// @flow
import * as React from "react";
import { InvoiceTemplatesUI } from "./InvoiceTemplatesUI";

import { useQuery } from "react-query";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { getInvoiceTemplates } from "../../../api/invoice/invoice.api";
import { TemplateNameModal } from "../../../Modals/TemplateNameModal/TemplateNameModal";
import { useDeleteTemplate, useGetTemplates } from "../invoice.hooks";
import { ActiveTemplates } from "./ActiveTemplates/ActiveTemplates";
import { Tab } from "../../../Types/global.types";
import { Invoice } from "../../../Types/invoice.types";
import { DeletedTemplates } from "./DeletedTemplates/DeletedTemplates";
import { RecurringTemplates } from "./RecurringTemplates/RecurringTemplates";

type Props = {};
export const InvoiceTemplates: React.FC = (props: Props) => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const { isLoading, data: invoiceTemplates } = useGetTemplates(id);
  const { isLoading: isDeleteLoading, mutate: deleteInvoiceTemplate } =
    useDeleteTemplate(id);

  const { activeInvoiceTemplates, deletedInvoiceTemplates, recurringInvoiceTemplates } =
    React.useMemo(() => {
      const activeInvoiceTemplates: Invoice[] = invoiceTemplates?.filter(
        (invoiceTemplate: Invoice) => !invoiceTemplate.isDeleted
      );
      const recurringInvoiceTemplates: Invoice[] = invoiceTemplates?.filter(
        (invoiceTemplate: Invoice) => invoiceTemplate.isRecurring && !invoiceTemplate.isDeleted
      );
      const deletedInvoiceTemplates: Invoice[] = invoiceTemplates?.filter(
        (invoiceTemplate: Invoice) => invoiceTemplate.isDeleted
      );

      return { activeInvoiceTemplates, deletedInvoiceTemplates, recurringInvoiceTemplates };
    }, [invoiceTemplates]);

  const handleEdit = (row: any) => {
    navigate({
      pathname: `/dashboard/invoice/template`,
      search: createSearchParams({
        id,
        "template-id": row.id,
        mode: "edit",
      }).toString(),
    });
  };

  const handleSingleDelete = (templateId: string) => {
    deleteInvoiceTemplate({ templateId });
  };

  const tabs: Tab[] = [
    {
      title: "Active Templates",
      content: (
        <ActiveTemplates
          activeInvoiceTemplates={activeInvoiceTemplates}
          handleEdit={handleEdit}
          handleSingleDelete={handleSingleDelete}
        />
      ),
    },
    {
      title: "Deleted Templates",
      content: (
        <DeletedTemplates deletedInvoiceTemplates={deletedInvoiceTemplates} />
      ),
    },
    {
      title: "Recurring Templates",
      content: (
        <RecurringTemplates recurringInvoiceTemplates={recurringInvoiceTemplates}
          handleEdit={handleEdit}
          handleSingleDelete={handleSingleDelete}
        />
      ),
    },
  ];
  return (
    <>
      <InvoiceTemplatesUI
        handleEdit={handleEdit}
        handleSingleDelete={handleSingleDelete}
        invoiceTemplates={invoiceTemplates}
        tabs={tabs}
      />

    </>
  );
};

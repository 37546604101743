// @flow
import * as React from "react";
import "./AddEditClientUI.scss";

import { Col, Row } from "antd";
import { ContainedButton } from "../../../UILibrary/Buttons/ContainedButton/ContainedButton";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
import { Client } from "../../../Types/invoice.types";
import { taxes } from "../taxConfig";

type Props = {
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    key?: string | Number | Symbol | any,
    value?: string | Number | Symbol | any
  ) => void;
  clientDetails: any;
  addClient: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setClientDetails: Function;
  isLoading: boolean;
};

export const AddEditClientUI = ({
  onChange,
  clientDetails,
  addClient,
  setClientDetails,
  isLoading,
}: Props) => {
  const onTaxInformationChange = (
    ev: any,
    key?: string | Number | Symbol | any,
    value?: string | Number | Symbol | any
  ) => {
    setClientDetails((prev: Client) => {
      return {
        ...prev,
        taxInformation: { ...prev.taxInformation, [ev?.target?.name]: ev?.target?.value },
      };
    });
  }
  return (
    <AppContentCard
      className="add_client"
      style={{ paddingBottom: "144px", marginBottom: "112px" }}
    >
      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Basic Details* :</p>
        </Col>

        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="name"
            id="name"
            placeholder="Client's Business name"
            value={clientDetails?.name}
          />
        </Col>

        <Col span={12}>
          <select
            className="input"
            name="type"
            id="type"
            onChange={onChange}
            placeholder="Type of Client"
            value={clientDetails?.type}
          >
            <option value={"Business"}>Business</option>
            <option value={"Consumer"}>Consumer</option>
          </select>
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Tax information (Optional):</p>
        </Col>

        <Col span={12}>
          <select
            className="input"
            name="taxScheme"
            id="taxScheme"
            onChange={onTaxInformationChange}
            placeholder="Tax Scheme"
            value={clientDetails?.taxInformation?.taxScheme}
          >
            {taxes?.map((tax: any) => (
              <option value={tax.id}>{tax.label}</option>
            ))}
          </select>
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onTaxInformationChange}
            type="text"
            name="taxId"
            id="taxId"
            placeholder="Business Tax Id"
            value={clientDetails?.taxInformation?.taxId}
          />
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Address* :</p>
        </Col>

        <Col span={24}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="streetAddress"
            id="streetAddress"
            placeholder="Street Address"
            value={clientDetails?.streetAddress}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="postalCode"
            id="postalCode"
            placeholder="Postal Code / Zip Code"
            value={clientDetails?.postalCode}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="city"
            id="city"
            placeholder="City"
            value={clientDetails?.city}
          />
        </Col>

        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="state"
            id="state"
            placeholder="State"
            value={clientDetails?.state}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="country"
            id="country"
            placeholder="Country"
            value={clientDetails?.country}
          />
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Contact (Optional):</p>
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            value={clientDetails?.email}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Phone"
            value={clientDetails?.phoneNumber}
          />
        </Col>
        {/* 
        <Col span={12}>
          <div className="checkbox-input">
           {clientDetails && <input
              className="input checkbox"
              type="checkbox"
              name="addEmailToInvoice"
              id="addEmailToInvoice"
              placeholder="addEmailToInvoice" 
              value={clientDetails?.addEmailToInvoice}
              defaultChecked={clientDetails?.addEmailToInvoice}
              onChange={e => {
                onChange(
                  e,
                  "addEmailToInvoice",
                  !clientDetails?.addEmailToInvoice
                );
              }}
            />}
            Show Email in Invoice
          </div>
        </Col>
        <Col span={12}>
         {clientDetails && <div className="checkbox-input">
            <input
              className="input checkbox"
              type="checkbox"
              name="addPhoneNumberToInvoice"
              id="addPhoneNumberToInvoice"
              value={clientDetails?.addPhoneNumberToInvoice}
              defaultChecked={clientDetails?.addPhoneNumberToInvoice}
              onChange={e => {
                setClientDetails(prev => ({
                  ...prev,
                  addPhoneNumberToInvoice: !prev.addPhoneNumberToInvoice
                }))
              }}
            />
            Show Phone in Invoice
          </div>}
        </Col> */}
      </Row>
      <ContainedButton onClick={addClient} isLoading={isLoading}>
        Save
      </ContainedButton>
    </AppContentCard>
  );
};

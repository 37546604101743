// @flow
import * as React from "react";
import { TableIn } from "../../../../UILibrary/Table/Table";
import CurrencyList from "currency-list";
import { KeyValue } from "../../../../UILibrary/KeyValue/KeyValue";
import { taxes } from "../../taxConfig";
import { useCalculateTotal } from "../../invoice.hooks";
import { Divider } from "antd";
import { Column } from "../../../../Types/global.types";
import { Invoice } from "../../../../Types/invoice.types";

type Props = {
  invoiceDetails: Invoice;
  isDownloadPage: boolean;
};
export const ItemsSummary = ({ invoiceDetails, isDownloadPage }: Props) => {
  const {
    columnDefinitions,
    lineItems,
    taxScheme,
    currency,
    additionalCharges,
    reductions,
  } = invoiceDetails;

  const currencySymbol = React.useMemo(
    () => CurrencyList.getAll("en_IN")[currency]?.symbol as string,
    [currency]
  );

  const taxColumns = taxes?.find(
    each => each.id === taxScheme
  )?.taxAmountColumns;
  const totalAmount = useCalculateTotal(
    taxColumns
  );

  const columns: Column[] | undefined = React.useMemo(() => {
    return columnDefinitions?.map((each: any) => {
      let prefix: string | React.ReactElement | undefined;
      let suffix: string | React.ReactElement | undefined;
      if (
        [
          "amount",
          "IGST",
          "CGST",
          "VAT",
          "SGST",
          "PPN",
          "HST",
          "SST",
          "total",
          "rate",
        ].includes(each.columnKey)
      ) {
        prefix = currencySymbol;
      }

      if (each.columnKey === "taxRate") {
        suffix = "%";
      }
      if (each.columnKey === "discount") {
        if (invoiceDetails?.eachItemDiscountUnit === "Percentage") {
          suffix = "%";
        } else {
          prefix = currencySymbol;
        }
      }
      return {
        title: each.name,
        key: each.columnKey,
        columnKey: each.columnKey,
        prefix,
        suffix,
        ...(each.columnKey === "itemName" && { width: "200px" }),
      };
    });
  }, [columnDefinitions, currencySymbol]);

  return (
    <>
      <TableIn
        columns={columns}
        data={lineItems}
        cellPadding={isDownloadPage ? "6px 14px" : "18px 14px"}
        boldHeaders={true}
        // removableRows
        // onRemoveRow={handleRemoveRow}
        // extraFunctionality={useAddDescription}
        classname="invoice_preview_invoice_items_summary_wrapper"
      />
      <div className="invoice_preview_total_card_wrapper">
        <div></div>
        <div
          className={`invoice_preview_total_card  ${isDownloadPage ? "invoice_download_total_card" : ""
            }`}
        >
          <KeyValue
            title={"Amount"}
            value={
              currencySymbol +
              " " +
              lineItems?.reduce(
                (sum: number, invoice: any) => sum + (parseFloat(invoice?.amount) || 0),
                0
              )
            }
            // totalWidth={300}
            valueWeight={600}
            valueAlign="right"
            titleFontSize={"1.1rem"}
            valueFontSize={"1.1rem"}
          />
          {taxes
            ?.find(each => each.id === taxScheme)
            ?.taxAmountColumns?.map((each: any) => (
              <KeyValue
                title={each.label}
                value={
                  currencySymbol +
                  " " +
                  lineItems.reduce(
                    (sum: number, invoice: any) =>
                      sum + (parseFloat(invoice[each.variable]) || 0),
                    0
                  )
                }
                // totalWidth={300}
                valueWeight={600}
                valueAlign="right"
                titleFontSize={"1.1rem"}
                valueFontSize={"1.1rem"}
              />
            ))}

          {additionalCharges?.map(charge => {
            let prefix = "";
            let suffix = "";
            if (charge.type === "Percentage" || charge.type === "%") {
              suffix = " %";
            } else {
              prefix = currencySymbol + " ";
            }

            console.log(charge, "additionalCharges")

            return (
              <KeyValue
                title={charge.name}
                value={"+ " + prefix + charge.value + suffix}
                valueAlign="right"
                titleFontSize={"1.1rem"}
                valueFontSize={"1.1rem"}
                valueWeight={600}
              />
            );
          })}
          {reductions?.map(charge => {
            let prefix = "";
            let suffix = "";

            console.log(charge)
            if (charge.type === "Percentage") {
              suffix = " %";
            } else {
              prefix = currencySymbol + " ";
            }

            return (
              <KeyValue
                title={charge.name}
                value={"- " + prefix + charge.value + suffix}
                valueAlign="right"
                titleFontSize={"1.1rem"}
                valueFontSize={"1.1rem"}
                valueWeight={600}
              />
            );
          })}
          <Divider style={{ border: "2px solid rgba(5, 5, 5, 0.06)" }} />
          <KeyValue
            title={`Total (${currency})`}
            value={`${currencySymbol} ${totalAmount.toFixed(2)}`}
            titleColor="black"
            valueWeight={600}
            valueAlign="right"
            titleFontSize={"1.5rem"}
            valueFontSize={"1.5rem"}
          />
          <Divider style={{ border: "1px solid rgba(5, 5, 5, 0.06)" }} />
        </div>
      </div>
    </>
  );
};

// @flow
import * as React from "react";
import { NavLink } from "react-router-dom";
import "./PanelItem.scss";

type Props = {
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  title?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  children?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
    | undefined;
  navigateTo?: any;
};

export const PanelItem = ({ icon, navigateTo, children, title }: Props) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        isPending ? "link_item" : isActive ? "active_link" : "link_item"
      }
      to={navigateTo}
    >
      <div className="item_icon">{icon}</div>
      <div className="item_name">{title}</div>
    </NavLink>
  );
};

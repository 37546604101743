import React from "react";
import { Dialog, Button, IconButton } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";
import { ConfigProvider, Form, Input, Select } from "antd";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EntityLogo from "./EntityLogo";
import DeleteLeaveEntity from "./DeleteLeaveEntity";
const editIcon = require("../images/edit.png");

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  id: any;
  tokenValue: string;
  entity: any;
  currentEntity: any;
  handleCoworker: () => void;
  handleLeave: () => void;
  handleDelete: () => void;
  handleRemoveLogo: () => void;
  handleEntityUpdate: () => void;
  handleUploadLogo: (imageFile: any) => void;
  handleChange: (name: string, value: string) => void;
  userDetail: any;
  leaveEntity: boolean;
  handleLeaveEntity: any;
  deleteEntity: boolean;
  handleDeleteEntity: any;
  editEntity: boolean;
  handleEditEntity: any;
  deleteEntityInput: string;
  setDeleteEntityInput: any;
  lastNameMatch: string;
  setLastNameMatch: any;
  entityInputs: any;
  changeLogo: boolean;
  setChangeLogo: any;
  logoState: string;
  setLogoState: any;
}

const SettingsUI: React.FC<Props> = (props) => {
  const {
    id,
    tokenValue,
    entity,
    currentEntity,
    handleCoworker,
    userDetail,
    leaveEntity,
    handleLeaveEntity,
    deleteEntity,
    handleDeleteEntity,
    deleteEntityInput,
    setDeleteEntityInput,
    lastNameMatch,
    setLastNameMatch,
    handleLeave,
    handleDelete,
    editEntity,
    handleEditEntity,
    handleEntityUpdate,
    entityInputs,
    handleChange,
    changeLogo,
    setChangeLogo,
    handleUploadLogo,
    handleRemoveLogo,
    logoState,
    setLogoState,
  } = props;
  const numberOfEmployeesSelect = [
    {
      value: "OneToTen",
      label: "1-10",
    },
    {
      value: "ElevenToFifty",
      label: "11-50",
    },
    {
      value: "FiftyOneToHundred",
      label: "51-100",
    },
    {
      value: "AboveHundred",
      label: "More than 100",
    },
  ];
  return (
    <div className="setting">
      <div className="setting-top">
        <div className="setting-entity">
          <EntityLogo
            {...{
              entity,
              changeLogo,
              setChangeLogo,
              handleUploadLogo,
              handleRemoveLogo,
              logoState,
              setLogoState,
            }}
          />
          <div className="setting-entity-details">
            <div className="setting-entity-details-name">
              {entity.entityName}
            </div>
            <div className="setting-entity-details-website">
              {entity.website ? (
                <a href={entity.website} target="_blank">
                  {entity.website}
                </a>
              ) : (
                <a onClick={() => handleEditEntity(true)}>Add Website</a>
              )}
            </div>
          </div>
          <div className="setting-entity-edit">
            <IconButton onClick={() => handleEditEntity(true)}>
              <img src={editIcon} alt="" />
            </IconButton>
          </div>
        </div>
        <div className="setting-coworker">
          <Button
            variant="contained"
            className="setting-coworker-button"
            onClick={handleCoworker}
          >
            Manage Co-Worker
          </Button>
        </div>
      </div>
      <DeleteLeaveEntity
        {...{
          currentEntity,
          leaveEntity,
          handleLeaveEntity,
          deleteEntity,
          handleDeleteEntity,
          deleteEntityInput,
          setDeleteEntityInput,
          lastNameMatch,
          setLastNameMatch,
          userDetail,
          handleLeave,
          handleDelete,
        }}
      />

      {/* EDIT COMPANy */}
      <Dialog TransitionComponent={Transition} open={editEntity}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                handleEditEntity(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Edit Business Details</div>
          {entityInputs && (
            <Form onFinish={handleEntityUpdate}>
              <Form.Item
                className="add-form-item"
                name="companyName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your first name",
                  },
                ]}
                initialValue={entityInputs.name}
              >
                <Input
                  className="add-form-input-name"
                  placeholder="Company Name"
                  onChange={(e) => handleChange("companyName", e.target.value)}
                />
              </Form.Item>

              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: "#F5f5f5",
                    lineWidth: 0,
                  },
                }}
              >
                <Form.Item
                  className="add-form-item"
                  name="numberOfEmployees"
                  rules={[
                    {
                      message: "Please select number of employees",
                    },
                  ]}
                >
                  <Select
                    placement="bottomRight"
                    size="large"
                    defaultValue={entityInputs.numberOfEmployees}
                    rootClassName="add-form-select"
                    dropdownStyle={{ minWidth: "140px" }}
                    popupClassName="select-popup"
                    placeholder="Number of Employees"
                    onChange={(value) =>
                      handleChange("numberOfEmployees", value)
                    }
                    options={numberOfEmployeesSelect}
                  />
                </Form.Item>
              </ConfigProvider>
              <Form.Item
                className="add-form-item"
                name="companyCountry"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your country",
                  },
                ]}
                initialValue={entityInputs.companyCountry}
              >
                <Input
                  className="add-form-input-name"
                  placeholder="Country"
                  disabled
                  onChange={(e) =>
                    handleChange("companyCountry", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                className="add-form-item"
                name="companyType"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your entity type",
                  },
                ]}
                initialValue={entityInputs.companyType}
              >
                <Input
                  className="add-form-input-name"
                  placeholder="Type of Entity"
                  disabled
                  onChange={(e) => handleChange("companyType", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className="add-form-item"
                name="website"
                rules={[
                  {
                    message: "Please enter your Website",
                  },
                ]}
                initialValue={entityInputs.website}
              >
                <Input
                  className="add-form-input-name"
                  placeholder="Website (Optional)"
                  onChange={(e) => handleChange("website", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className="add-form-item"
                name="description"
                rules={[
                  {
                    message: "Please enter your description",
                  },
                ]}
                initialValue={entityInputs.description}
              >
                <Input
                  className="add-form-input-name"
                  placeholder="Description (Optional)"
                  onChange={(e) => handleChange("description", e.target.value)}
                />
              </Form.Item>
              <div className="add-form-submit">
                <Button
                  className="add-form-submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default SettingsUI;

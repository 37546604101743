import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { docGeneration } from "../../../../api/docGeneration/data";
import { Button } from "@mui/material";
const selectImg = require("../../images/select.png");
const downloadImg = require("../../images/download.png");
const detailsImg = require("../../images/details.png");
const docsImg = require("../../images/docs.png");

interface Props {
  handleDocClick: (name: string) => void;
  handleReqCustom: () => void;
}

const DocumentGenerationUI: React.FC<Props> = (props) => {
  const { handleDocClick, handleReqCustom } = props;
  return (
    <div className="doc-gen">
      <div className="doc-gen-working">
        <div className="doc-gen-working-head">
          Generate important documents with pre-built templates in 30 seconds!
        </div>
        <div className="doc-gen-working-heading">How does it work?</div>
        <div className="doc-gen-working-flow">
          <div className="doc-gen-working-flow-item">
            <div className="doc-gen-working-flow-item-image">
              <img src={selectImg} alt="Select Doc" />
            </div>
            <div className="doc-gen-working-flow-item-details">
              <div className="doc-gen-working-flow-item-details-heading">
                Select Document
              </div>
              <div className="doc-gen-working-flow-item-details-content">
                Choose any document from the given list
              </div>
            </div>
            <div className="doc-gen-working-flow-item-arrow">
              <ChevronRightIcon />
            </div>
          </div>
          <div className="doc-gen-working-flow-item">
            <div className="doc-gen-working-flow-item-image">
              <img src={detailsImg} alt="Select Doc" />
            </div>
            <div className="doc-gen-working-flow-item-details">
              <div className="doc-gen-working-flow-item-details-heading">
                Enter Details
              </div>
              <div className="doc-gen-working-flow-item-details-content">
                The document is automatically edited according to your responses
              </div>
            </div>
            <div className="doc-gen-working-flow-item-arrow">
              <ChevronRightIcon />
            </div>
          </div>
          <div className="doc-gen-working-flow-item">
            <div className="doc-gen-working-flow-item-image">
              <img src={downloadImg} alt="Select Doc" />
            </div>
            <div className="doc-gen-working-flow-item-details">
              <div className="doc-gen-working-flow-item-details-heading">
                Share Link or Download file
              </div>
              <div className="doc-gen-working-flow-item-details-content">
                The required document would be created and be available to
                download
              </div>
            </div>
          </div>
        </div>
        <div className="doc-gen-working-docs">
          {docGeneration.map((item: any) => {
            return (
              <div
                className="doc-gen-working-docs-item"
                onClick={() => {
                  handleDocClick(item.path);
                }}
              >
                <div className="doc-gen-working-docs-item-image">
                  <img src={docsImg} alt="" />
                </div>
                <div className="doc-gen-working-docs-item-type">
                  {item.type}
                </div>
                <div className="doc-gen-working-docs-item-name">
                  {item.name}
                </div>
                <div className="doc-gen-working-docs-item-disclaimer">
                  {item.disclaimer}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="doc-gen-request-buttons">
        <Button
          className="doc-gen-request-button"
          variant="contained"
          onClick={handleReqCustom}
        >
          Request Custom
        </Button>
      </div>
    </div>
  );
};

export default DocumentGenerationUI;

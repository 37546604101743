import * as React from "react";
import "./ContainedButton.scss";
import { CircularProgress } from "@mui/material";
import { Space } from "antd";

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  style?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  alignLoader?: string

};
export const ContainedButton = ({
  disabled,
  active,
  children,
  style,
  onClick,
  isLoading,
  alignLoader

}: Props) => {
  return (
    <div className="outlined-button-wrapper">
      {(isLoading && (alignLoader ? alignLoader === "left" : false)) && (
        <CircularProgress size={30} style={{ marginRight: "20px" }} />
      )}
      <button
        onClick={onClick}
        disabled={disabled || isLoading}
        style={style}
        className={`contained-button ${active ? "active-button" : ""}`}
      >
        {(isLoading && (alignLoader ? alignLoader === "center" : true)) ?
          <CircularProgress size={30} />
          : children
        }
      </button>
      {(isLoading && (alignLoader ? alignLoader === "right" : false)) && (
        <CircularProgress size={30} style={{ marginLeft: "20px" }} />
      )}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { docGeneration } from "../../../../api/docGeneration/data";
import "./styles.scss";
import "../requestCustom/index.scss";
import { generateDocument } from "../../../../api/docGeneration/docgen";
import MultiPageForm from "./multipageForm";
import { Dialog, Button, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { generateTemplate } from "../../../../api/docGeneration/docgen";
import { toast } from "react-toastify";
import { FlashlightOnRounded } from "@mui/icons-material";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const IndivDoc = () => {
  const navigate = useNavigate();
  const [openTemp, setOpenTemp] = useState<boolean>(false);
  const [loadCustom, setLoadCustom] = useState<boolean>(false);
  const [searchparams] = useSearchParams();
  const location = useLocation();
  const id: any = searchparams.get("id");
  const path = location.pathname.split("/");
  const pathname = path[path.length - 1];
  const data: any = docGeneration.filter((item) => item.path === pathname);
  const fields = data[0].fields;

  const keyValueObject: any = {};

  fields.forEach((item: any) => {
    item.field.forEach((val: any) => {
      if (val.key) {
        keyValueObject[val.key] = "";
      }
    });
  });
  const [keyValue, setKeyValues] = useState<any>(keyValueObject);

  const noOfPages = data[0].page;

  const [currentPage, setCurrentPage] = useState<number>(0);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleReqCustom = () => {
    navigate({
      pathname: "/dashboard/documentGeneration/requestCustom",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };

  const handleTempDown = async () => {
    try {
      setLoadCustom(true);

      const generation = await generateTemplate({
        id,
        tempCode: data[0].tempCode,
      });
      if (generation && generation.type === "success") {
        toast.success("Request Sent Successfully");

        const blobUrl = URL.createObjectURL(generation.data);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = data[0].tempCode;
        downloadLink.click();

        URL.revokeObjectURL(blobUrl);
        setLoadCustom(false);
      } else if (generation && generation.type === "error") {
        throw new Error(generation.errorType);
      }
    } catch (error: any) {
      toast.error(error.message);
      setLoadCustom(false);
    }
  };

  return (
    <div className="docgen">
      <div className="docgen-request">
        <div className="docgen-request-heading">{data[0].pageHeading}</div>
        <MultiPageForm
          {...{
            fields,
            keyValue,
            setKeyValues,
            noOfPages,
            currentPage,
            data,
            handlePreviousPage,
            id,
            setCurrentPage,
          }}
        />
      </div>
      <div className="doc-gen-request-buttons">
        <Button
          className="doc-gen-request-buttons-button-outlined"
          variant="outlined"
          onClick={() => setOpenTemp(true)}
        >
          Download Template
        </Button>
        <Button
          className="doc-gen-request-buttons-button"
          variant="contained"
          onClick={handleReqCustom}
        >
          Request Custom
        </Button>
      </div>
      <Dialog TransitionComponent={Transition} open={openTemp}>
        <div className="docgen-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setOpenTemp(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="docgen-dialog-heading">
            Download Template for {data[0].name}
          </div>

          <div className="docgen-dialog-buttons">
            {loadCustom ? (
              <MiniLoaderButton />
            ) : (
              <>
                <Button
                  className="docgen-dialog-buttons-contained"
                  variant="contained"
                  onClick={handleTempDown}
                >
                  Download
                </Button>
                <Button
                  className="docgen-dialog-buttons-outlined"
                  variant="outlined"
                  onClick={() => setOpenTemp(false)}
                >
                  Close
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default IndivDoc;

import React from "react";
import { Form, Input } from "antd";
import { Button } from "@mui/material";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";

interface User {
  firstName: string;
  lastName: string;
}
interface Props {
  userDetails: User;
  handleDetails: (name: string, e: any) => void;
  handleSubmit: () => void;
  submitLoading: boolean;
}

const RegisterUI: React.FC<Props> = (props) => {
  const { userDetails, handleDetails, handleSubmit, submitLoading } = props;
  return (
    <div className="register">
      <div className="register-text">
        <div className="register-text-heading">Your Name</div>
        <div className="register-text-message">
          Please enter your name and continue
        </div>
      </div>
      <Form className="register-form" onFinish={handleSubmit}>
        <Form.Item
          className="register-form-item"
          name="firstName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter your first name",
            },
          ]}
          initialValue={userDetails.firstName}
        >
          <Input
            className="register-form-input-name"
            placeholder="First Name"
            onChange={(e) => handleDetails("firstName", e)}
          />
        </Form.Item>{" "}
        <Form.Item
          className="register-form-item"
          name="lastName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter your last name",
            },
          ]}
          initialValue={userDetails.lastName}
        >
          <Input
            className="register-form-input-name"
            placeholder="Last Name"
            onChange={(e) => handleDetails("lastName", e)}
          />
        </Form.Item>
        <div className="register-form-submit">
          {submitLoading ? (
            <MiniLoaderButton />
          ) : (
            <Button
              className="register-form-submit-button register-success"
              variant="contained"
              color="primary"
              type="submit"
            >
              Continue
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default RegisterUI;

export const consultionAgreement = {
  name: "Consulting Agreement",
  docCodeType: "single",
  path: "consulting",
  type: "Business Operations",
  tempCode: "consulting-agreement",
  disclaimer:
    "A Consulting Agreement is a contract between a consultant and client that outlines the terms of their arrangement.",
  docCodeValues: [
    {
      docCode: "consulting-agreement",
    },
  ],
  page: 4,
  pageHeading:
    "Enter below details to generate a prefilled Consulting Agreement!",
  fields: [
    // Page: 1
    {
      page: 1,
      name: "Date on which the agreement will be entered into:",
      field: [
        {
          key: "effective_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "The state from where this agreement is entered into:",
      field: [
        {
          key: "agreement_state",
          type: "select",
          width: "half",
          placeholder: "State",
          options: [
            {
              label: "Andaman and Nicobar Islands",
              value: "Andaman and Nicobar Islands",
            },
            {
              label: "Andhra Pradesh",
              value: "Andhra Pradesh",
            },
            {
              label: "Arunachal Pradesh",
              value: "Arunachal Pradesh",
            },
            {
              label: "Assam",
              value: "Assam",
            },
            {
              label: "Bihar",
              value: "Bihar",
            },
            {
              label: "Chandigarh",
              value: "Chandigarh",
            },
            {
              label: "Chhattisgarh",
              value: "Chhattisgarh",
            },
            {
              label: "Dadra and Nagar Haveli",
              value: "Dadra and Nagar Haveli",
            },
            {
              label: "Daman and Diu",
              value: "Daman and Diu",
            },
            {
              label: "Delhi",
              value: "Delhi",
            },
            {
              label: "Goa",
              value: "Goa",
            },
            {
              label: "Gujarat",
              value: "Gujarat",
            },
            {
              label: "Haryana",
              value: "Haryana",
            },
            {
              label: "Himachal Pradesh",
              value: "Himachal Pradesh",
            },
            {
              label: "Jammu and Kashmir",
              value: "Jammu and Kashmir",
            },
            {
              label: "Jharkhand",
              value: "Jharkhand",
            },
            {
              label: "Karnataka",
              value: "Karnataka",
            },
            {
              label: "Kerala",
              value: "Kerala",
            },
            {
              label: "Lakshadweep",
              value: "Lakshadweep",
            },
            {
              label: "Madhya Pradesh",
              value: "Madhya Pradesh",
            },
            {
              label: "Maharashtra",
              value: "Maharashtra",
            },
            {
              label: "Manipur",
              value: "Manipur",
            },
            {
              label: "Meghalaya",
              value: "Meghalaya",
            },
            {
              label: "Mizoram",
              value: "Mizoram",
            },
            {
              label: "Nagaland",
              value: "Nagaland",
            },
            {
              label: "Odisha",
              value: "Odisha",
            },
            {
              label: "Puducherry",
              value: "Puducherry",
            },
            {
              label: "Punjab",
              value: "Punjab",
            },
            {
              label: "Rajasthan",
              value: "Rajasthan",
            },
            {
              label: "Sikkim",
              value: "Sikkim",
            },
            {
              label: "Tamil Nadu",
              value: "Tamil Nadu",
            },
            {
              label: "Telangana",
              value: "Telangana",
            },
            {
              label: "Tripura",
              value: "Tripura",
            },
            {
              label: "Uttar Pradesh",
              value: "Uttar Pradesh",
            },
            {
              label: "Uttarakhand",
              value: "Uttarakhand",
            },
            {
              label: "West Bengal",
              value: "West Bengal",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Type of the client:",
      field: [
        {
          key: "client_type",
          type: "radio",
          width: "initial",
          options: [
            {
              value: "Business",
              label: "Business",
            },
            {
              value: "Individual",
              label: "Individual",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Business name and office address of the client:",
      clientCondition: "Business",
      field: [
        {
          key: "client_name",
          type: "input",
          width: "half",
          placeholder: "Business Name",
        },
        {
          key: "client_address",
          type: "input",
          width: "full",
          placeholder: "Office Address",
        },
      ],
    },
    {
      page: 1,
      name: "Full name and correspondence address of the client:",
      clientCondition: "Individual",
      field: [
        {
          key: "client_name",
          type: "input",
          width: "half",
          placeholder: "Full Name",
        },
        {
          key: "client_address",
          type: "input",
          width: "full",
          placeholder: "Correspondence Address",
        },
      ],
    },
    {
      page: 1,
      name: "Major business areas of the client:",
      clientCondition: "Business",
      field: [
        {
          key: "client_business_domain",
          type: "input",
          width: "half",
          placeholder: "Business Area 1, Business Area 2, and so on",
        },
      ],
    },
    {
      page: 1,
      name: "PAN of the client:",
      clientCondition: "Individual",
      field: [
        {
          key: "client_PAN",
          type: "input",
          width: "half",
          placeholder: "PAN",
        },
      ],
    },
    {
      page: 1,
      name: "Name of the person representing the client:",
      clientCondition: "Business",
      field: [
        {
          key: "client_rep_name",
          type: "input",
          width: "half",
          placeholder: "Full Name",
        },
      ],
    },
    {
      page: 1,
      name: "Major business areas of the client:",
      clientCondition: "Individual",
      field: [
        {
          key: "client_business_domain",
          type: "input",
          width: "half",
          placeholder: "Business Area 1, Business Area 2, and so on",
        },
      ],
    },
    // Page: 2
    {
      page: 2,
      name: "Type of the consultant:",
      field: [
        {
          key: "consultant_type",
          type: "radio",
          width: "initial",
          options: [
            {
              value: "Business",
              label: "Business",
            },
            {
              value: "Individual",
              label: "Individual",
            },
          ],
        },
      ],
    },
    {
      page: 2,
      name: "Business name and office address of the consultant:",
      consultantCondition: "Business",
      field: [
        {
          key: "consultant_name",
          type: "input",
          width: "half",
          placeholder: "Business Name",
        },
        {
          key: "consultant_address",
          type: "input",
          width: "full",
          placeholder: "Office Address",
        },
      ],
    },
    {
      page: 2,
      name: "Full name and correspondence address of the consultant:",
      consultantCondition: "Individual",
      field: [
        {
          key: "consultant_name",
          type: "input",
          width: "half",
          placeholder: "Full Name",
        },
        {
          key: "consultant_address",
          type: "input",
          width: "full",
          placeholder: "Correspondence Address",
        },
      ],
    },
    {
      page: 2,
      name: "Area of specialization of the consultant:",
      consultantCondition: "Business",
      field: [
        {
          key: "consultant_expertise",
          type: "input",
          width: "half",
          placeholder:
            "Specialization Area 1, Specialization Area 2, and so on",
        },
      ],
    },
    {
      page: 2,
      name: "PAN of the consultant:",
      consultantCondition: "Individual",
      field: [
        {
          key: "consultant_PAN",
          type: "input",
          width: "half",
          placeholder: "PAN",
        },
      ],
    },
    {
      page: 2,
      name: "Name of the person representing the consultant:",
      consultantCondition: "Business",
      field: [
        {
          key: "consultant_rep_name",
          type: "input",
          width: "half",
          placeholder: "Full Name",
        },
      ],
    },
    {
      page: 2,
      name: "Major business areas of the client:",
      consultantCondition: "Individual",
      field: [
        {
          key: "consultant_expertise",
          type: "input",
          width: "half",
          placeholder:
            "Specialization Area 1, Specialization Area 2, and so on",
        },
      ],
    },
    // Page: 3
    {
      page: 3,
      name: "The specific services in detail to be rendered by the Consultant under this Agreement (Statement of Work):",
      field: [
        {
          key: "statement_of_work",
          type: "textarea",
          width: "full",
          placeholder: `1. First service and detail
             2. Second service and detail
             and so on...`,
        },
      ],
    },
    {
      page: 3,
      name: "Fee:",
      field: [
        {
          key: "currency",
          type: "select",
          width: "initial",
          placeholder: "Currency",
          options: [
            {
              label: "USD",
              value: "USD",
            },
            {
              label: "INR",
              value: "INR",
            },
          ],
        },
        {
          key: "Amount",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
        {
          key: "payment_frequency",
          type: "select",
          width: "initial",
          placeholder: "Frequency",
          options: [
            {
              label: "Fixed Fee",
              value: "Fixed Fee",
            },
            {
              label: "Annualy",
              value: "Annualy",
            },
            {
              label: "Semi-annualy",
              value: "Semi-annualy",
            },
            {
              label: "Quartely",
              value: "Quartely",
            },
            {
              label: "Monthly",
              value: "Monthly",
            },
            {
              label: "Weekly",
              value: "Weekly",
            },
            {
              label: "Daily",
              value: "Daily",
            },
            {
              label: "Hourly",
              value: "Hourly",
            },
          ],
        },
      ],
    },
    {
      page: 3,
      name: "Number of days within which payment is to be made by the client after receipt of invoice:",
      field: [
        {
          key: "days_for_payment",
          type: "input",
          width: "half",
          placeholder: "Number of Days. Ex: 7, 14, 30, etc",
        },
      ],
    },
    {
      page: 3,
      name: "Method in which the payment will be made to the Consultant:",
      field: [
        {
          key: "mode_of_payment",
          type: "input",
          width: "half",
          placeholder: "Payment method. Ex: Bank transfer, cheque, etc",
        },
      ],
    },
    // Page: 4
    {
      page: 4,
      name: "Method in which the payment will be made to the Consultant:",
      field: [
        {
          key: "retainer",
          type: "radio",
          width: "initial",
          options: [
            {
              label: "Yes",
              value: "Yes",
            },
            {
              label: "No",
              value: "No",
            },
          ],
        },
      ],
    },
    {
      page: 4,
      name: "Retainer fee:",
      field: [
        {
          key: "retainer_fee",
          type: "input",
          width: "half",
          placeholder: "Retainer Fee",
        },
      ],
    },
    {
      page: 4,
      name: "Intervals at which the Consultant has to submit a report to the Client about the work status:",
      field: [
        {
          key: "report_frequency",
          type: "select",
          width: "half",
          placeholder: "Interval",
          options: [
            {
              label: "Annualy",
              value: "Annualy",
            },
            {
              label: "Semi-annualy",
              value: "Semi-annualy",
            },
            {
              label: "Quartely",
              value: "Quartely",
            },
            {
              label: "Monthly",
              value: "Monthly",
            },
            {
              label: "Weekly",
              value: "Weekly",
            },
            {
              label: "Daily",
              value: "Daily",
            },
            {
              label: "Hourly",
              value: "Hourly",
            },
          ],
        },
      ],
    },
    {
      page: 4,
      name: "Starting date of the consultancy service:",
      field: [
        {
          key: "start_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
  ],
};

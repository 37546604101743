import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles.scss";

const MiniLoaderButton = () => {
  return (
    <div className="mini-loader">
      <CircularProgress sx={{ color: "#ffffff" }} size={24} />
    </div>
  );
};

export default MiniLoaderButton;

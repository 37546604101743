// @flow
import { Col, Modal, Row } from "antd";
import * as React from "react";
// import "./TemplateNameModal.scss";

import { Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { toastIn } from "../../UILibrary/Toast/Toast";
import { createSearchParams, useNavigate } from "react-router-dom";
import { patchInvoiceTemplate, postInvoiceTemplate } from "../../api/invoice/invoice.api";
import { Invoice } from "../../Types/invoice.types";
import { useDispatch } from "react-redux";
import { setOpenTemplateNameModal } from "../../redux/Modals/modalActionCreator";
import { useSelector } from "react-redux";
type Props = {
  handleCancel: () => void;
  companyId: string | null;
  open: boolean;
};
export const TemplateNameModal = ({ open, handleCancel, companyId }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient()
  const { key, isEdit, templateId, templateName: incomingName } = useSelector((state: any) => state.modal.openTemplateNameModal)
  const [templateName, setTemplateName] = React.useState(incomingName);

  const {
    mutate: mutateInvoiceTemplate,
    isLoading: isMutateInvoiceTemplateLoading,
  } = useMutation({
    mutationFn: () => postInvoiceTemplate(companyId, templateName),
    onSuccess: (newTemplate: Invoice) => {
      dispatch(setOpenTemplateNameModal({ key: false }))
      navigate({
        pathname: `/dashboard/invoice/template`,
        search: createSearchParams({
          id: companyId || "",
          "template-id": newTemplate?.id || "",
          mode: "edit",
        }).toString(),
      });
    },
    onError: (error: Error) => {
      toastIn.error(error.message);
    },
  });

  const {
    mutate: mutatePatchInvoiceTemplate,
    isLoading: isMutatePatchInvoiceTemplateLoading,
  } = useMutation(
    {
      mutationFn: (payload: any) => {

        return patchInvoiceTemplate(companyId, templateId, payload)
      },
      onSuccess: (newTemplate: Invoice) => {
        dispatch(setOpenTemplateNameModal({ key: false }))
        queryClient.invalidateQueries([companyId, "invoiceTemplate", templateId])
      },
      onError: (error: Error) => {
        toastIn.error(error.message);
      },
    }
  );

  const handleOk = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      if (isEdit) {
        mutatePatchInvoiceTemplate({ name: templateName })
      } else {
        mutateInvoiceTemplate();
      }

    } catch (error: Error | any) {
      toastIn.error(error?.message || "");
    }
  };
  return (
    <Modal
      title={<Typography variant="h6">Create Template</Typography>}
      open={open}
      onOk={handleOk}
      confirmLoading={isMutateInvoiceTemplateLoading || isMutatePatchInvoiceTemplateLoading}
      onCancel={handleCancel}
      className="business_modal"
      width={500}
    >
      <Row gutter={[20, 20]} className="form-row">
        {/* <Col span={24}>
          <p className="form-subheading">
            Please enter the name of the template:
          </p>
        </Col> */}

        <Col span={24}>
          <form>
            <input
              className="input"
              onChange={e => setTemplateName(e.target.value)}
              type="text"
              name="templateName"
              id="templateName"
              required
              placeholder="Template Name"
              value={templateName}
            />
          </form>
        </Col>
      </Row>
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getIndivCompany } from "../../../api/companies/companies";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CoworkersUI from "./CoworkerUI";
import { Entity } from "../../constants";
import { useDispatch } from "react-redux";
import {
  setLoadMessage,
  setLoader,
} from "../../Loader/state/action/actionCreator";

const Coworker: React.FC = () => {
  const state: any = useSelector((state) => state);
  const userDetail = state.user.userDetails;
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    document.title = "Entitiy Coworkers";
  }, []);

  if (!id || error === "Company not found. Please retry.") {
    return <Navigate to="/" replace />;
  } else {
    return <CoworkersUI {...{ id }} />;
  }
};

export default Coworker;

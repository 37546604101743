import * as React from "react";
import { KeyValue } from "../../../UILibrary/KeyValue/KeyValue";
import { Col, Image, Row, Space } from "antd";
import "./InvoicePreviewUI.scss";
import { Typography } from "@mui/material";
import { ItemsSummary } from "./ItemsSummary/ItemsSummary";
import { OutlinedButton } from "../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import { ContainedButton } from "../../../UILibrary/Buttons/ContainedButton/ContainedButton";
import { Download } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
import { Invoice } from "../../../Types/invoice.types";

type Props = {
  invoiceDetails: Invoice;
  templateId: string;
  setOpenInvoiceNumberModal: Function
  isDownloadPage: boolean
};

export const InvoicePreviewUI = ({
  invoiceDetails,
  templateId,
  setOpenInvoiceNumberModal,
  isDownloadPage
}: Props) => {
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");

  const { termsAndConditions } = invoiceDetails;
  React.useEffect(() => {
    if (isDownloadPage && invoiceDetails) {
      setTimeout(() => {
        window.print();

      }, 2000);
    }
  }, [invoiceDetails, isDownloadPage]);

  const vendor = invoiceDetails?.vendor || invoiceDetails?.vendorDetails
  const client = invoiceDetails?.client || invoiceDetails?.clientDetails

  return (
    <AppContentCard
      className="invoice_preview"
      style={isDownloadPage ? { margin: "auto", width: "1062px" } : {}}
    >
      <Row>
        <Col span={12}>

          {/* <KeyValue
            title="Invoice No. #"
            value={invoiceDetails?.invoiceNumber}
            titleWidth={"150px"}
            titleWeight={600}
            valueWeight={600}
            marginBottom={isDownloadPage ? "0" : ""}
          /> */}
          <KeyValue
            title="Issue Date"
            value={new Date(invoiceDetails.issueDate).toDateString()}
            titleWidth={"150px"}
            titleWeight={600}
            valueWeight={600}
            marginBottom={isDownloadPage ? "0" : ""}
          />
          <KeyValue
            title="Due Date"
            value={new Date(invoiceDetails.dueDate).toDateString()}
            titleWidth={"150px"}
            titleWeight={600}
            valueWeight={600}
            marginBottom={isDownloadPage ? "0" : ""}
          />
          <KeyValue
            title="Time for Payment"
            value={(invoiceDetails?.daysForPayment || 0) + " Days"}
            titleWidth={"150px"}
            titleWeight={600}
            valueWeight={600}
            marginBottom={isDownloadPage ? "0" : ""}
          />
        </Col>
        <Col span={12}  >
          <Row justify={"end"}>

            <Image
              width={125}
              height={125}
              src={invoiceDetails?.vendor?.logoUrl}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ marginTop: "1rem" }}>
        <Col span={12}>
          <div
            className={`invoice_preview_billed_card ${isDownloadPage ? "invoice_download_billed_card" : ""
              }`}
          >
            <Typography
              variant="h5"
              className="invoice_preview_billed_card_heading"
            >
              Bill By
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {vendor?.name}
            </Typography>
            <Typography variant="body1">
              {vendor?.streetAddress},{" "}
              {vendor?.city}, {vendor?.state},{" "}
              {vendor?.country}
            </Typography>
          </div>
        </Col>
        <Col span={12}>
          <div
            className={`invoice_preview_billed_card ${isDownloadPage ? "invoice_download_billed_card" : ""
              }`}
          >
            <Typography
              variant="h5"
              className="invoice_preview_billed_card_heading"
            >
              Bill To
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {client?.name}
            </Typography>
            <Typography variant="body1">
              {client?.streetAddress},{" "}
              {client?.city}, {client?.state},{" "}
              {client?.country}
            </Typography>
          </div>
        </Col>
      </Row>
      {invoiceDetails && (
        <ItemsSummary
          invoiceDetails={invoiceDetails}
          isDownloadPage={isDownloadPage}
        />
      )}

      {invoiceDetails?.signature && <div className="new_invoice_documents_wrapper">
        <div></div>
        <div className="new_invoice_documents">
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Image
              width={100}
              height={100}
              src={invoiceDetails?.signature}
            />

          </div>

        </div>
      </div>}

      <div className="invoice_preview_terms_and_conditions">
        <Typography variant="h6">Terms and Conditions</Typography>
        <div>
          {termsAndConditions &&
            termsAndConditions?.map((each: string, index: number) => (
              <Typography
                variant="body1"
                className="invoice_preview_terms_and_conditions_item"
              >
                {index + 1}. {each}
              </Typography>
            ))}
          {(!termsAndConditions || termsAndConditions?.length === 0) && (
            <Typography
              variant="body1"
              className="invoice_preview_terms_and_conditions_item"
            >
              No terms and conditions
            </Typography>
          )}
        </div>
      </div>

      {!isDownloadPage && (
        <Space size={20} className="invoice_preview_submit_actions">
          <OutlinedButton
            onClick={() => {
              window.open(
                `/dashboard/template/download?id=${id}&template-id=${templateId}`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            <Download />
          </OutlinedButton>

          <ContainedButton onClick={() => { setOpenInvoiceNumberModal(true) }}>Issue Invoice</ContainedButton>
        </Space>
      )}
    </AppContentCard>
  );
};

import React from "react";
import { Button } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
interface Props {
  loginLoading: Boolean;
  email: string;
  handleSentEmailAgain: () => void;
  backToLogin: () => void;
}

const SentUI: React.FC<Props> = (props) => {
  const { email, handleSentEmailAgain, backToLogin, loginLoading } = props;
  return (
    <div className="sent">
      <div className="sent-icon-container">
        <div className="sent-icon">
          <MailOutlineIcon htmlColor="#ffffff" />
        </div>
      </div>
      <div className="sent-text">
        <div className="sent-text-head">Check your email</div>
        <div className="sent-text-message">
          We have sent a link to {email}.
          <br />
          Please click the link and you would be redirected to the next step.
        </div>
      </div>
      <div className="sent-buttons">
        <Button
          className="sent-buttons-mail"
          variant="outlined"
          target="_blank"
          href="https://mail.google.com/mail/"
        >
          <img src="./images/gmail.png" alt="gmail" />
          Open Gmail
        </Button>
        <Button
          className="sent-buttons-outlook"
          variant="outlined"
          target="_blank"
          href="https://outlook.office.com/mail/"
        >
          <img src="./images/outlook.png" alt="outlook" />
          Open Outlook
        </Button>
      </div>
      <div className="sent-again">
        Can't find it? Check your spam folder or&nbsp;
        {loginLoading ? (
          <span style={{ cursor: "text" }}>Sending ... </span>
        ) : (
          <span onClick={handleSentEmailAgain}>
            click to send the link again
          </span>
        )}
        . Noticed a typo in email?&nbsp;
        <span onClick={backToLogin}>Fix your email address</span>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SentUI;

export const privacyPolicy = {
  name: "Privacy Policy",
  docCodeType: "multiple",
  path: "privacyPolicy",
  type: "Product ",
  tempCode: "privacy-policy",
  disclaimer:
    "A privacy policy is a legal document that outlines how a website/app collects, uses, stores, & shares users' information.",
  docCodeValues: [
    {
      docCode: "privacy-policy-mobile",
    },
    {
      docCode: "privacy-policy-website",
    },
  ],
  page: 1,
  pageHeading:
    "Enter below details to generate an automatically edited Privacy Policy for you!",
  fields: [
    {
      page: 1,
      name: "Date on which the Privacy Policy comes into effect:",
      field: [
        {
          key: "effective_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the company/entity/person operating:",
      field: [
        {
          key: "entity_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "entity_address",
          type: "input",
          width: "half",
          placeholder: "Company Address",
        },
      ],
    },
    {
      page: 1,
      name: "Generate it to be used for:",
      field: [
        {
          key: "condition_policy",
          type: "radio",
          width: "initial",
          options: [
            {
              label: "Mobile",
              value: "1",
            },
            {
              label: "Website",
              value: "2",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Name of the Mobile Application:",
      policyCondition: "1",
      field: [
        {
          key: "app_name",
          type: "input",
          width: "half",
          placeholder: "App Name",
        },
      ],
    },
    {
      page: 1,
      policyCondition: "2",
      name: "Website URL:",
      field: [
        {
          key: "web_url",
          type: "input",
          width: "half",
          placeholder: "Website Url",
        },
      ],
    },
    {
      page: 1,
      name: "Personal information collected when a user signs up or uses the service (separated by commas):",
      field: [
        {
          key: "personal_info",
          type: "input",
          width: "full",
          placeholder:
            "Ex: Name, Phone number, Address, Email, Location and so on",
        },
      ],
    },
    {
      page: 1,
      name: "Email for contact:",
      field: [
        {
          key: "contact_email",
          type: "input",
          width: "half",
          placeholder: "Email",
        },
      ],
    },
  ],
};

import { baseAPI } from "..";

export interface CompanyType {
  id?: string | null;
  token?: string;
  companyName?: string;
  numberOfEmployees?: string;
  country?: string;
  companyType?: string;
  email?: string;
  role?: string;
  invites?: any;
  data?: any;
  website?: string;
  emails?: any;
  description?: string;
}

export const company = `${baseAPI}/company`;

export const allCompanies = `${baseAPI}/company/all`;

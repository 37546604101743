// @flow
import * as React from "react";
import { TableIn } from "../../../../UILibrary/Table/Table";

import { Space } from "antd";

import { Column } from "../../../../Types/global.types";
const EditIcon = require("../../../../images/edit.png");
const DeleteIcon = require("../../../../images/delete.png");

type Props = {
  activeInvoiceTemplates: any;
  handleEdit: Function;
  handleSingleDelete: Function;
};
export const ActiveTemplates = ({
  handleSingleDelete,
  activeInvoiceTemplates,
  handleEdit,
}: Props) => {
  const columns: Column[] = [
    {
      title: "Name",
      columnKey: "name",
      key: "name",
      width: "180px",
    },
    // {
    //   title: "Invoice#",
    //   columnKey: "invoiceNumber",
    //   key: "invoiceNumber",
    //   width: "120px",
    // },
    {
      title: "Billed To",
      columnKey: "client",
      key: "billTo",
      width: "238px",
      render: (client: any) => client?.name || "-",
    },
    // { title: "Status", columnKey: "status", key: "status", width: "183px" },
    // { title: "PAN", columnKey: "panNumber", key: "panNumber", width: "127px" },
    // { title: "Country", columnKey: "country", key: "country", width: "94px" },
    {
      title: "",
      columnKey: "actions",
      key: "actions",
      width: "100%",
      align: "right",
      render: (_: any, row: any, index: number) => (
        <Space size={25} align="start">
          <div className="list_action" onClick={() => handleEdit(row)}>
            <div className="list_action_icon">
              <img width={"100%"} height={"100%"} src={EditIcon} alt="Edit" />
            </div>
            <div className="list_action_text">Edit</div>
          </div>
          {/* <div className="list_action">
            <div className="list_action_icon">
              <img
                width={"100%"}
                height={"100%"}
                src={InvoiceIcon}
                alt="Create Invoice"
              />
            </div>
            <div className="list_action_text">Create Invoice</div>
          </div> */}
          <div
            className="list_action"
            onClick={() => handleSingleDelete(row.id)}
          >
            <div className="list_action_icon list_action_delete">
              <img
                width={"100%"}
                height={"100%"}
                src={DeleteIcon}
                alt="Delete"
              />
            </div>
            <div className="list_action_text">Delete</div>
          </div>
        </Space>
      ),
    },
  ];
  return (
    <>
      <TableIn
        // classname="active_clients_table"
        columns={columns}
        data={activeInvoiceTemplates}
      />
    </>
  );
};

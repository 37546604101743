import { loaderActions } from "../action/actions";
import { loadingInitialState } from "./initialState";
import { loaderActionType } from "../type";

const loaderReducer = (
  state = loadingInitialState,
  action: loaderActionType
) => {
  switch (action.type) {
    case loaderActions.SET_LOADING:
      return { ...state, loading: action.payload };
    case loaderActions.LOADING_TEXT:
      return { ...state, loadingText: action.payload };
    default:
      return state;
  }
};

export default loaderReducer;

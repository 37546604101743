import { userApiStateType } from "../type";

export const userApiInitialState: userApiStateType = {
  userLoaded: false,
  userDetails: {
    firstName: "",
    lastName: "",
  },
  user: false,
};

export const numberOfEmployeesSelect = [
  {
    value: "OneToTen",
    label: "1-10",
  },
  {
    value: "ElevenToFifty",
    label: "11-50",
  },
  {
    value: "FiftyOneToHundred",
    label: "51-100",
  },
  {
    value: "AboveHundred",
    label: "More than 100",
  },
];

export const countryAndType = [
  {
    country: "India",
    countryTypes: [
      "Private Limited",
      "LLP",
      "Partnership",
      "Proprietorship",
      "HUF",
    ],
  },
  {
    country: "US",
    countryTypes: ["Delaware C-Corp"],
  },
  {
    country: "Singapore",
    countryTypes: ["Singapore Company"],
  },
];

import { useSelector } from "react-redux";
import {
  TaxConfigObject,
  columnDefinition,
} from "../../../Types/invoice.types";

const calculateRowValues = (
  row: any,
  taxObject: any,
  isAmountChanged?: boolean
) => {
  const rate: number = parseFloat(row?.rate) || 0;
  const quantity: number = parseFloat(row?.quantity) || 0;
  const taxRate: number = parseFloat(row?.taxRate) || 0;
  const discount: number = parseFloat(row?.discount) || 0;

  const totalTax: number = (rate * quantity * taxRate) / 100;

  if (isAmountChanged) {
    row.rate = (row.amount + discount) / quantity;
  } else {
    row.amount = Number(rate * quantity - discount).toFixed(2);
  }
  taxObject?.taxAmountColumns.forEach((each: any) => {
    row[each.variable] = Number(totalTax / (taxObject?.taxAmountColumns?.length || 1)).toFixed(2);
  });
  row.total = (parseFloat(row.amount || 0) + (totalTax || 0)).toFixed(2);

  return row
};

const getUpdatedColumnDefinitions = (
  isItemWiseDiscount: boolean,
  taxObject: TaxConfigObject | undefined
) => {
  const columns: columnDefinition[] | undefined[] = [
    {
      name: "Item",
      columnKey: "itemName",
      hidden: false,
    },
    {
      name: taxObject?.taxRateColumn.label || "Tax Rate",
      columnKey: "taxRate",
      hidden: false,
    },
    {
      name: "Quantity",
      columnKey: "quantity",
      hidden: false,
    },
    {
      name: "Rate",
      columnKey: "rate",
      hidden: false,
    },
    {
      name: "Amount",
      columnKey: "amount",
      hidden: false,
    },
    ...(taxObject?.taxAmountColumns?.map(each => ({
      name: each.label,
      columnKey: each.variable,
      hidden: false,
    })) || []),
    {
      name: "Total",
      columnKey: "total",
      hidden: false,
    },
  ];

  if (isItemWiseDiscount) {
    columns.splice(4, 0, {
      name: "Discount",
      columnKey: "discount",
      hidden: false,
    });
  }

  return columns;
};
export {
  calculateRowValues,
  getUpdatedColumnDefinitions,
};

// @flow
import * as React from "react";

import ImgCrop from "antd-img-crop";
import { Col, Image, Row, Space, Upload } from "antd";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import "./NewInvoiceUI.scss";

import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { TextButton } from "../../../UILibrary/Buttons/TextButton/TextButton";
import { KeyValue } from "../../../UILibrary/KeyValue/KeyValue";
import { InvoiceItems } from "./InvoiceItems/InvoiceItems";
import { OutlinedButton } from "../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import { TermsAndConditions } from "./TermsAndConditions/TermsAndConditions";
import { RecurringInvoice } from "./RecurringInvoice/RecurringInvoice";
import { ContainedButton } from "../../../UILibrary/Buttons/ContainedButton/ContainedButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
import { RootReducer } from "../../../redux/rootReducer";
import { handleInvoiceKeyValueChange, setInvoiceDetails } from "../../../redux/Invoice/invoiceActionCreator";
import { Invoice } from "../../../Types/invoice.types";
import { toastIn } from "../../../UILibrary/Toast/Toast";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { setOpenTemplateNameModal } from "../../../redux/Modals/modalActionCreator";

type Props = {
  signature: any;
  onPreview: (file: UploadFile<any>) => void;
  onSignatureChange: (info: UploadChangeParam<UploadFile<any>>) => void;
  setShowShippingDetails: Function;
  showShippingDetails: boolean;
  billedByBusinesses: any;
  billedToBusinesses: any;
  setBusinessModalState: Function;
  selectedBillByBusiness: any;
  selectedBillToBusiness: any;
  companyId: string | null;
  handleSaveAndContinue: Function;
  setOpenInvoiceNumberModal: Function;
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
  isMutatePatchInvoiceTemplateLoading: boolean;
  templateId: string | null;
  isLoading: boolean
};

export const NewInvoiceUI: React.FC<Props> = ({
  signature,
  onPreview,
  onSignatureChange,
  showShippingDetails,
  setShowShippingDetails,
  billedByBusinesses,
  billedToBusinesses,
  setBusinessModalState,
  selectedBillByBusiness,
  selectedBillToBusiness,
  companyId,
  handleInvoiceChangeOnBlur,
  handleSaveAndContinue,
  isMutatePatchInvoiceTemplateLoading,
  setOpenInvoiceNumberModal,
  templateId,
  isLoading
}: Props) => {
  const invoiceDetails = useSelector((state: RootReducer) => state?.invoice);

  const dispatch = useDispatch();

  return (
    <AppContentCard
      className="new_invoice"
      style={{ paddingBottom: "144px", marginBottom: "112px" }}
    >
      <Row gutter={[20, 20]} className="form-row">
        <Col span={12}>
          <Row gutter={[20, 20]} className="form-row">

            {invoiceDetails?.name && <Col span={24}>
              <div>
                <Typography >
                  Template Name
                </Typography>
                <Space size={10}>

                  <Typography style={{ color: "grey" }}>
                    {invoiceDetails?.name}
                  </Typography>
                  <IconButton onClick={() => dispatch(setOpenTemplateNameModal({ key: true, isEdit: true, templateId, templateName: invoiceDetails?.name }))}>
                    <EditIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </Space>
              </div>

            </Col>}
            <Col span={24}>
              <label htmlFor="issueDate">Issue Date</label>
              <input
                className="input"
                onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
                type="date"
                name="issueDate"
                id="issueDate"
                placeholder="Date"
                value={invoiceDetails?.issueDate}
                defaultValue={new Date().toUTCString()}
                onBlur={e =>
                  handleInvoiceChangeOnBlur({
                    issueDate: invoiceDetails?.issueDate,
                  })
                }
              />
            </Col>
            <Col span={24}>
              <label htmlFor="issueDate">Time (in Days) for Payment</label>

              <input
                className="input"
                onChange={e =>
                  dispatch(
                    handleInvoiceKeyValueChange(
                      e,
                      "daysForPayment",
                      Number(e.target.value)
                    )
                  )
                }
                type="number"
                min={0}
                name="daysForPayment"
                id="daysForPayment"
                placeholder="Number of Days"
                value={invoiceDetails?.daysForPayment}
                onBlur={() =>
                  handleInvoiceChangeOnBlur({
                    daysForPayment: invoiceDetails?.daysForPayment,
                  })
                }
              />
            </Col>
            <Col span={24}>
              <label htmlFor="dueDate">Due Date</label>
              <input
                className="input"
                onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
                type="date"
                name="dueDate"
                id="dueDate"
                defaultValue={new Date().toUTCString()}
                value={invoiceDetails?.dueDate}
                onBlur={() =>
                  handleInvoiceChangeOnBlur({
                    dueDate: invoiceDetails?.dueDate,
                  })
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}  >
          <Row justify={"end"}>

            <Image
              width={125}
              height={125}
              src={selectedBillByBusiness?.logoUrl}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="form-row">
        <Col span={12}>
          <div className="new_invoice_billing_card">
            <div className="space_between">
              <p className="form-subheading">
                Billed By{" "}
                <span style={{ color: "grey", fontSize: "0.9rem" }}>
                  (Your Details)
                </span>
              </p>
              <TextButton
                active
                icon={<AddRoundedIcon />}
                onClick={() => {
                  setBusinessModalState({
                    open: true,
                    title: "Add Business",
                    mode: "ADD",
                    type: "BUSINESS",
                  });
                }}
              >
                Add Business
              </TextButton>
            </div>
            <select
              className="input new_invoice_company_select"
              name="vendorId"
              id="vendorId"
              onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
              placeholder="Select Host Company"
              value={invoiceDetails?.vendorId}
              onBlur={() =>
                handleInvoiceChangeOnBlur({
                  vendorId: invoiceDetails?.vendorId || "",
                })
              }
            >
              {billedByBusinesses?.map((billedBy: any) => (
                <option key={billedBy.id} value={billedBy.id}>
                  {billedBy.name}
                </option>
              ))}
            </select>

            <div className="new_invoice_billing_card_info">
              <Row gutter={[20, 20]} style={{ marginBottom: "2rem" }}>
                <Col span={18}>
                  <Typography variant="subtitle1">Business Details</Typography>
                </Col>
                <Col span={6}>
                  <TextButton
                    active
                    icon={<EditIcon />}
                    onClick={() => {
                      setBusinessModalState({
                        open: true,
                        title: "Edit Business",
                        mode: "EDIT",
                        type: "BUSINESS",
                        businessDetails: selectedBillByBusiness,
                      });
                    }}
                  >
                    Edit
                  </TextButton>
                </Col>
              </Row>

              <KeyValue
                title="Business Name"
                titleWidth={"130px"}
                value={selectedBillByBusiness?.name}
              />
              <KeyValue
                title="Address"
                titleWidth={"130px"}
                value={selectedBillByBusiness?.country}
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="new_invoice_billing_card">
            <div className="space_between">
              <p className="form-subheading">
                Billed To{" "}
                <span style={{ color: "grey", fontSize: "0.9rem" }}>
                  (Client Details)
                </span>
              </p>
              <TextButton
                active
                icon={<AddRoundedIcon />}
                onClick={() => {
                  setBusinessModalState({
                    open: true,
                    title: "Add Client",
                    mode: "ADD",
                    type: "CLIENT",
                  });
                }}
              >
                Add Client
              </TextButton>
            </div>
            <select
              className="input new_invoice_company_select"
              name="clientId"
              id="clientId"
              onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
              placeholder="Select Host Company"
              value={invoiceDetails?.clientId}
              onBlur={() =>
                handleInvoiceChangeOnBlur({
                  clientId: invoiceDetails?.clientId || "",
                })
              }
            >
              {billedToBusinesses?.map((billedTo: any) => (
                <option key={billedTo.id} value={billedTo.id}>
                  {billedTo.name}
                </option>
              ))}
            </select>

            <div className="new_invoice_billing_card_info">
              <Row gutter={[20, 20]} style={{ marginBottom: "2rem" }}>
                <Col span={18}>
                  <Typography variant="subtitle1">Business Details</Typography>
                </Col>
                <Col span={6}>
                  <TextButton
                    active
                    icon={<EditIcon />}
                    onClick={() => {
                      setBusinessModalState({
                        open: true,
                        title: "Edit Client",
                        mode: "EDIT",
                        type: "CLIENT",
                        businessDetails: selectedBillToBusiness,
                      });
                    }}
                  >
                    Edit
                  </TextButton>
                </Col>
              </Row>

              <KeyValue
                title="Business Name"
                titleWidth={"130px"}
                value={selectedBillToBusiness?.name}
              />
              <KeyValue
                title="Address"
                titleWidth={"130px"}
                value={selectedBillToBusiness?.country}
              />
            </div>
          </div>
        </Col>
      </Row>

      {/* <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <div className="checkbox-input">
            <input
              className="input checkbox"
              type="checkbox"
              name="email"
              id="email"
              placeholder="Email"
              onChange={(e) => {
                setShowShippingDetails(!showShippingDetails);
                console.log(e.target.value, "Checkbox 1");
              }}
            />
            Add Shipping Details
          </div>
        </Col>
        {showShippingDetails && (
          <>
            <Col span={12}>
              <div className="new_invoice_billing_card">
                <p className="form-subheading">Shipped From</p>

                <select
                  className="input new_invoice_company_select"
                  name="vendorId"
                  id="vendorId"
                  onChange={(e) => dispatch(handleInvoiceKeyValueChange(e))}

                  placeholder="Select Host Company"
                >
                  <option>Refrens</option>
                  <option>Cashwise</option>
                  <option>
                    <ContainedButton>Add Client</ContainedButton>
                  </option>
                </select>
              </div>
            </Col>
            <Col span={12}>
              <div className="new_invoice_billing_card">
                <p className="form-subheading">Shipped To</p>
              </div>
            </Col>
            <Col span={12}>
              <div className="new_invoice_billing_card">
                <p className="form-subheading">Transport Details</p>
              </div>
            </Col>
          </>
        )}
      </Row> */}

      <InvoiceItems
        handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur}
        isLoading={isLoading}
      />
      <div className="new_invoice_documents_wrapper">
        <div></div>
        <div className="new_invoice_documents">
          {invoiceDetails?.signature && <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Image
              width={100}
              height={100}
              src={invoiceDetails?.signature}
            />
            <TextButton active style={{ display: "block" }} onClick={() => dispatch(setInvoiceDetails({ signature: "" }))}>Replace</TextButton>
          </div>}
          {!invoiceDetails?.signature && <ImgCrop rotationSlider>
            <Upload
              beforeUpload={() => false}
              listType="picture-card"
              fileList={signature}
              maxCount={1}
              multiple={false}
              onChange={onSignatureChange}
              onPreview={onPreview}
              className="new_invoice_documents_signature"
              defaultFileList={
                invoiceDetails?.signature
                  ? [
                    {
                      uid: "1",
                      name: "signature",
                      status: "done",
                      url: invoiceDetails.signature,
                    },
                  ]
                  : []
              }
            >
              {signature.length === 0 && (
                <OutlinedButton dashed icon={<BorderColorIcon />}>
                  Add Signature
                </OutlinedButton>
              )}
            </Upload>
          </ImgCrop>}


        </div>
      </div>
      <TermsAndConditions
        handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur}
      />
      <RecurringInvoice handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur} />

      <Space size={20} className="new_invoice_submit_actions">
        <OutlinedButton
          onClick={() => {
            window.open(
              `/dashboard/template/preview?id=${companyId}&template-id=${invoiceDetails?.id}`,
              "_blank",
              "noreferrer"
            );
          }}
        >
          <RemoveRedEyeIcon />
        </OutlinedButton>
        <ContainedButton
          isLoading={isMutatePatchInvoiceTemplateLoading}
          alignLoader="center"
          onClick={() => {
            handleSaveAndContinue(invoiceDetails, () =>
              toastIn.success("Invoice Template Saved Successfully")
            );
          }}
        >
          Save
        </ContainedButton>
        <OutlinedButton onClick={() => { setOpenInvoiceNumberModal(true) }}>Issue Invoice</OutlinedButton>

      </Space>
    </AppContentCard>
  );
};

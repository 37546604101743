import React, { useEffect, useState } from "react";
import CoworkerInvite from "./coworkerInvite";
import CoworkerDetail, { List } from "./coworkerDetails";
import { getAllCollabrator } from "../../../api/companies/companies";
interface Props {
  id: string;
}
const CoworkersUI = ({ id }: Props) => {
  const [coworkerList, setCoworkerList] = useState<List[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const getColl = await getAllCollabrator({ id });
      if (getColl && getColl.type === "success") {
        setCoworkerList(getColl.data);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <CoworkerInvite id={id} {...{ coworkerList, setCoworkerList }} />
      <CoworkerDetail id={id} {...{ coworkerList, setCoworkerList }} />
    </div>
  );
};

export default CoworkersUI;

export const invoiceInitialState: any = {
  currency: "INR",
  termsAndConditions: [""],
  lineItems: [
    {
      itemName: "",
      hsnsac: "",
      taxRate: 0,
      quantity: 1,
      rate: 1,
      cgst: 0,
      sgst: 0,
      amount: 1,
      total: 1,
    },
  ],
  additionalCharges: [],
  reductions: [],
  taxScheme: "CGST & SGST",
};

import * as React from "react";
import { InvoicePreviewUI } from "./InvoicePreviewUI";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getInvoiceById,
  getInvoiceTemplateById,
} from "../../../api/invoice/invoice.api";
import { InvoiceNumberModal } from "../../../Modals/InvoiceNumberModal/InvoiceNumberModal";
import { useDispatch } from "react-redux";
import { setLoadMessage } from "../../Loader/state/action/actionCreator";
import { setInvoiceDetails } from "../../../redux/Invoice/invoiceActionCreator";

type Props = {};

export const InvoicePreview = (props: Props) => {
  const dispatch = useDispatch()
  const [searchparams] = useSearchParams();
  const templateId: any = searchparams.get("template-id");
  const invoiceId: any = searchparams.get("invoice-id");
  const companyId: any = searchparams.get("id");

  const [openInvoiceNumberModal, setOpenInvoiceNumberModal] =
    React.useState(false);

  let {
    isLoading,
    data: templateDetails,

  } = useQuery({
    queryKey: [companyId, "template", templateId], queryFn: () =>
      getInvoiceTemplateById(companyId, templateId),
    enabled: !!templateId
  });

  let {
    isLoading: isInvoiceLoading,
    data: invoiceDetails,

  } = useQuery({
    queryKey: [companyId, "invoice", invoiceId],
    queryFn: () => getInvoiceById(invoiceId, companyId),
    enabled: !!invoiceId
  });


  const location = useLocation();
  const isDownloadPage = location.pathname === "/dashboard/invoice/download" || location.pathname === "/dashboard/template/download";

  React.useEffect(() => {
    if (isLoading || isInvoiceLoading) {
      dispatch(setLoadMessage("Loading..."))
    } else {
      dispatch(setLoadMessage(""))

    }
  }, [dispatch, isInvoiceLoading, isLoading])

  React.useEffect(() => {
    if (!isLoading && invoiceDetails) {
      dispatch(setInvoiceDetails(invoiceDetails))
    }
  }, [dispatch, invoiceDetails, isInvoiceLoading, isLoading])



  return (
    <>
      {(invoiceDetails || templateDetails) && (
        <InvoicePreviewUI
          invoiceDetails={invoiceDetails || templateDetails}
          templateId={templateId}
          setOpenInvoiceNumberModal={setOpenInvoiceNumberModal}
          isDownloadPage={isDownloadPage}
        />
      )}
      {openInvoiceNumberModal && (
        <InvoiceNumberModal
          open={openInvoiceNumberModal}
          handleCancel={() => setOpenInvoiceNumberModal(false)}
          companyId={companyId}
          templateId={templateId}
        />
      )}
    </>
  );
};

// @flow
import * as React from "react";

import { ContainedButton } from "../../../../UILibrary/Buttons/ContainedButton/ContainedButton";
import { useDispatch } from "react-redux";
import { setOpenTemplateNameModal } from "../../../../redux/Modals/modalActionCreator";


export const InvoiceTemplatesFooter = () => {
  const dispatch = useDispatch()
  const handleAddNewTemplate = () => {
    dispatch(setOpenTemplateNameModal({ key: true }));
  };

  return (
    <div className="clients_footer">
      <ContainedButton onClick={handleAddNewTemplate}>
        Create New Template
      </ContainedButton>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import auth from "../../../../firebase/auth";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import CompanyInvitedUI from "./CompanyInvitedUI";
import ProjectContainer from "../../container/container";
import { acceptInvite } from "../../../../api/companies/companies";
import "./styles.scss";

const CompanyInvited: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Accept Invite";
  }, []);
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const name = searchParams.get("name");

  const creatorFirstName = searchParams.get("creatorFirstName");
  const creatorlastName = searchParams.get("creatorlastName");

  const acceptInviteReq = async () => {
    setAcceptLoading(true);
    try {
      const send = await acceptInvite({ id });
      if (send.type === "success") {
        navigate({
          pathname: "/",
          search: createSearchParams({}).toString(),
        });
        setAcceptLoading(false);
      } else {
        throw new Error("Error while accepting Invite");
        setAcceptLoading(false);
      }
    } catch (error) {
      setAcceptLoading(false);

      toast.error("Error while acepting Invite ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <ProjectContainer
      children={
        <CompanyInvitedUI
          {...{
            id,
            name,
            creatorFirstName,
            creatorlastName,
            acceptInviteReq,
            acceptLoading,
          }}
        />
      }
    />
  );
};

export default CompanyInvited;

// @flow
import { Col, Modal, Row } from "antd";
import * as React from "react"; 

import { Typography } from "@mui/material";
import { useMutation } from "react-query";
import { toastIn } from "../../UILibrary/Toast/Toast";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  postInvoice, 
} from "../../api/invoice/invoice.api";
import { Invoice } from "../../Types/invoice.types";
type Props = {
  handleCancel: () => void;
  companyId: string | null;
  templateId: string | null;
  open: boolean;
};
export const InvoiceNumberModal = ({
  open,
  handleCancel,
  companyId,
  templateId,
}: Props) => {
  const navigate = useNavigate();
  const [invoiceNumber, setInvoiceNumber] = React.useState("");

  const {
    mutate: mutateInvoiceTemplate,
    isLoading: isMutateInvoiceTemplateLoading,
  } = useMutation({
    mutationFn: () => postInvoice(companyId, invoiceNumber, templateId),
    onSuccess: (newTemplate: Invoice) => {
      navigate({
        pathname: `/dashboard/invoice/summary`,
        search: createSearchParams({
          id: companyId || "",
        }).toString(),
      });
    },
    onError: (error: Error) => {
      toastIn.error(error.message);
    },
  });

  const handleOk = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // combine address and business details state
    try {
      mutateInvoiceTemplate();
    } catch (error: Error | any) {
      toastIn.error(error?.message || "");
    }
  };
  return (
    <Modal
      title={<Typography variant="h6">Issue Invoice</Typography>}
      open={open}
      onOk={handleOk}
      confirmLoading={isMutateInvoiceTemplateLoading}
      onCancel={handleCancel}
      className="business_modal"
      width={500}
    >
      <Row gutter={[20, 20]} className="form-row">
        {/* <Col span={24}>
          <p className="form-subheading">
            Please enter the name of the template:
          </p>
        </Col> */}

        <Col span={24}>
          <form>
            <input
              className="input"
              onChange={e => setInvoiceNumber(e.target.value)}
              type="text"
              name="invoiceNumber"
              id="invoiceNumber"
              required
              placeholder="Invoice Number"
              value={invoiceNumber}
            />
          </form>
        </Col>
      </Row>
    </Modal>
  );
};

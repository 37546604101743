import { nda } from "./docDetails/nda";
import { cofounderAgreement } from "./docDetails/cofounderAgreement";
import { serviceAgreement } from "./docDetails/serviceAgreement";
import { consultionAgreement } from "./docDetails/consultingAgreement";
import { offerLetter } from "./docDetails/offerLetter";
import { employmentAgreement } from "./docDetails/employmentAgreement";
import { privacyPolicy } from "./docDetails/privacyPolicy";
import { termsConditions } from "./docDetails/termsAndConditions";

export const docGeneration = [
  cofounderAgreement,
  nda,
  consultionAgreement,
  serviceAgreement,
  employmentAgreement,
  offerLetter,
  privacyPolicy,
  termsConditions,
];

import React, { useState, useEffect } from "react";
import auth from "../../../../firebase/auth";

import { toast } from "react-toastify";
import { createSearchParams, useNavigate } from "react-router-dom";
import ProjectContainer from "../../container/container";
import CompanyCreateUI from "./CompanyCreateUI";
import { addNewCompany } from "../../../../api/companies/companies";
import { FormData } from "./type";
import "./styles.scss";
import { useDispatch } from "react-redux";

const CompanyCreate: React.FC = () => {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [data, setData] = useState<FormData>({
    companyName: "",
    numberOfEmployees: "",
    country: "",
    companyType: "",
  });

  useEffect(() => {
    document.title = "Create Entity";
  }, []);

  const handleChange = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
    if (name === "country") {
      setData({
        ...data,
        [name]: value,
        companyType: "",
      });
    }
  };

  const handleFormSubmit = async () => {
    setSubmitLoading(true);
    try {
      const { companyName, numberOfEmployees, country, companyType } = data;
      const addComp = await addNewCompany({
        companyName,
        numberOfEmployees,
        country,
        companyType,
      });
      if (addComp && addComp.type === "success") {
        navigate({
          pathname: "/companies/invite",
          search: createSearchParams({
            id: addComp.data.companyId,
          }).toString(),
        });
        setSubmitLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        "Error occured while creating your account. Please try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      setSubmitLoading(false);
    }
  };
  return (
    <ProjectContainer
      children={
        <CompanyCreateUI
          {...{
            data,
            handleChange,
            handleFormSubmit,
            submitLoading,
          }}
        />
      }
    />
  );
};

export default CompanyCreate;

import React, { useState, useEffect } from "react";
import auth from "../../../../firebase/auth";
import { toast } from "react-toastify";
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  getIndivCompany,
  addCollabrators,
} from ".././../../../api/companies/companies";
import { InputType } from "./type";
import CompanyInviteUI from "./CompanyInviteUI";
import ProjectContainer from "../../container/container";
import "./styles.scss";
import { useDispatch } from "react-redux";
import {
  setLoadMessage,
  setLoader,
} from "../../../Loader/state/action/actionCreator";
const CompanyInvite: React.FC = () => {
  const navigate = useNavigate();

  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const [companyName, setCompanyName] = useState<string>("");
  const [loadCompanyName, setLoadCompanyName] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [sentLoading, setSentloading] = useState<boolean>(false);
  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(setLoadMessage("Loading Invite"));
    document.title = "Invite Collabrators";
    (async () => {
      try {
        const company = await getIndivCompany({ id });
        if (company && company.type === "success") {
          setCompanyName(company.data.name);
          setLoadCompanyName(false);
          dispatch(setLoader(false));
          dispatch(setLoadMessage("Loading Cashwise"));
        } else if (company && company.type === "error") {
          throw new Error("Something went wrong");
        }
      } catch (error) {
        toast.error("Something went wrong!");
        setLoadCompanyName(false);
        dispatch(setLoader(false));
        dispatch(setLoadMessage("Loading Cashwise"));
      }
    })();
  }, []);

  const [inputs, setInputs] = useState<InputType[]>([
    {
      value: "",
    },
  ]);
  const [result, setResult] = useState<any>({});
  useEffect(() => {
    let val = {};
    const data = inputs.map((item, indx) => {
      val = { ...val, [item.value]: "ADMIN" };
    });
    setResult(val);
  }, [inputs]);

  const addRow = () => {
    const values = [...inputs];
    values.push({ value: "" });
    setInputs([...values]);
  };
  const deleteRow = (indx: number) => {
    const values = [...inputs];
    values.splice(indx, 1);
    setInputs([...values]);
  };
  const handleChange = (indx: number, value: string) => {
    const values = [...inputs];
    values[indx].value = value;
    setInputs(values);
  };

  const handleInvite = async () => {
    setSentloading(true);
    try {
      const send = await addCollabrators({ id, invites: result });
      if (send.type === "success") {
        navigate({
          pathname: "/companies/completed",
          search: createSearchParams({
            id: id,
          }).toString(),
        });
        setSentloading(false);
      } else {
        setSentloading(false);
        throw new Error("Error while adding Collaborators");
      }
    } catch (error) {
      setSentloading(false);
      toast.error("Error while adding Collaborators", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleSkip = () => {
    navigate({
      pathname: "/companies/completed",
      search: createSearchParams({
        id: id,
      }).toString(),
    });
  };
  if (!id) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <ProjectContainer
        children={
          <>
            {!loadCompanyName && (
              <CompanyInviteUI
                {...{
                  companyName,
                  inputs,
                  addRow,
                  deleteRow,
                  handleChange,
                  handleInvite,
                  handleSkip,
                  sentLoading,
                }}
              />
            )}
          </>
        }
      />
    );
  }
};

export default CompanyInvite;

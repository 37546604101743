import { invoiceInitialState } from "./InvoiceInitial";
import { invoiceActions } from "./invoiceActions";
import { invoiceType } from "./invoiceTypes";

const invoiceReducer = (state = invoiceInitialState, action: invoiceType) => {
  switch (action.type) {
    case invoiceActions.SET_INVOICE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };

    case invoiceActions.HANDLE_INVOICE_KEY_VALUE_CHANGE:
      return {
        ...state,
        ...action.payload,
      };

    case invoiceActions.SET_INVOICE_ITEM_AT_INDEX:
      const { index, row } = action.payload
      let copyLineItems = state?.lineItems || []
      if (!copyLineItems?.length) {
        copyLineItems = [{ ...row }]
      } else {
        copyLineItems.splice(index, 1, { ...row })

      }
      console.log("while setting the row", copyLineItems, row)
      return {
        ...state,
        lineItems: [...copyLineItems]
      };

    case invoiceActions.ADD_NEW_LINE_ITEM:
      const { row: newLine } = action.payload
      return {
        ...state,
        lineItems: [...(state?.lineItems || []), { ...newLine }]
      };

    default:
      return state;
  }
};

export default invoiceReducer;

import { Form, Input } from "antd";
import { Button, CircularProgress } from "@mui/material";
import React from "react";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";

interface Props {
  loginLoading: Boolean;
  email: string;
  handleEmailChange: (e: React.FormEvent<EventTarget>) => void;
  handleSendEmail: () => void;
}

const validateEmail = (rule: unknown, value: string, callback: any) => {
  if (value && !/^\S+@\S+\.\S+$/.test(value)) {
    callback("Please enter a valid email address");
  } else {
    callback();
  }
};

const LoginUI: React.FC<Props> = (props) => {
  const { email, handleEmailChange, handleSendEmail, loginLoading } = props;
  return (
    <div className="login">
      <div className="login-logo">
        <img src="./images/logo.svg" alt="" />
      </div>
      <div className="login-text">
        <div className="login-text-head">Welcome to Cashwise</div>
        <div className="login-text-message">
          Increase your business’ <br /> productivity by automating your
          document workflow
        </div>
      </div>
      <Form className="login-form" onFinish={handleSendEmail}>
        <Form.Item
          className="login-form-item"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter your email address",
            },
            { validator: validateEmail },
          ]}
          initialValue={email}
        >
          <Input
            className="login-form-input-email"
            placeholder="Email"
            onChange={handleEmailChange}
          />
        </Form.Item>
        <div className="login-form-submit">
          {loginLoading ? (
            <MiniLoaderButton />
          ) : (
            <Button
              className="login-form-submit-button"
              disabled={loginLoading ? true : false}
              variant="contained"
              color="primary"
              type="submit"
            >
              Continue
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default LoginUI;

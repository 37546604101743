import React, { useEffect, useState } from "react";
import auth from "../../../../firebase/auth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthContainer from "../../container/AuthContainer";
import RegisterUI from "./RegisterUI";
import { createUser } from "../../../../api/users/user";
import { userCreated } from "../../states/user/actions/userApiActionCreator";
import "./styles.scss";

interface User {
  firstName: string;
  lastName: string;
}

const Register: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<User>({
    firstName: "",
    lastName: "",
  });
  const handleDetails = (name: string, e: any) => {
    setUserDetails({
      ...userDetails,
      [name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const { firstName, lastName } = userDetails;
      const submitUser = await createUser({ firstName, lastName });
      if (submitUser.type === "success") {
        setSubmitLoading(false);
        dispatch(userCreated());
        navigate("/");
      } else {
        setSubmitLoading(false);
        throw new Error("Some Error Occured");
      }
    } catch (error) {
      setSubmitLoading(false);
      toast.error(
        "Error occured while creating your account. Please try again.",
        {
          toastId: "register_error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };
  return (
    <AuthContainer
      children={
        <RegisterUI
          {...{ userDetails, handleDetails, handleSubmit, submitLoading }}
        />
      }
    />
  );
};

export default Register;

import { baseAPI } from "..";

export interface Props {
  token?: string;
  id: string | null;
  type?: string;
  recordId?: string | null;
  itemId?: string | null;
  name?: string;
  data?: FormData;
  fileName?: string;
  recordIds?: string[];
  kind?: string;
  multipleUpload?: boolean;
  category?: string;
  path?: string[] | [];
}

export const formationDocs = baseAPI + "/formation-docs";

export const shareRecord = "doc-collection/create-share-link";

export const deleteRecord = "record/delete";

import React, { useEffect, useState } from "react";
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deleteCompany,
  deleteLogo,
  getIndivCompany,
  leaveCompany,
  updateCompany,
  uploadLogo,
} from "../../../api/companies/companies";
import SettingsUI from "./SettingsUI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Entity } from "../../constants";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Loader/state/action/actionCreator";

const Settings: React.FC = () => {
  const state: any = useSelector((state) => state);
  const userDetail = state.user.userDetails;
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [entity, setEntity] = useState<Entity>({
    entityName: "",
    website: "",
    logo: "",
  });
  const dispatch = useDispatch();
  const [changeLogo, setChangeLogo] = useState<boolean>(false);
  const [currentEntity, setCurrentEntity] = useState<any>();
  const [lastNameMatch, setLastNameMatch] = useState<string>("");
  const [deleteEntityInput, setDeleteEntityInput] = useState<string>("");

  const [editEntity, handleEditEntity] = useState<boolean>(false);
  const [logoState, setLogoState] = useState<string>("initial");
  const [leaveEntity, handleLeaveEntity] = useState<boolean>(false);
  const [deleteEntity, handleDeleteEntity] = useState<boolean>(false);
  const [tokenValue, setToken] = useState<string>("");
  const [entityInputs, setEntityInputs] = useState<any>();

  useEffect(() => {
    (async () => {
      dispatch(setLoader(true));
      const entityDetails = await getIndivCompany({ id });

      if (entityDetails && entityDetails.type === "success") {
        setEntity({
          entityName: entityDetails.data.name,
          website: entityDetails.data.website,
          logo: entityDetails.data.logoUrl,
        });
        setCurrentEntity(entityDetails.data);
        setEntityInputs({
          name: entityDetails.data.name,
          numberOfEmployees: entityDetails.data.numberOfEmployees,
          companyCountry: entityDetails.data.companyCountry,
          companyType: entityDetails.data.companyType,
          website: entityDetails.data.website,
          description: entityDetails.data.description,
        });
        setLoading(false);
        dispatch(setLoader(false));
      } else if (entityDetails && entityDetails.type === "error") {
        setError(entityDetails.errorType);
        dispatch(setLoader(false));
        setLoading(false);
      }
    })();
  }, []);

  const handleCoworker = () => {
    navigate({
      pathname: "/dashboard/companies/co-workers",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };

  const handleChange = (name: string, value: string) => {
    setEntityInputs({
      ...entityInputs,
      [name]: value,
    });
  };
  const handleDelete = async () => {
    const deleteComp = await deleteCompany({ id, token: tokenValue });
    if (deleteComp && deleteComp.type === "success") {
      navigate("/");
      toast.success("You have successfully deleted the company", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleLeave = async () => {
    const deleteComp = await leaveCompany({ id, token: tokenValue });
    if (deleteComp && deleteComp.type === "success") {
      navigate("/");
      toast.success("You have successfully left the company", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleEntityUpdate = async () => {
    const updateEntity = await updateCompany({
      id,
      token: tokenValue,
      companyName: entityInputs.name,
      numberOfEmployees: entityInputs.numberOfEmployees,
      website: entityInputs.website,
      description: entityInputs.description,
    });
    if (updateEntity && updateEntity.type === "success") {
      const newEntity = await getIndivCompany({ token: tokenValue, id });
      if (newEntity && newEntity.type === "success") {
        setCurrentEntity(newEntity.data);
        setEntity({
          entityName: newEntity.data.name,
          website: newEntity.data.website,
          logo: newEntity.data.logoUrl,
        });
        handleEditEntity(false);
      }
    }
  };
  const handleUploadLogo = async (imageFile: any) => {
    const formData = new FormData();
    formData.append("brand-logo", imageFile);
    const uploadImage = await uploadLogo({
      id,
      data: formData,
      token: tokenValue,
    });
    if (uploadImage && uploadImage.type === "success") {
      const entityDetails = await getIndivCompany({ token: tokenValue, id });
      if (entityDetails && entityDetails.type === "success") {
        setEntity({
          entityName: entityDetails.data.name,
          website: entityDetails.data.website,
          logo: entityDetails.data.logoUrl,
        });
        toast.success("Logo Added Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setChangeLogo(false);
        setLogoState("initial");
      }
    }
  };
  const handleRemoveLogo = async () => {
    const deleteImage = await deleteLogo({ id, token: tokenValue });

    if (deleteImage.type === "success") {
      const entityDetails = await getIndivCompany({ token: tokenValue, id });
      if (entityDetails && entityDetails.type === "success") {
        setEntity({
          entityName: entityDetails.data.name,
          website: entityDetails.data.website,
          logo: entityDetails.data.logoUrl,
        });
        toast.success("Logo Added Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setChangeLogo(false);
      }
    }
  };
  if (!id || error === "Company not found. Please retry.") {
    return <>{!loading && <Navigate to="/" replace />}</>;
  } else {
    return (
      <>
        {!loading && (
          <SettingsUI
            {...{
              id,
              tokenValue,
              entity,
              currentEntity,
              handleCoworker,
              userDetail,
              leaveEntity,
              handleLeaveEntity,
              deleteEntity,
              handleDeleteEntity,
              deleteEntityInput,
              setDeleteEntityInput,
              lastNameMatch,
              setLastNameMatch,
              editEntity,
              handleEditEntity,
              handleLeave,
              handleDelete,
              handleEntityUpdate,
              entityInputs,
              handleChange,
              changeLogo,
              setChangeLogo,
              handleUploadLogo,
              handleRemoveLogo,
              logoState,
              setLogoState,
            }}
          />
        )}
      </>
    );
  }
};

export default Settings;

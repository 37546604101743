import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Router from "./routes/Router";
import "./styles/app.scss";
import { signInWithEmailLink } from "./firebase/auth";

import auth from "./firebase/auth";
import {
  createUser,
  loadAuth,
  setErrorMessage,
} from "./components/auth/states/auth/actions/authActionCreator";
import { ToastContainer } from "react-toastify";
import { getUser } from "./api/users/user";
import {
  loadUser,
  userCreated,
} from "./components/auth/states/user/actions/userApiActionCreator";
import Loader from "./components/Loader/Loader";
import { setLoader } from "./components/Loader/state/action/actionCreator";

const App: React.FC = () => {
  const state: any = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setLoader(true));
      const signIn = await signInWithEmailLink();

      if (signIn && signIn.value === "success") {
        dispatch(userCreated());
        dispatch(createUser({ firstName: "Initial", lastName: "Initial" }));
        dispatch(setLoader(false));
      } else if (signIn && signIn.value === "error") {
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
      }
    })();

    const unsubscribe = async () =>
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userData = await getUser();
          const returnType = userData.type;
          if (returnType === "error") {
            dispatch(setErrorMessage(userData.errorType));
          } else {
            dispatch(setErrorMessage(""));
            dispatch(userCreated());
            dispatch(createUser(userData.data));
          }
          dispatch(loadAuth());
          dispatch(loadUser());
        } else {
          dispatch(loadAuth());
          dispatch(loadUser());
        }
      });
    unsubscribe();
  }, []);

  return (
    <>
      {state.auth.authLoaded && state.user.userLoaded ? <Router /> : <Loader />}
      {state.load.loading && <Loader />}
      <ToastContainer />
    </>
  );
};

export default App;
import { Button } from "@mui/material";
import { Form, Input } from "antd";
import React, { useState } from "react";
import { customTemplateGeneration } from "../../../../api/docGeneration/docgen";
import { useSearchParams } from "react-router-dom";
import "./index.scss";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";
import { toast } from "react-toastify";
const { TextArea } = Input;

const RequestCustom: React.FC = () => {
  const [requestValues, setRequestValues] = useState<any>({
    email: "",
    mobile: "",
    docType: "",
    purpose: "",
  });
  const [loadCustom, setLoadCustom] = useState<boolean>(false);
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const handleRequestCustom = async () => {
    try {
      setLoadCustom(true);

      const data = await customTemplateGeneration({
        id,
        data: requestValues,
      });
      if (data && data.type === "success") {
        toast.success("Request Sent Successfully");
        setLoadCustom(false);
      } else if (data && data.type === "error") {
        throw new Error(data.data);
      }
    } catch (error: any) {
      toast.error(error);
      setLoadCustom(false);
    }
  };
  return (
    <div className="docgen-request">
      <div className="docgen-request-heading">
        Tell us about your request, and our team of lawyers and CAs would draft
        an appropriate document for you!
      </div>
      <Form onFinish={handleRequestCustom}>
        <div className="docgen-request-fields">
          <div className="docgen-request-fields-set">
            <div className="docgen-request-fields-set-heading">
              Contact Details:
            </div>
            <div className="docgen-request-fields-set-half">
              <Form.Item
                className="add-form-item"
                key="email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: `Email is required`,
                  },
                ]}
              >
                <Input
                  className="doc-gen-form-input"
                  placeholder="Email"
                  onChange={(e: any) => {
                    setRequestValues({
                      ...requestValues,
                      email: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
            <div className="docgen-request-fields-set-half">
              <Form.Item
                className="add-form-item"
                key="mobile"
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: `Mobile is required`,
                  },
                ]}
              >
                <Input
                  className="doc-gen-form-input"
                  placeholder="Mobile"
                  onChange={(e: any) => {
                    setRequestValues({
                      ...requestValues,
                      mobile: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="docgen-request-fields-set">
            <div className="docgen-request-fields-set-heading">
              Document Type:
            </div>
            <div className="docgen-request-fields-set-half">
              <Form.Item
                className="add-form-item"
                key="docType"
                name="docType"
                rules={[
                  {
                    required: true,
                    message: `docType is required`,
                  },
                ]}
              >
                <Input
                  className="doc-gen-form-input"
                  placeholder="Ex: Vendor Agreement, Privacy Policy, etc"
                  onChange={(e: any) => {
                    setRequestValues({
                      ...requestValues,
                      docType: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="docgen-request-fields-set">
            <div className="docgen-request-fields-set-heading">
              Tell us about the purpose of document:
            </div>
            <div className="docgen-request-fields-set-full">
              <Form.Item
                className="add-form-item"
                key="purpose"
                name="purpose"
                rules={[
                  {
                    required: true,
                    message: `Purpose is required`,
                  },
                ]}
              >
                <TextArea
                  className="doc-gen-form-textarea"
                  placeholder="Explain in brief about why you need the document, we would be able to serve you better!"
                  style={{ minHeight: 120 }}
                  onChange={(e: any) => {
                    setRequestValues({
                      ...requestValues,
                      purpose: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="docgen-request-button">
          {loadCustom ? (
            <MiniLoaderButton />
          ) : (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default RequestCustom;

import { modalStatetype } from "./modalTypes";

export const modalInitialState: modalStatetype = {
  openTemplateNameModal: {
    key: false,
    templateId: "",
    templateName: "",
    isEdit: false
  } ,
};

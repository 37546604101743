import { authActions } from "../actions/authActions";
import { authInitialState } from "./authInitial";
import { authActionType } from "../type";

const authReducer = (state = authInitialState, action: authActionType) => {
  switch (action.type) {
    case authActions.LOAD_AUTH:
      return { ...state, authLoaded: true };

    case authActions.CREATE_USER:
      return { ...state, user: action.payload };
    case authActions.SIGN_OUT:
      return { ...state, user: null, errorMessage: "" };
    case authActions.SET_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

export default authReducer;

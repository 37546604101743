import { userApiStateType } from "../type";
import { userApiActions } from "./userApiActions";

export const loadUser = () => {
  return {
    type: userApiActions.LOAD_USER,
  };
};

export const createUser = (user: userApiStateType) => {
  return {
    type: userApiActions.CREATE_USER,
    payload: user,
  };
};

export const updateUser = (user: userApiStateType) => {
  return {
    type: userApiActions.UPDATE_USER,
    payload: user,
  };
};

export const userCreated = () => {
  return {
    type: userApiActions.USER_CREATED,
  };
};

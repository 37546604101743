import React, { ReactElement } from "react";
import "./styles.scss";
interface Props {
  children: ReactElement;
}

const projectBack = require("../images/project-back.png");

const ProjectContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="project-container">
      <div className="project-container-top">
        <img src={projectBack} alt="Project" />
      </div>
      <div className="project-container-body">{children}</div>
    </div>
  );
};

export default ProjectContainer;

// @flow
import * as React from "react";
import { TabsIn } from "../../../UILibrary/Tabs/Tabs";
import { Tab } from "../../../Types/global.types";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
type Props = {
  tabs: Tab[];
};
export const InvoiceSummaryUI = ({ tabs }: Props) => {
  return (
    <>
      <AppContentCard>
        <TabsIn tabs={tabs} />
      </AppContentCard>
    </>
  );
};

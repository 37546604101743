import React, { useState } from "react";
import { Dialog, Button, IconButton, MenuItem, Tooltip } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

import { TransitionProps } from "@mui/material/transitions";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { List } from ".";
import { Select } from "antd";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";
const deleteIcon = require("../../images/delete.png");
const resendIcon = require("../../images/resend.png");
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  handleDelete: (email: string) => void;
  handleResend: (email: string, role: string) => void;
  owner: string;
  coworkerList: List[];
  logOutOpen: boolean;
  setLogOutOpen: any;
  loadingDelete: boolean;
}
interface tableProps {
  item: any;
  handleDelete: (email: string) => void;
  handleResend: (email: string, role: string) => void;
  logOutOpen: boolean;
  loadingDelete: boolean;
  setLogOutOpen: any;
}
const CoworkerDetailUI: React.FC<Props> = (props) => {
  const {
    owner,
    coworkerList,
    handleDelete,
    handleResend,
    logOutOpen,
    setLogOutOpen,
    loadingDelete,
  } = props;

  return (
    <>
      {coworkerList.length === 0 ? (
        <></>
      ) : (
        <div className="details_main">
          <div className="list_workers">
            <table>
              <tr>
                <th>Co-worker Email</th>
                <th>Roles</th>
                <th></th>
                <th></th>
              </tr>
              <br />
              <tr>
                <td className="row_email owner">
                  <a href={`mailto:${owner}`}>{owner}</a>
                </td>
                <td className="row_role owner">Owner</td>
                <td></td>
                <td></td>
              </tr>
              <br />

              {coworkerList && (
                <>
                  {coworkerList
                    ?.filter((it) => it.role !== "OWNER")
                    .map((item) => (
                      <>
                        <TableRow
                          {...{
                            handleDelete,
                            handleResend,
                            item,
                            logOutOpen,
                            setLogOutOpen,
                            loadingDelete,
                          }}
                        />
                        <br />
                      </>
                    ))}
                </>
              )}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

const TableRow = (tableprops: tableProps) => {
  const {
    item,
    handleDelete,
    handleResend,
    logOutOpen,
    setLogOutOpen,
    loadingDelete,
  } = tableprops;

  const [selectVal, setSelectVal] = useState<string>(item.role);

  return (
    <tr>
      <td className="row_email">
        <a href={`mailto:${item.email}`}>{item.email}</a>
      </td>
      <td className="row_role">
        <Select
          placement="bottomRight"
          size="large"
          rootClassName="add-form-select"
          dropdownStyle={{ minWidth: "140px" }}
          popupClassName="select-popup"
          placeholder="Select Role"
          style={{ width: "140px" }}
          onChange={(value) => setSelectVal(value)}
          options={[
            {
              label: "Admin",
              value: "ADMIN",
            },
          ]}
          defaultValue={"ADMIN"}
        ></Select>
      </td>
      <td className="row_delete">
        <Tooltip title="Delete this role" placement="bottom-start">
          <IconButton color="error" onClick={() => setLogOutOpen(true)}>
            <img src={deleteIcon} alt="" />
          </IconButton>
        </Tooltip>
      </td>

      {item.inviteStatus !== "ACCEPTED" ? (
        <td
          className="row_delete row_update"
          style={{ display: "inline-block", marginTop: "8px" }}
        >
          <Tooltip title="Resend Invite" placement="bottom-start">
            <IconButton onClick={() => handleResend(item.email, selectVal)}>
              <img src={resendIcon} alt="" />
            </IconButton>
          </Tooltip>
        </td>
      ) : (
        <td></td>
      )}
      <Dialog TransitionComponent={Transition} open={logOutOpen}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setLogOutOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Delete</div>
          <div className="dialog-content">
            Are you sure you want to delete co-worker?
          </div>
          <div className="dialog-buttons">
            {loadingDelete ? (
              <MiniLoaderButton />
            ) : (
              <Button
                className="dialog-buttons-contained"
                variant="contained"
                onClick={() => {
                  handleDelete(item.email);
                }}
              >
                Yes
              </Button>
            )}

            <Button
              className="dialog-buttons-outlined"
              variant="outlined"
              onClick={() => {
                setLogOutOpen(false);
              }}
            >
              No
            </Button>
          </div>
        </div>
      </Dialog>
    </tr>
  );
};

export default CoworkerDetailUI;

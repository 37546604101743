import React, { useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ProjectContainer from "../../container/container";
import CompanyCreatedUI from "./CompanyCreationSuccessUI";

const CompanyCreated: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    document.title = "Entity Created";
  }, []);
  const id = searchParams.get("id");
  const continueClick = () => {
    navigate({
      pathname: "/",
      search: createSearchParams({}).toString(),
    });
  };
  return (
    <ProjectContainer children={<CompanyCreatedUI {...{ continueClick }} />} />
  );
};

export default CompanyCreated;

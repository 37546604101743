import { invoiceActions } from "./invoiceActions";

export const handleInvoiceKeyValueChange = (
  event?:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>,
  key?: string,
  value?: string | number | string[] | boolean
) => {
  return {
    type: invoiceActions.HANDLE_INVOICE_KEY_VALUE_CHANGE,
    payload: {
      [key ?? (event?.target?.name || "invalid")]:
        value ?? event?.target?.value,
    },
  };
};

export const setInvoiceDetails = (data: any) => {
  return {
    type: invoiceActions.SET_INVOICE_DETAILS,
    payload: data,
  };
};

export const setInvoiceItemRowAtIndex = (data: any) => {
  return {
    type: invoiceActions.SET_INVOICE_ITEM_AT_INDEX,
    payload: data,
  };
};

export const addNewLineItem = (data: any) => {
  return {
    type: invoiceActions.ADD_NEW_LINE_ITEM,
    payload: data,
  };
};

export const offerLetter = {
  name: "Offer Letter",
  docCodeType: "single",
  path: "employee",
  type: "Human Resources",
  tempCode: "offer-letter",
  disclaimer:
    "An offer letter is a letter to a potential employee that provides key terms of the prospective employment.",
  docCodeValues: [
    {
      docCode: "offer-letter",
    },
  ],
  page: 1,
  pageHeading: "Enter below details to generate a prefilled Offer Letter!",
  fields: [
    {
      page: 1,
      name: "Date on which the employer wishes to send this offer letter:",
      field: [
        {
          key: "offer_letter_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the employer:",
      field: [
        {
          key: "company_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "company_address",
          type: "input",
          width: "half",
          placeholder: "Company Address",
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the employee:",
      field: [
        {
          key: "employee_name",
          type: "input",
          width: "half",
          placeholder: "Employee's Name",
        },
        {
          key: "employee_address",
          type: "input",
          width: "half",
          placeholder: "Employee's Address",
        },
      ],
    },
    {
      page: 1,
      name: "Type of employment:",
      field: [
        {
          key: "offer_type",
          type: "radio",
          width: "initial",
          options: [
            {
              value: "Full-time",
              label: "Full-time",
            },
            {
              value: "Part-time",
              label: "Part-time",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Joining date for the employee:",
      field: [
        {
          key: "joining_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 2,
      name: "Employee's job title:",
      field: [
        {
          key: "job_title",
          type: "input",
          width: "half",
          placeholder: "Job Title. Ex: Sales Manager, Software Developer, etc",
        },
      ],
    },
    {
      page: 2,
      name: "New employee's probabtionary period:",
      field: [
        {
          key: "probation_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 3 months, 8 weeks, etc",
        },
      ],
    },

    {
      page: 2,
      name: "Employee's annual salary (CTC):",
      field: [
        {
          key: "currency",
          type: "select",
          width: "half",
          placeholder: "Currency",
          options: [
            {
              value: "INR",
              label: "INR",
            },
            {
              value: "USD",
              label: "USD",
            },
          ],
        },
        {
          key: "employee_annual_salary",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
      ],
    },

    {
      page: 2,
      name: "Joining bonus to be given to the employee:",
      field: [
        {
          key: "joining_bonus",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
      ],
    },
    {
      page: 2,
      name: "After how many days/weeks/months of the joining date will the joining bonus be paid?",
      field: [
        {
          key: "joining_bonus_time",
          type: "input",
          width: "half",
          placeholder: "Ex: 3 months, 8 weeks, etc",
        },
      ],
    },
    {
      page: 3,
      name: "Employee's job duties:",
      field: [
        {
          key: "employee_job_duties",
          type: "textarea",
          width: "full",
          placeholder: `1. First responsibility
2. Second responsibility
and so on...`,
        },
      ],
    },
    {
      page: 3,
      name: "Office address from where the employee would be working:",
      field: [
        {
          key: "office_address",
          type: "input",
          width: "half",
          placeholder: "Office Address",
        },
      ],
    },
    {
      page: 3,
      name: "Hours per week that the employee will be required to work:",
      field: [
        {
          key: "hours_per_week",
          type: "input",
          width: "half",
          placeholder: "Hours per week (number). Ex: 36, 40, 45, etc.",
        },
      ],
    },

    {
      page: 3,
      name: "Notice period to be given:",
      field: [
        {
          key: "notice_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 2 weeks, 1 month, 2 months",
        },
      ],
    },

    {
      page: 3,
      name: "Name of the person signing the agreement on behalf of the company:",
      field: [
        {
          key: "company_rep_naemployer_repme",
          type: "input",
          width: "half",
          placeholder: "Designation",
        },
      ],
    },
  ],
};

import { Input } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";

const HeadingFormation: React.FC = () => {
  return (
    <div className="formation-docs-heading">
      <div className="formation-docs-heading-title">Corporate Documents</div>
    </div>
  );
};

export default HeadingFormation;

import React, { useEffect, useState } from "react";
import {
  addFund,
  checkRecordType,
  fileDownload,
  fileRename,
  fileUpload,
  formationDocs,
} from "./formationApiType";
import { Button } from "@mui/material";
import { RecordListA, RecordListB } from "./RecordsList";
import { Drawer, Input } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { CloseOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import lunr from "lunr";
interface Document {
  name: string;
}
interface Props {
  formationDocsData: formationDocs[] | null;
  handleUploadRecordItem: ({
    imageFile,
    recordId,
  }: fileUpload) => Promise<boolean | undefined>;
  loadingUpload: boolean;
  handleRename: ({
    itemId,
    recordId,
    fileName,
  }: fileRename) => Promise<boolean | undefined>;
  handleAddFunding: ({ fundingName }: addFund) => any;
  handleDelete: ({
    itemId,
    recordId,
  }: fileDownload) => Promise<boolean | undefined>;
  handleAddRowItem: (
    name: string,
    type: string,
    category: string,
    path: string[]
  ) => Promise<boolean | undefined>;

  handleDownload: ({ itemId, recordId }: fileDownload) => void;

  checkRecords: checkRecordType[];
  handleChecks: (it: any) => void;
  showEmptyCheck: boolean;
  shareLoading: boolean;
  deleteLoading: boolean;
  addLoading: boolean;
  handleEmptyView: () => void;
  handleDeleteMultiple: () => Promise<boolean | undefined>;
  handleShareMultiple: () => Promise<boolean | undefined>;
  handleRemoveCheck: (id: string) => void;
}

const FormationDiligenceUI: React.FC<Props> = (props) => {
  const {
    formationDocsData,
    handleUploadRecordItem,
    loadingUpload,
    handleRename,
    handleDownload,
    handleDelete,
    handleAddFunding,
    checkRecords,
    handleChecks,
    showEmptyCheck,
    handleEmptyView,
    handleDeleteMultiple,
    handleShareMultiple,
    handleRemoveCheck,
    shareLoading,
    deleteLoading,
    handleAddRowItem,
    addLoading,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = Object.fromEntries(searchParams);

  const tabValue = queryParams.page
    ? queryParams.page
    : formationDocsData && formationDocsData[0].name;

  const [tab, setTab] = useState<any>(tabValue);
  const funds: any = formationDocsData?.filter(
    (item) => item.name === "Funding Documents"
  );

  const fundingStart =
    funds.length > 0
      ? funds[0].fundingRounds &&
        funds[0].fundingRounds.length > 0 &&
        funds[0].fundingRounds[0].name
      : "";

  // const fundingStarts = if(funds)
  const [selectFund, setSelectFund] = useState<any>(fundingStart);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [addOpen, setAddOpen] = useState<boolean>(false);

  const [addRowItem, setAddRowItem] = useState<any>({
    name: "",
    type: "single-file",
  });

  const documents: any = formationDocsData?.flatMap((item: any) =>
    item.name === "Funding Documents"
      ? item.fundingRounds.length > 0 &&
        item.fundingRounds[0].records.map((rec: any) => ({
          title: item.name,
          content: rec.kind,
        }))
      : item.records.map((rec: any) => ({
          title: item.name,
          content: rec.kind,
        }))
  );

  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);
  const [filteredResults, setFilteredResults] = useState<any>([]);
  const [showResults, setShowResults] = useState(false);
  const [searchIndex, setSearchIndex] = useState<lunr.Index>();

  useEffect(() => {
    const index = lunr(function (this: lunr.Index) {
      this.ref("id");
      this.field("title");
      this.field("content");

      documents.forEach((document, index) => {
        this.add({ id: index.toString(), ...document });
      });
    });

    setSearchIndex(index);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuery(value);
    if (value.trim() !== "" && searchIndex) {
      const searchResults: lunr.Index.Result[] = searchIndex.search(value);
      const mappedResults: any = searchResults.map((result) => {
        const document = documents[parseInt(result.ref)];
        return { ...document };
      });
      setResults(mappedResults);
      setFilteredResults(
        mappedResults.filter((result: any) => result.score > 0.5)
      );
      setShowResults(true);
    } else {
      setResults([]);
      setFilteredResults([]);
      setShowResults(false);
    }
  };

  return (
    // <div style={{ position: "relative" }}>
    <>
      <div className="formation-docs-heading-search">
        <Input
          className="formation-docs-heading-search-input"
          placeholder="Search"
          type="text"
          value={query}
          onChange={handleInputChange}
        />
        {showResults && (
          <>
            {results.length > 0 ? (
              <div className="search-results-box">
                <ul>
                  {results.map((result: any) => (
                    <li>
                      <div
                        onClick={() => {
                          setTab(result.title);
                          setQuery("");
                          setResults([]);
                          setShowResults(false);
                        }}
                      >
                        <span>{result.content}</span>&nbsp; are in &nbsp;
                        <span>{result.title}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="search-results-box">
                <ul>
                  <li style={{ cursor: "text" }}>
                    <span>No Matches Found!</span>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}

        <div className="formation-docs-heading-search-icon">
          <SearchOutlined />
        </div>
      </div>
      <div className="formation-docs">
        <div className="formation-docs-details">
          <div className="formation-docs-details-tabs">
            {formationDocsData?.map((item: any) => (
              <Button
                className={`${
                  tab === item.name && "formation-docs-details-tabs-active"
                } `}
                onClick={() => {
                  setTab(item.name);
                  setSearchParams((prevSearchParams) => {
                    const updatedParams = new URLSearchParams(prevSearchParams);
                    updatedParams.set("page", item.name);
                    return updatedParams;
                  });
                }}
              >
                {item.name}
              </Button>
            ))}
          </div>
          <div className="formation-docs-details-data">
            {tab === "Funding Documents" ? (
              <>
                <RecordListB
                  {...{
                    formationDocsData,
                    tab,
                    handleUploadRecordItem,
                    loadingUpload,
                    handleRename,
                    handleDownload,
                    handleDelete,
                    handleAddFunding,
                    checkRecords,
                    handleChecks,
                    showEmptyCheck,
                    handleEmptyView,
                    selectFund,
                    setSelectFund,
                  }}
                />
              </>
            ) : (
              <>
                <RecordListA
                  {...{
                    formationDocsData,
                    tab,
                    handleUploadRecordItem,
                    loadingUpload,
                    handleRename,
                    handleDownload,
                    handleDelete,
                    handleAddFunding,
                    checkRecords,
                    handleChecks,
                    showEmptyCheck,
                    handleEmptyView,
                    selectFund,
                    setSelectFund,
                  }}
                />
              </>
            )}
          </div>
        </div>{" "}
        <div className="formation-docs-footer">
          <div className="formation-docs-footer-check">
            <input
              type="checkbox"
              checked={showEmptyCheck}
              onChange={handleEmptyView}
            />
            Do not show empty document rows
          </div>
          <div className="formation-docs-footer-buttons">
            <div className="formation-docs-footer-buttons">
              <Button
                variant="outlined"
                className="formation-docs-footer-buttons-add"
                onClick={() => setAddOpen(true)}
              >
                Add
              </Button>
            </div>
            <div className="formation-docs-footer-buttons">
              <Button
                variant="outlined"
                className="formation-docs-footer-buttons-delete"
                disabled={checkRecords.length === 0 ? true : false}
                onClick={() => setDeleteOpen(true)}
              >
                Delete
              </Button>
            </div>
            <div className="formation-docs-details-footer-buttons">
              <Button
                variant="contained"
                className="formation-docs-details-footer-buttons-share"
                disabled={checkRecords.length === 0 ? true : false}
                onClick={() => setShareOpen(true)}
              >
                Share
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------Drawers------------- */}
      <Drawer
        closable={false}
        title=""
        placement="right"
        mask={false}
        open={deleteOpen}
      >
        <div className="wrapper-body">
          <div className="wrapper-body-head">
            <div className="wrapper-body-head-name">Delete</div>
            <div
              className="wrapper-body-head-icon"
              onClick={() => setDeleteOpen(false)}
            >
              <CloseOutlined style={{ color: "#767676" }} />
            </div>
          </div>
          <div className="wrapper-body-content">
            <div className="wrapper-body-content-head">
              Delete Selected Files
            </div>
            <div className="wrapper-body-content-body">
              All the selected rows along with the files would be deleted.
            </div>
            <div className="wrapper-body-content-button">
              {deleteLoading ? (
                <div className="wrapper-body-content-button-loading">
                  <CircularProgress sx={{ color: "white" }} size={24} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={async () => {
                    const val = await handleDeleteMultiple();
                    if (val) {
                      setDeleteOpen(false);
                    }
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
            <div className="wrapper-body-content-list">
              {checkRecords?.map((item, ind) => (
                <div className="wrapper-body-content-list-item">
                  <span onClick={() => handleRemoveCheck(item.id)}>
                    <CloseSquareOutlined
                      style={{ color: "#767676", fontSize: "24px" }}
                    />
                  </span>
                  {ind + 1}. {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        closable={false}
        title=""
        placement="right"
        mask={false}
        open={shareOpen}
      >
        <div className="wrapper-body">
          <div className="wrapper-body-head">
            <div className="wrapper-body-head-name">Share</div>
            <div
              className="wrapper-body-head-icon"
              onClick={() => setShareOpen(false)}
            >
              <CloseOutlined style={{ color: "#767676" }} />
            </div>
          </div>
          <div className="wrapper-body-content">
            <div className="wrapper-body-content-head">
              List of Selected Files
            </div>
            <div className="wrapper-body-content-body">
              A zip of the selected files can be shared using the below link:
            </div>
            <div className="wrapper-body-content-button">
              {shareLoading ? (
                <div className="wrapper-body-content-button-loading">
                  <CircularProgress sx={{ color: "white" }} size={24} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={async () => {
                    const val = await handleShareMultiple();
                    if (val) {
                      setShareOpen(false);
                    }
                  }}
                >
                  copy link
                </Button>
              )}
            </div>
            <div className="wrapper-body-content-list">
              {checkRecords?.map((item, ind) => (
                <div className="wrapper-body-content-list-item">
                  <span onClick={() => handleRemoveCheck(item.id)}>
                    <CloseSquareOutlined
                      style={{ color: "#767676", fontSize: "24px" }}
                    />
                  </span>
                  {ind + 1}. {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        closable={false}
        title=""
        placement="right"
        mask={false}
        open={addOpen}
      >
        <div className="wrapper-body">
          <div className="wrapper-body-head">
            <div className="wrapper-body-head-name">Add Document Row</div>
            <div
              className="wrapper-body-head-icon"
              onClick={() => setAddOpen(false)}
            >
              <CloseOutlined style={{ color: "#767676" }} />
            </div>
          </div>
          <div className="wrapper-body-content">
            <div className="wrapper-body-content-head">
              Enter the name of the row you would like to add here:
            </div>
            <div className="wrapper-body-content-body">
              <Input
                value={addRowItem.name}
                onChange={(e: any) =>
                  setAddRowItem({ ...addRowItem, name: e.target.value })
                }
                className="wrapper-body-content-body-input"
                placeholder="Name of Document"
              />
            </div>
            <div className="wrapper-body-content-head">
              Select if there would be single file or multiple files
            </div>
            <div className="wrapper-body-content-radios">
              <label>
                <input
                  type="radio"
                  name="add-doc"
                  value="single-file"
                  checked={addRowItem.type === "single-file"}
                  onChange={(e: any) =>
                    setAddRowItem({ ...addRowItem, type: e.target.value })
                  }
                />
                Single File
              </label>
              <label>
                <input
                  type="radio"
                  name="add-doc"
                  value="multiple-file"
                  checked={addRowItem.type === "multiple-file"}
                  onChange={(e: any) =>
                    setAddRowItem({ ...addRowItem, type: e.target.value })
                  }
                />
                Multiple Files
              </label>
            </div>
            <div className="wrapper-body-content-button">
              {addLoading ? (
                <div className="wrapper-body-content-button-loading">
                  <CircularProgress sx={{ color: "white" }} size={24} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  disabled={addRowItem.name === "" ? true : false}
                  onClick={async () => {
                    const val = await handleAddRowItem(
                      addRowItem.name,
                      addRowItem.type,
                      tab,
                      [`${selectFund}`]
                    );
                    if (val) {
                      setAddOpen(false);
                    }
                  }}
                >
                  Add
                </Button>
              )}
            </div>
            <div className="wrapper-body-content-body">
              A new document row would be added at the end of the list in the
              opened category.
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default FormationDiligenceUI;

export const cofounderAgreement = {
  name: "Co-founders' Agreement",
  type: "Start Up",
  path: "cofounder",
  tempCode: "cofounders-agreement",
  disclaimer:
    "A founders agreement is a legal contract that all co-founders agree to — ideally set by the company prior to launching.",
  docCodeType: "multiple",
  docCodeValues: [
    {
      docCode: "two-cofounders-agreement",
    },
    {
      docCode: "three-cofounders-agreement",
    },
    {
      docCode: "four-cofounders-agreement",
    },
  ],
  page: 3,
  pageHeading:
    "Enter below details to generate a prefilled Co-founders' Agreement!",
  fields: [
    {
      page: 1,
      name: "Date on which the agreement will be entered into:",
      field: [
        {
          key: "effective_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "Name and registered address of the company:",
      field: [
        {
          key: "Company_Name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "company_address",
          type: "input",
          width: "full",
          placeholder: "Registered Address",
        },
      ],
    },
    {
      page: 1,
      name: "The company is engaged in the business of:",
      field: [
        {
          key: "business_domain",
          type: "input",
          width: "full",
          placeholder:
            "Business Domain. Ex: manufacturing of television parts, invoicing tool, consumer lending, etc.",
        },
      ],
    },
    {
      page: 1,
      name: "Number of co-founders:",
      field: [
        {
          key: "conditon_cofounder",
          type: "select",
          width: "half",
          placeholder: "Number of co-founders:",
          options: [
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
          ],
        },
      ],
    },
    {
      page: 2,
      name: "Founder 1 details:",
      cofounderCondition: 2,
      field: [
        {
          key: "first_founder_name",
          type: "input-split",
          width: "full",
          split: [
            {
              key: "first_founder_first_name",
              type: "input",
              width: "half",
              placeholder: "First and Middle Name",
            },
            {
              key: "first_founder_last_name",
              type: "input",
              width: "half",
              placeholder: "Last Name",
            },
          ],
        },
        {
          key: "first_founder_percent",
          type: "input",
          width: "half",
          placeholder: "Shareholding in Percentage",
        },
        {
          key: "first_founder_ckra",
          type: "input",
          width: "half",
          placeholder: "Chief KRA (Key responsibility Area)",
        },
        {
          key: "first_founder_dkra",
          type: "input",
          width: "full",
          placeholder: "Detailed KRA (Key responsibility Area)",
        },
      ],
    },
    {
      page: 2,
      cofounderCondition: 2,
      name: "Founder 2 details:",
      field: [
        {
          key: "second_founder_name",
          type: "input-split",
          width: "full",
          split: [
            {
              key: "second_founder_first_name",
              type: "input",
              width: "half",
              placeholder: "First and Middle Name",
            },
            {
              key: "second_founder_last_name",
              type: "input",
              width: "half",
              placeholder: "Last Name",
            },
          ],
        },
        {
          key: "second_founder_percent",
          type: "input",
          width: "half",
          placeholder: "Shareholding in Percentage",
        },
        {
          key: "second_founder_ckra",
          type: "input",
          width: "half",
          placeholder: "Chief KRA (Key responsibility Area)",
        },
        {
          key: "second_founder_dkra",
          type: "input",
          width: "full",
          placeholder: "Detailed KRA (Key responsibility Area)",
        },
      ],
    },
    {
      page: 2,
      cofounderCondition: 3,
      name: "Founder 3 details:",
      field: [
        {
          key: "third_founder_name",
          type: "input-split",
          width: "full",
          split: [
            {
              key: "third_founder_first_name",
              type: "input",
              width: "half",
              placeholder: "First and Middle Name",
            },
            {
              key: "third_founder_last_name",
              type: "input",
              width: "half",
              placeholder: "Last Name",
            },
          ],
        },
        {
          key: "third_founder_percent",
          type: "input",
          width: "half",
          placeholder: "Shareholding in Percentage",
        },
        {
          key: "third_founder_ckra",
          type: "input",
          width: "half",
          placeholder: "Chief KRA (Key responsibility Area)",
        },
        {
          key: "third_founder_dkra",
          type: "input",
          width: "full",
          placeholder: "Detailed KRA (Key responsibility Area)",
        },
      ],
    },
    {
      page: 2,
      cofounderCondition: 4,
      name: "Founder 4 details:",
      field: [
        {
          key: "fourth_founder_name",
          type: "input-split",
          width: "full",
          split: [
            {
              key: "fourth_founder_first_name",
              type: "input",
              width: "half",
              placeholder: "First and Middle Name",
            },
            {
              key: "fourth_founder_last_name",
              type: "input",
              width: "half",
              placeholder: "Last Name",
            },
          ],
        },
        {
          key: "fourth_founder_percent",
          type: "input",
          width: "half",
          placeholder: "Shareholding in Percentage",
        },
        {
          key: "fourth_founder_ckra",
          type: "input",
          width: "half",
          placeholder: "Chief KRA (Key responsibility Area)",
        },
        {
          key: "fourth_founder_dkra",
          type: "input",
          width: "full",
          placeholder: "Detailed KRA (Key responsibility Area)",
        },
      ],
    },
    {
      page: 3,
      name: "Lock in period or vesting period for founders in years:",
      field: [
        {
          key: "lock_in_years",
          type: "select",
          width: "full",
          placeholder:
            "Lock in period or vesting period for founders in years:",
          options: [
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
          ],
        },
      ],
    },
    {
      page: 3,
      name: "Date of first vesting (or date for ending cliff period):",
      field: [
        {
          key: "first_vesting_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
  ],
};

import { TaxConfigObject } from "../../Types/invoice.types";

export const taxes: TaxConfigObject[] = [
  {
    label: "IGST",
    id: "IGST",
    taxRateColumn: { label: "GST Rate", variable: "gstRate" },
    taxAmountColumns: [{ label: "IGST", variable: "igst" }],
  },
  {
    label: "CGST & SGST",
    id: "CGST & SGST",
    taxRateColumn: { label: "GST Rate", variable: "gstRate" },
    taxAmountColumns: [
      { label: "CGST", variable: "cgst" },
      { label: "SGST", variable: "sgst" },
    ],
  },
  {
    label: "VAT",
    id: "VAT",
    taxRateColumn: { label: "VAT Rate", variable: "vatRate" },
    taxAmountColumns: [{ label: "VAT", variable: "vat" }],
  },
  {
    label: "PPN",
    id: "PPN",
    taxRateColumn: { label: "PPN Rate", variable: "ppnRate" },
    taxAmountColumns: [{ label: "PPN", variable: "ppn" }],
  },
  {
    label: "SST",
    id: "SST",
    taxRateColumn: { label: "SST Rate", variable: "sstRate" },
    taxAmountColumns: [{ label: "SST", variable: "sst" }],
  },
  {
    label: "HST",
    id: "HST",
    taxRateColumn: { label: "HST Rate", variable: "hstRate" },
    taxAmountColumns: [{ label: "HST", variable: "hst" }],
  },
];

export const nda = {
  name: "Non-Disclosure Agreement",
  docCodeType: "single",
  path: "nda",
  type: "Business Operations",
  tempCode: "nda",
  disclaimer:
    "A non-disclosure agreement (NDA) is a legally binding contract that establishes a confidential relationship.",
  docCodeValues: [
    {
      docCode: "nda",
    },
  ],
  page: 1,
  pageHeading: "Enter below details to generate a prefilled NDA document!",
  fields: [
    {
      page: 1,
      name: "From:",
      field: [
        {
          key: "from_company_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "from_brand_name",
          type: "input",
          width: "half",
          placeholder: "Brand Name",
        },
        {
          key: "from_complete_address",
          type: "input",
          width: "full",
          placeholder: "Complete Address",
        },
        {
          key: "from_representatives_name",
          type: "input",
          width: "half",
          placeholder: "Representative's Name",
        },
        {
          key: "from_representatives_title",
          type: "input",
          width: "half",
          placeholder: "Representative's Title/Designation",
        },
      ],
    },
    {
      page: 1,
      name: "To:",
      field: [
        {
          key: "to_company_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "to_brand_name",
          type: "input",
          width: "half",
          placeholder: "Brand Name",
        },
        {
          key: "to_complete_address",
          type: "input",
          width: "full",
          placeholder: "Complete Address",
        },
        {
          key: "to_representatives_name",
          type: "input",
          width: "half",
          placeholder: "Representative's Name",
        },
        {
          key: "to_representatives_title",
          type: "input",
          width: "half",
          placeholder: "Representative's Title/Designation",
        },
      ],
    },
    {
      page: 1,
      name: "Date of Execution:",
      field: [
        {
          key: "date_of_execution",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
  ],
};

// @flow
import * as React from "react";
import { TextButton } from "../../../../../UILibrary/Buttons/TextButton/TextButton";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootReducer } from "../../../../../redux/rootReducer";
import { AdditionalCharges, Invoice } from "../../../../../Types/invoice.types";
import { setInvoiceDetails } from "../../../../../redux/Invoice/invoiceActionCreator";

type Props = {
  currencySymbol: string;
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
};

export const ExtraChargesOnTotal = ({
  currencySymbol,
  handleInvoiceChangeOnBlur,
}: Props) => {
  const dispatch = useDispatch();
  const additionalCharges =
    useSelector((state: RootReducer) => state.invoice?.additionalCharges) || [];

  const handleAdditionalChargesChange = (
    key: string,
    value: number | string,
    index: number
  ) => {
    const additionalChargesCopy: AdditionalCharges[] | void = [
      ...(additionalCharges || []),
    ];
    additionalChargesCopy[index] = {
      ...(additionalChargesCopy[index] || {}),
      [key]: value,
    };
    dispatch(setInvoiceDetails({ additionalCharges: additionalChargesCopy }));
    handleInvoiceChangeOnBlur({ additionalCharges: additionalChargesCopy });
  };

  const handleRemoveAdditionalCharge = (index: number) => {
    const updatedCharges = additionalCharges.filter(
      (each, eachIndex) => eachIndex !== index
    );
    dispatch(
      setInvoiceDetails({
        additionalCharges: updatedCharges,
      })
    );
    handleInvoiceChangeOnBlur({ additionalCharges: updatedCharges });
  };

  const handleAddAdditionalCharge = (index: number) => {
    dispatch(
      setInvoiceDetails({
        additionalCharges: [
          ...additionalCharges,
          {
            name: "Extra Charge",
            value: 0,
            type: "Absolute",
          },
        ],
      })
    );
  };

  return (
    <>
      <div style={{ marginTop: additionalCharges?.length ? "1rem" : "" }}>
        {additionalCharges?.map(
          (extraCharge: AdditionalCharges, index: number) => (
            <div className="extra_charges_wrapper" key={index}>
              <input
                type="text"
                name="name"
                id="extraCharges"
                style={{ marginLeft: 0, width: "130px" }}
                placeholder="Extra Charge"
                value={extraCharge?.name}
                onChange={e =>
                  handleAdditionalChargesChange("name", e.target.value, index)
                }
              />
              <div>
                <input
                  type="number"
                  min={0}
                  name="value"
                  id="extraCharges"
                  value={extraCharge.value}
                  onChange={e =>
                    handleAdditionalChargesChange(
                      "value",
                      parseFloat(e.target.value),
                      index
                    )
                  }
                />

                <select
                  className="input"
                  name="type"
                  id="extraCharges"
                  value={extraCharge.type}
                  onChange={e =>
                    handleAdditionalChargesChange("type", e.target.value, index)
                  }
                >
                  <option key={currencySymbol} value={"Absolute"}>
                    {currencySymbol}
                  </option>

                  <option key={"%"} value={"Percentage"}>
                    %
                  </option>
                </select>
                <TextButton
                  icon={<CloseOutlined />}
                  active
                  onClick={() => handleRemoveAdditionalCharge(index)}
                />
              </div>
            </div>
          )
        )}
      </div>

      <TextButton
        icon={<ReceiptIcon />}
        active
        onClick={handleAddAdditionalCharge}
      >
        Add Additional Charge
      </TextButton>
    </>
  );
};

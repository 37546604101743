// @flow
import * as React from "react";
import { TextButton } from "../../../../../UILibrary/Buttons/TextButton/TextButton";
import { CloseOutlined } from "@ant-design/icons";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../../../redux/rootReducer";
import { setInvoiceDetails } from "../../../../../redux/Invoice/invoiceActionCreator";
import { Invoice, Reduction } from "../../../../../Types/invoice.types";

type Props = {
  currencySymbol: string;
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
};
export const DiscountsOnTotal = ({
  currencySymbol,
  handleInvoiceChangeOnBlur,
}: Props) => {
  const dispatch = useDispatch();
  const reductions =
    useSelector((state: RootReducer) => state.invoice?.reductions) || [];
  const handleRemoveReduction = (index: number) => {
    const updatedCharges = reductions.filter(
      (each, eachIndex) => eachIndex !== index
    );
    dispatch(
      setInvoiceDetails({
        reductions: updatedCharges,
      })
    );

    handleInvoiceChangeOnBlur({ reductions: updatedCharges });
  };

  const handleReductionsChange = (
    key: string,
    value: number | string,
    index: number
  ) => {
    const reductionsCopy: Reduction[] | void = [...(reductions || [])];
    reductionsCopy[index] = {
      ...(reductionsCopy[index] || {}),
      [key]: value,
    };
    dispatch(setInvoiceDetails({ reductions: reductionsCopy }));
    handleInvoiceChangeOnBlur({ reductions: reductionsCopy });

  };

  const handleAddReductions = () => {
    const updatedCharges = [
      ...reductions,
      {
        name: "Discont On Total",
        value: 0,
        type: "Absolute",
      },
    ];
    dispatch(
      setInvoiceDetails({
        reductions: updatedCharges,
      })
    );
  };
  return (
    <>
      <div style={{ marginTop: reductions?.length ? "1rem" : "" }}>
        {reductions?.map((discount: Reduction, index: number) => (
          <div className="discount_on_total_wrapper" key={index}>
            <input
              type="text"
              name="name"
              id="discountOnTotal"
              placeholder="Discount"
              value={discount?.name}
              style={{ marginLeft: 0, width: "130px" }}
              onChange={e =>
                handleReductionsChange("name", e.target.value, index)
              }
            />
            <div>
              <input
                type="number"
                name="value"
                id="discountOnTotal"
                min={0}
                value={discount?.value}
                onChange={e =>
                  handleReductionsChange(
                    "value",
                    parseFloat(e.target.value),
                    index
                  )
                }
              />

              <select
                className="input"
                name="type"
                id="discountOnTotal"
                value={discount?.type}
                onChange={e =>
                  handleReductionsChange("type", e.target.value, index)
                }
              >
                <option key={currencySymbol} value={"Absolute"}>
                  {currencySymbol}
                </option>

                <option key={"%"} value={"Percentage"}>
                  %
                </option>
              </select>
              <TextButton
                icon={<CloseOutlined />}
                active
                onClick={() => handleRemoveReduction(index)}
              />
            </div>
          </div>
        ))}
      </div>

      <TextButton
        icon={<LocalOfferIcon />}
        active
        onClick={handleAddReductions}
      >
        Add Discount on Total
      </TextButton>
    </>
  );
};

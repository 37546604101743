// @flow
import * as React from "react";
// import { DndContext, closestCenter } from "@dnd-kit/core";
// import {
//   arrayMove,
//   SortableContext,
//   verticalListSortingStrategy,
// } from "@dnd-kit/sortable";
import { Terms } from "./Terms";
import { Typography } from "@mui/material";
import { TextButton } from "../../../../UILibrary/Buttons/TextButton/TextButton";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  handleInvoiceKeyValueChange,
  setInvoiceDetails,
} from "../../../../redux/Invoice/invoiceActionCreator";
import { RootReducer } from "../../../../redux/rootReducer";
import { Invoice } from "../../../../Types/invoice.types";

type Props = {
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
};
export const TermsAndConditions = ({ handleInvoiceChangeOnBlur }: Props) => {
  const dispatch = useDispatch();
  const terms: string[] = useSelector(
    (state: RootReducer) => state?.invoice?.termsAndConditions
  );

  React.useEffect(() => {
    if (!terms.length) {
      dispatch(setInvoiceDetails({ termsAndConditions: [""] }));
    }
  }, [terms]);

  const handleTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {

    const { value } = event.target;
    const termsCopy = [...terms];
    termsCopy[index] = value;
    dispatch(
      handleInvoiceKeyValueChange(event, "termsAndConditions", termsCopy)
    );
  };

  const handleTermDelete = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const filteredTerms = [...terms?.filter((term, i) => i !== index)];
    handleInvoiceChangeOnBlur({ termsAndConditions: filteredTerms });

    dispatch(
      handleInvoiceKeyValueChange(event, "termsAndConditions", filteredTerms)
    );
  };

  const handleAddNewTerm = (event?: React.ChangeEvent<HTMLInputElement>) => {

    const updatedTerms = [...terms, ""];
    dispatch(
      handleInvoiceKeyValueChange(event, "termsAndConditions", updatedTerms)
    );
    handleInvoiceChangeOnBlur({ termsAndConditions: updatedTerms });
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {

    handleInvoiceChangeOnBlur({ termsAndConditions: terms });
  }

  return (
    <div className="new_invoice_terms_and_conditions">
      <Typography
        variant="body1"
        color={"grey"}
        className="new_invoice_terms_and_conditions_heading"
      >
        Terms and Conditions
      </Typography>
      <ul className="new_invoice_terms_and_conditions_list">
        {terms &&
          terms?.map((term, index) => (
            <Terms
              key={index}
              term={term}
              index={index}
              handleTermChange={handleTermChange}
              handleTermDelete={handleTermDelete}
              handleOnBlur={handleOnBlur}
            />
          ))}
        <TextButton
          icon={<PlusCircleOutlined />}
          active
          onClick={handleAddNewTerm}
          style={{ margin: "1rem 0" }}
        >
          Add New Term
        </TextButton>
      </ul>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "./styles.scss";
import { useSelector } from "react-redux";
const Loader: React.FC = () => {
  const state: any = useSelector((state) => state);
  const [dots, setDots] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        return prevDots.length >= 3 ? "" : prevDots + ".";
      });
    }, 500); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="loading">
      <div className="loading-container">
        <div className="loading-container-icon">
          <AutorenewIcon sx={{ fontSize: "4rem", color: "#323232" }} />
        </div>
        <div className="loading-container-text">
          {state.load.loadingText}
          <span>{dots}</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;

export const employmentAgreement = {
  name: "Employment Agreement",
  docCodeType: "single",
  path: "employee",
  tempCode: "employment-agreement",
  type: "Human Resources",
  disclaimer:
    "Employment contract is a formal, signed agreement between the new employee and the employer.",
  docCodeValues: [
    {
      docCode: "employement-agreement",
    },
  ],
  page: 5,
  pageHeading:
    "Enter below details to generate a prefilled Employment Agreement!",
  fields: [
    {
      page: 1,
      name: "Date on which the agreement will be entered into:",
      field: [
        {
          key: "date_of_agreement",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the employer:",
      field: [
        {
          key: "company_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "company_address",
          type: "input",
          width: "half",
          placeholder: "Company Address",
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the employee:",
      field: [
        {
          key: "employee_name",
          type: "input",
          width: "half",
          placeholder: "Employee's Name",
        },
        {
          key: "employee_address",
          type: "input",
          width: "half",
          placeholder: "Employee's Address",
        },
      ],
    },
    {
      page: 1,
      name: "Type of employment:",
      field: [
        {
          key: "employment_type",
          type: "radio",
          width: "initial",
          options: [
            {
              value: "Full-time",
              label: "Full-time",
            },
            {
              value: "Part-time",
              label: "Part-time",
            },
          ],
        },
      ],
    },
    {
      page: 2,
      name: "Joining date for the employee:",
      field: [
        {
          key: "joining_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 2,
      name: "Employee's job title:",
      field: [
        {
          key: "job_title",
          type: "input",
          width: "half",
          placeholder: "Job Title. Ex: Sales Manager, Software Developer, etc",
        },
      ],
    },
    {
      page: 2,
      name: "New employee's probabtionary period:",
      field: [
        {
          key: "probation_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 3 months, 8 weeks, etc",
        },
      ],
    },
    {
      page: 2,
      name: "Notice period (in days) in case of termination by employer during the probabation period:",
      field: [
        {
          key: "probabtion_notice_period_termination",
          type: "input",
          width: "half",
          placeholder: "Notice period (in days). Ex: 7, 14, 30, etc",
        },
      ],
    },
    {
      page: 2,
      name: "Notice period (in days) in case of resignation by employee during the probabtion period:",
      field: [
        {
          key: "probabtion_notice_period_resignation",
          type: "input",
          width: "half",
          placeholder: "Notice period (in days). Ex: 7, 14, 30, etc",
        },
      ],
    },
    {
      page: 3,
      name: "Employee's annual salary (CTC):",
      field: [
        {
          key: "currency",
          type: "select",
          width: "half",
          placeholder: "Currency",
          options: [
            {
              value: "INR",
              label: "INR",
            },
            {
              value: "USD",
              label: "USD",
            },
          ],
        },
        {
          key: "employee_annual_salary",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
      ],
    },
    {
      page: 3,
      name: "By which day of the subsequent month will the salary be paid for the previous month?",
      field: [
        {
          key: "salary_day",
          type: "select",
          width: "half",
          placeholder: "Day of the Subsequent Month",
          options: [
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 1 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 },
            { label: 9, value: 9 },
            { label: 10, value: 10 },
          ],
        },
      ],
    },
    {
      page: 3,
      name: "Joining bonus to be given to the employee:",
      field: [
        {
          key: "joining_bonus",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
      ],
    },
    {
      page: 3,
      name: "After how many days/weeks/months of the joining date will the joining bonus be paid?",
      field: [
        {
          key: "joining_bonus_time",
          type: "input",
          width: "half",
          placeholder: "Ex: 3 months, 8 weeks, etc",
        },
      ],
    },
    {
      page: 3,
      name: "Employee's job duties:",
      field: [
        {
          key: "employee_job_duties",
          type: "textarea",
          width: "full",
          placeholder: `1. First responsibility
2. Second responsibility
and so on...`,
        },
      ],
    },
    {
      page: 4,
      name: "Office address from where the employee would be working:",
      field: [
        {
          key: "office_address",
          type: "input",
          width: "half",
          placeholder: "Office Address",
        },
      ],
    },
    {
      page: 4,
      name: "Hours per week that the employee will be required to work:",
      field: [
        {
          key: "hours_per_week",
          type: "input",
          width: "half",
          placeholder: "Hours per week (number). Ex: 36, 40, 45, etc.",
        },
      ],
    },
    {
      page: 4,
      name: "Enter the time period post termination for which the employees should be restricted from sharing any confidential information of the company:",
      field: [
        {
          key: "confidential_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 1 year, 2 year, 6 months",
        },
      ],
    },
    {
      page: 4,
      name: "Notice period to be given by the employer for termination of employee or by the employee in case of resignation:",
      field: [
        {
          key: "notice_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 2 weeks, 1 month, 2 months",
        },
      ],
    },
    {
      page: 4,
      name: "Restrain period for the employee to not work with a competitor after the employee leaves the company:",
      field: [
        {
          key: "restrain_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 2 weeks, 1 month, 2 months",
        },
      ],
    },
    {
      page: 4,
      name: "Period of restriction regarding non-solicitation (for instance, not soliciting customers) to be imposed after employee leaves the company:",
      field: [
        {
          key: "non_solicitation_period",
          type: "input",
          width: "half",
          placeholder: "Ex: 2 weeks, 1 month, 2 months",
        },
      ],
    },
    {
      page: 5,
      name: "Courts of this state have the jurisdiction over any dispute arising out of this Agreement:",
      field: [
        {
          key: "court_state",
          type: "input",
          width: "half",
          placeholder: "State",
          options: [
            {
              label: "Andaman and Nicobar Islands",
              value: "Andaman and Nicobar Islands",
            },
            {
              label: "Andhra Pradesh",
              value: "Andhra Pradesh",
            },
            {
              label: "Arunachal Pradesh",
              value: "Arunachal Pradesh",
            },
            {
              label: "Assam",
              value: "Assam",
            },
            {
              label: "Bihar",
              value: "Bihar",
            },
            {
              label: "Chandigarh",
              value: "Chandigarh",
            },
            {
              label: "Chhattisgarh",
              value: "Chhattisgarh",
            },
            {
              label: "Dadra and Nagar Haveli",
              value: "Dadra and Nagar Haveli",
            },
            {
              label: "Daman and Diu",
              value: "Daman and Diu",
            },
            {
              label: "Delhi",
              value: "Delhi",
            },
            {
              label: "Goa",
              value: "Goa",
            },
            {
              label: "Gujarat",
              value: "Gujarat",
            },
            {
              label: "Haryana",
              value: "Haryana",
            },
            {
              label: "Himachal Pradesh",
              value: "Himachal Pradesh",
            },
            {
              label: "Jammu and Kashmir",
              value: "Jammu and Kashmir",
            },
            {
              label: "Jharkhand",
              value: "Jharkhand",
            },
            {
              label: "Karnataka",
              value: "Karnataka",
            },
            {
              label: "Kerala",
              value: "Kerala",
            },
            {
              label: "Lakshadweep",
              value: "Lakshadweep",
            },
            {
              label: "Madhya Pradesh",
              value: "Madhya Pradesh",
            },
            {
              label: "Maharashtra",
              value: "Maharashtra",
            },
            {
              label: "Manipur",
              value: "Manipur",
            },
            {
              label: "Meghalaya",
              value: "Meghalaya",
            },
            {
              label: "Mizoram",
              value: "Mizoram",
            },
            {
              label: "Nagaland",
              value: "Nagaland",
            },
            {
              label: "Odisha",
              value: "Odisha",
            },
            {
              label: "Puducherry",
              value: "Puducherry",
            },
            {
              label: "Punjab",
              value: "Punjab",
            },
            {
              label: "Rajasthan",
              value: "Rajasthan",
            },
            {
              label: "Sikkim",
              value: "Sikkim",
            },
            {
              label: "Tamil Nadu",
              value: "Tamil Nadu",
            },
            {
              label: "Telangana",
              value: "Telangana",
            },
            {
              label: "Tripura",
              value: "Tripura",
            },
            {
              label: "Uttar Pradesh",
              value: "Uttar Pradesh",
            },
            {
              label: "Uttarakhand",
              value: "Uttarakhand",
            },
            {
              label: "West Bengal",
              value: "West Bengal",
            },
          ],
        },
      ],
    },
    {
      page: 3,
      name: "",
      field: [
        {
          key: "job_title",
          type: "input",
          width: "half",
          placeholder: "",
        },
      ],
    },
    {
      page: 5,
      name: "Date on which the employment agreement will be signed by the employer:",
      field: [
        {
          key: "employer_sign_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 5,
      name: "Date on which the employment agreement will be signed by the employee:",
      field: [
        {
          key: "employee_sign_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 5,
      name: "Name of the person signing the agreement on behalf of the company:",
      field: [
        {
          key: "company_rep_name",
          type: "input",
          width: "half",
          placeholder: "Designation",
        },
      ],
    },
    {
      page: 5,
      name: "Designation of the above person in the company:",
      field: [
        {
          key: "company_rep_designation",
          type: "input",
          width: "half",
          placeholder: "Designation",
        },
      ],
    },
  ],
};

import React from "react";
import { Form, Input } from "antd";
import { Button, IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Props } from "./type";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";
const deleteIcon = require("../../images/delete.png");
const infoIcon = require("../../images/info.png");

const validateEmail = (rule: unknown, value: string, callback: any) => {
  if (value && !/^\S+@\S+\.\S+$/.test(value)) {
    callback("Please enter a valid email address");
  } else {
    callback();
  }
};
const CompanyInviteUI: React.FC<Props> = (props) => {
  const {
    inputs,
    addRow,
    deleteRow,
    handleChange,
    handleInvite,
    handleSkip,
    companyName,
    sentLoading,
  } = props;
  return (
    <div className="invite-collab" style={{ position: "relative", zIndex: 2 }}>
      <div className="invite-collab-text">
        <div className="invite-collab-text-heading">
          Invite people from your team
        </div>
        <div className="invite-collab-text-message">
          <span>Collaborate with your internal and external stakeholders</span>
          <span>Invite people to {companyName}</span>
        </div>
      </div>
      <Form className="invite-collab-form" onFinish={handleInvite}>
        {inputs.map((item, indx) => (
          <div className="invite-collab-form-comp">
            <Form.Item
              className="invite-collab-form-item"
              name={`email${indx}`}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter the email",
                },
                { validator: validateEmail },
              ]}
              initialValue={item.value}
            >
              <Input
                className="invite-collab-form-input-name"
                placeholder="Email"
                onChange={(e) => handleChange(indx, e.target.value)}
              />
            </Form.Item>

            {inputs.length > 1 && (
              <Tooltip title="Delete row" placement="bottom-start">
                <IconButton color="primary" onClick={() => deleteRow(indx)}>
                  <img src={deleteIcon} alt="" />
                </IconButton>
              </Tooltip>
            )}
            {indx === 0 ? (
              <Tooltip title="Invite Collaborators" placement="bottom-start">
                <IconButton
                  disableRipple
                  style={{ cursor: "text" }}
                  sx={{ marginLeft: `${inputs.length === 1 && "10px"}` }}
                >
                  <img src={infoIcon} alt="" />
                </IconButton>
              </Tooltip>
            ) : (
              <div className="info-placeholder"></div>
            )}
          </div>
        ))}
        <div className="invite-collab-form-add-input">
          <Button variant="text" onClick={addRow}>
            + Add More
          </Button>
        </div>
        <div className="invite-collab-form-submit">
          {sentLoading ? (
            <MiniLoaderButton />
          ) : (
            <Button
              className="invite-collab-form-submit-button"
              variant="contained"
              color="primary"
              type="submit"
            >
              Invite
            </Button>
          )}
          &nbsp; &nbsp; &nbsp;
          <Button
            className="invite-collab-form-submit-button-outlined"
            variant="outlined"
            color="primary"
            onClick={handleSkip}
          >
            Skip
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CompanyInviteUI;

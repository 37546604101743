// @flow
import * as React from "react";
import { OutlinedButton } from "../../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import { TableIn } from "../../../../UILibrary/Table/Table";
import { Space } from "antd";
import { useQueryClient } from "react-query";
import { Column } from "../../../../Types/global.types";
import { Invoice } from "../../../../Types/invoice.types";

const DeleteIcon = require("../../../../images/delete.png");
const DownloadIcon = require("../../../../images/download.png");

type Props = {
  deletedInvoices: Invoice[];
  companyId: string | undefined;
};
export const DeletedInvoices = ({ deletedInvoices, companyId }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
  const queryClient = useQueryClient();

  const handleSelectionDelete = (selectedRowKeys: string[]) => { };
  const handleSingleDelete = (invoiceId: string) => {
    // delete api call
    queryClient.invalidateQueries({ queryKey: ["invoices"] });
  };

  const columns: Column[] = [
    {
      title: "Date",
      columnKey: "dueDate",
      key: "dueDate",
      width: "120px",
      render: (data: string) => new Date(data).toDateString(),
    },
    {
      title: "Invoice#",
      columnKey: "invoiceNumber",
      key: "invoiceNumber",
      width: "120px",
    },
    {
      title: "Billed To",
      columnKey: "clientDetails",
      key: "clientId",
      width: "238px",
      render: (clientDetails: any) => clientDetails?.name,
    },
    { title: "Status", columnKey: "status", key: "status", width: "183px" },
    {
      title: "",
      columnKey: "actions",
      key: "actions",
      render: (_: any, row: any, index: number) => (
        <Space size={25} align="start">
          <div
            className="list_action"
            onClick={() => {
              window.open(
                `/dashboard/invoice/download?id=${companyId}&invoice-id=${row.id}`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            <div className="list_action_icon list_action_delete">
              <img
                width={"100%"}
                height={"100%"}
                src={DownloadIcon}
                alt="Download"
              />
            </div>
            <div className="list_action_text">Download</div>
          </div>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Space size={25} align="start">
        {/* <OutlinedButton
          disabled={!selectedRowKeys.length}
          active={!!selectedRowKeys?.length}
          style={{ marginBottom: "20px" }}
          onClick={() => handleSelectionDelete(selectedRowKeys)}
        >
          Delete
        </OutlinedButton>
        <OutlinedButton
          disabled={!selectedRowKeys.length}
          active={!!selectedRowKeys?.length}
          style={{ marginBottom: "20px" }}
          onClick={() => handleSelectionDelete(selectedRowKeys)}
        >
          Send Email
        </OutlinedButton> */}
      </Space>

      <TableIn
        classname="deleted_clients_table"
        columns={columns}
        data={deletedInvoices}
        // selectedRows={selectedRowKeys}
        // setSelectedRows={setSelectedRowKeys}
        // selectableRows
      />
    </>
  );
};

import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Invite } from ".";
import { Input, Select } from "antd";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";
const deleteIcon = require("../../images/delete.png");
interface Props {
  inviteRows: Invite[];
  addRow: () => void;
  deleteRow: (ind: number) => void;
  handleChange: (ind: number, name: string, value: string) => void;
  errorMessage: string;
  sendInvite: () => void;
  loadSendInvite: boolean;
}
const CoworkerInviteUI: React.FC<Props> = (props) => {
  const {
    inviteRows,
    addRow,
    deleteRow,
    handleChange,
    errorMessage,
    sendInvite,
    loadSendInvite,
  } = props;

  return (
    <div className="invite_main">
      <div className="invite_info">
        <Tooltip
          title="Invited co-workers would have all the permissions except the option to delete the company"
          placement="bottom-start"
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className="invite_heading">
        Invite your co-workers to collaborate on Cashwise.
      </div>
      <div className="invite_list_input">
        {inviteRows.map((item, ind) => (
          <div className="invite_item" key={ind}>
            <Input
              placeholder="Enter email"
              className="input_email"
              value={inviteRows[ind].email}
              onChange={(e) => handleChange(ind, "email", e.target.value)}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Select
              placement="bottomRight"
              size="large"
              rootClassName="add-form-select"
              dropdownStyle={{ minWidth: "140px" }}
              popupClassName="select-popup"
              placeholder="Select Role"
              style={{ width: "140px" }}
              onChange={(value) => handleChange(ind, "role", value)}
              options={[
                {
                  label: "Admin",
                  value: "ADMIN",
                },
              ]}
              defaultValue={"ADMIN"}
            ></Select>
            &nbsp; &nbsp;
            <Tooltip title="Delete Row" placement="right-end">
              <IconButton onClick={() => deleteRow(ind)} color="error">
                {/* <DeleteIcon /> */}
                <img src={deleteIcon} alt="" />
              </IconButton>
            </Tooltip>
          </div>
        ))}
      </div>
      {inviteRows.length === 0 ? (
        <Button variant="contained" onClick={addRow}>
          Add Co-workers
        </Button>
      ) : (
        <Button variant="text" onClick={addRow} className="add_row">
          + Add More
        </Button>
      )}
      {errorMessage && (
        <div className="error">
          Error: Can't send invites
          <br /> {errorMessage}
        </div>
      )}

      {inviteRows.length !== 0 && (
        <div className="send_invite">
          {loadSendInvite ? (
            <MiniLoaderButton />
          ) : (
            <Button variant="contained" onClick={sendInvite}>
              Send Invite
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default CoworkerInviteUI;

import * as React from "react";
import "./OutlinedButton.scss";
import { CircularProgress } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  dashed?: boolean;
  style?: any;
  icon?: React.ReactNode;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  alignLoader?: string
};
export const OutlinedButton = ({
  disabled,
  active,
  children,
  style,
  onClick,
  dashed,
  icon,
  isLoading,
  alignLoader
}: Props) => {
  return (
    <div className="outlined-button-wrapper">
      {(isLoading && (alignLoader ? alignLoader === "left" : false)) && (
        <CircularProgress size={30} style={{ marginRight: "20px" }} />
      )}
      <button
        onClick={onClick}
        disabled={disabled}
        className={`outlined-button ${active ? "active-button" : ""} ${dashed ? "dashed-border" : ""
          }`}
        style={style}
      >
        {icon && (
          <span style={{ marginRight: children ? "0.5rem" : 0 }}>{icon}</span>
        )}
        {(isLoading && (alignLoader ? alignLoader === "center" : true)) ?
          <CircularProgress size={30} />
          : children
        }
      </button>
      {(isLoading && (alignLoader ? alignLoader === "right" : false)) && (
        <CircularProgress size={30} style={{ marginLeft: "20px" }} />
      )}
    </div>
  );
};

import React, { useState } from "react";
import {
  addFund,
  checkRecordType,
  fileDownload,
  fileRename,
  fileUpload,
  formationDocs,
} from "../formationDeligence/formationApiType";
import { Dialog, Button, IconButton, Slide, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Form, Input, Select, Space, ConfigProvider } from "antd";
import { complianceDocs } from "./ComplianceTypes";
const rename = require("../images/rename.png");
const download = require("../images/download.png");
const deleteIcon = require("../images/delete.png");

interface Props {
  complianceDocsData: complianceDocs[] | null;
  tab: any;
  handleUploadRecordItem: ({
    imageFile,
    recordId,
  }: fileUpload) => Promise<boolean | undefined>;
  loadingUpload: boolean;
  handleRename: ({
    itemId,
    recordId,
    fileName,
  }: fileRename) => Promise<boolean | undefined>;
  handleDownload: ({ itemId, recordId }: fileDownload) => void;
  handleDelete: ({
    itemId,
    recordId,
  }: fileDownload) => Promise<boolean | undefined>;
  checkRecords: checkRecordType[];
  handleChecks: (it: any) => void;
  showEmptyCheck: boolean;
  handleEmptyView: () => void;
  selectFy: any;
  setSelectFy: any;
  selectMonthFyA: any;
  setSelectMonthFyA: any;
  selectMonthFyB: any;
  setSelectMonthFyB: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const RecordListA: React.FC<Props> = (props) => {
  const {
    complianceDocsData,
    tab,
    handleUploadRecordItem,
    loadingUpload,
    handleRename,
    handleDownload,
    handleDelete,
    checkRecords,
    handleChecks,
    handleEmptyView,
    showEmptyCheck,
    selectFy,
    setSelectFy,
    selectMonthFyA,
    setSelectMonthFyA,
    selectMonthFyB,
    setSelectMonthFyB,
  } = props;

  return (
    <div className="formation-docs-details-data-record-list">
      {complianceDocsData
        ?.filter((item) => item.name === tab)
        .map((item) => (
          <div className="formation-docs-details-data-record-list-select">
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#F5f5f5",
                  lineWidth: 0,
                },
              }}
            >
              <Select
                placement="bottomRight"
                size="large"
                rootClassName="add-form-select"
                dropdownStyle={{ minWidth: "140px" }}
                popupClassName="select-popup"
                placeholder="Anually Filings"
                onChange={(value) => setSelectFy(value)}
                options={item.FYs?.map((it) => ({
                  value: it.name,
                  label: it.name,
                }))}
                style={{ width: "190px" }}
                defaultValue={item.FYs[0].name}
              />
            </ConfigProvider>
            <div className="formation-docs-details-data-record-list">
              {item.FYs?.filter((it) => it.name === selectFy).map((it) => (
                <>
                  {it?.records
                    ?.filter((fr) => {
                      if (showEmptyCheck && fr.items.length !== 0) {
                        return true;
                      } else if (!showEmptyCheck) {
                        return true;
                      }
                    })
                    ?.map((fr) => {
                      return (
                        <div className="formation-docs-details-data-record-list-item">
                          <div className="formation-docs-details-data-record-list-item-check">
                            <input
                              type="checkbox"
                              checked={
                                checkRecords.filter(
                                  (checking) => checking.id === fr.id
                                ).length !== 0
                                  ? true
                                  : false
                              }
                              onChange={() => handleChecks(fr)}
                            />
                            <div className="formation-docs-details-data-record-list-item-check-name">
                              {fr.kind}
                            </div>
                          </div>
                          <div className="formation-docs-details-data-item-upload">
                            {fr.allowMultipleItems ? (
                              <div className="formation-docs-details-data-record-list-item-upload">
                                {fr.items.length !== 0 && (
                                  <>
                                    {fr.items.map((recordItem) => {
                                      return (
                                        <div className="formation-docs-details-data-record-list-item-upload-uploaded">
                                          <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial">
                                            <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-name">
                                              {recordItem.name}
                                            </div>
                                            <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-svg">
                                              <TextSnippetOutlinedIcon />
                                            </div>
                                          </div>
                                          <div className="formation-docs-details-data-record-list-item-upload-uploaded-hovered">
                                            <Rename
                                              itemId={recordItem.id}
                                              recordId={fr.id}
                                              initialName={recordItem.name}
                                              {...{
                                                handleRename,
                                                loadingUpload,
                                              }}
                                            />
                                            <Tooltip title="Download">
                                              <div
                                                className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
                                                onClick={() => {
                                                  handleDownload({
                                                    itemId: recordItem.id,
                                                    recordId: fr.id,
                                                  });
                                                }}
                                              >
                                                <img src={download} alt="" />
                                              </div>
                                            </Tooltip>
                                            <Deletes
                                              itemId={recordItem.id}
                                              recordId={fr.id}
                                              {...{
                                                handleDelete,
                                                loadingUpload,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                                <MultipleInputs
                                  addType={fr.kind}
                                  initialCount={fr.items.length === 0 ? 1 : 0}
                                  recordId={fr.id}
                                  {...{
                                    handleUploadRecordItem,
                                    loadingUpload,
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="formation-docs-details-data-item-upload">
                                {fr.items.length === 0 ? (
                                  <>
                                    <Inputs
                                      name={fr.kind}
                                      recordId={fr.id}
                                      {...{
                                        handleUploadRecordItem,
                                        loadingUpload,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <div className="formation-docs-details-data-record-list-item-upload-uploaded">
                                    <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial">
                                      <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-name">
                                        {fr.items[0].name}
                                      </div>
                                      <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-svg">
                                        <TextSnippetOutlinedIcon />
                                      </div>
                                    </div>
                                    <div className="formation-docs-details-data-record-list-item-upload-uploaded-hovered">
                                      <div>
                                        <Rename
                                          itemId={fr.items[0].id}
                                          recordId={fr.id}
                                          initialName={fr.items[0].name}
                                          {...{
                                            handleRename,
                                            loadingUpload,
                                          }}
                                        />
                                      </div>
                                      <Tooltip title="Download">
                                        <div
                                          className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
                                          onClick={() => {
                                            handleDownload({
                                              itemId: fr.items[0].id,
                                              recordId: fr.id,
                                            });
                                          }}
                                        >
                                          <img src={download} alt="" />
                                        </div>
                                      </Tooltip>
                                      <Deletes
                                        itemId={fr.items[0].id}
                                        recordId={fr.id}
                                        {...{
                                          handleDelete,
                                          loadingUpload,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export const RecordListB: React.FC<Props> = (props) => {
  const {
    complianceDocsData,
    tab,
    handleUploadRecordItem,
    loadingUpload,
    handleRename,
    handleDownload,
    handleDelete,

    checkRecords,
    handleChecks,
    handleEmptyView,
    showEmptyCheck,
    selectFy,
    setSelectFy,
    selectMonthFyA,
    setSelectMonthFyA,
    selectMonthFyB,
    setSelectMonthFyB,
  } = props;

  return (
    <div className="formation-docs-details-data-record-list">
      {complianceDocsData
        ?.filter((item) => item.name === tab)
        .map((item) => (
          <div className="formation-docs-details-data-record-list-select">
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#F5f5f5",
                  lineWidth: 0,
                },
              }}
            >
              <Select
                placement="bottomRight"
                size="large"
                rootClassName="add-form-select"
                dropdownStyle={{ minWidth: "140px" }}
                popupClassName="select-popup"
                placeholder="Monthly Filings"
                onChange={(value) => setSelectMonthFyA(value)}
                options={item.FYs?.map((it) => ({
                  value: it.name,
                  label: it.name,
                }))}
                style={{ width: "190px" }}
                defaultValue={item.FYs[0].name}
              />
            </ConfigProvider>
            <span style={{ display: "inline-block", width: "30px" }}></span>
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#F5f5f5",
                  lineWidth: 0,
                },
              }}
            >
              <Select
                placement="bottomRight"
                size="large"
                rootClassName="add-form-select"
                dropdownStyle={{ minWidth: "140px" }}
                popupClassName="select-popup"
                placeholder="Number of Employees"
                onChange={(value) => setSelectMonthFyB(value)}
                options={item.FYs.filter(
                  (it) => it.name === selectMonthFyA
                )[0].months?.map((it) => ({
                  value: it.name,
                  label: it.name,
                }))}
                style={{ width: "190px" }}
                defaultValue={
                  item.FYs.filter((it) => it.name === selectMonthFyA)[0]
                    .months[0].name
                }
              />
            </ConfigProvider>
            <div className="formation-docs-details-data-record-list">
              {item.FYs?.filter((it) => it.name === selectMonthFyA)[0]
                .months.filter((it) => it.name === selectMonthFyB)
                .map((it) => (
                  <>
                    {it?.records
                      ?.filter((fr) => {
                        if (showEmptyCheck && fr.items.length !== 0) {
                          return true;
                        } else if (!showEmptyCheck) {
                          return true;
                        }
                      })
                      ?.map((fr) => {
                        return (
                          <div className="formation-docs-details-data-record-list-item">
                            <div className="formation-docs-details-data-record-list-item-check">
                              <input
                                type="checkbox"
                                checked={
                                  checkRecords.filter(
                                    (checking) => checking.id === fr.id
                                  ).length !== 0
                                    ? true
                                    : false
                                }
                                onChange={() => handleChecks(fr)}
                              />
                              <div className="formation-docs-details-data-record-list-item-check-name">
                                {fr.kind}
                              </div>
                            </div>
                            <div className="formation-docs-details-data-item-upload">
                              {fr.allowMultipleItems ? (
                                <div className="formation-docs-details-data-record-list-item-upload">
                                  {fr.items.length !== 0 && (
                                    <>
                                      {fr.items.map((recordItem) => {
                                        return (
                                          <div className="formation-docs-details-data-record-list-item-upload-uploaded">
                                            <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial">
                                              <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-name">
                                                {recordItem.name}
                                              </div>
                                              <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-svg">
                                                <TextSnippetOutlinedIcon />
                                              </div>
                                            </div>
                                            <div className="formation-docs-details-data-record-list-item-upload-uploaded-hovered">
                                              <Tooltip title="Rename">
                                                <Rename
                                                  itemId={recordItem.id}
                                                  recordId={fr.id}
                                                  initialName={recordItem.name}
                                                  {...{
                                                    handleRename,
                                                    loadingUpload,
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip title="Download">
                                                <div
                                                  className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
                                                  onClick={() => {
                                                    handleDownload({
                                                      itemId: recordItem.id,
                                                      recordId: fr.id,
                                                    });
                                                  }}
                                                >
                                                  <img src={download} alt="" />
                                                </div>
                                              </Tooltip>
                                              <Tooltip title="Delete">
                                                <Deletes
                                                  itemId={recordItem.id}
                                                  recordId={fr.id}
                                                  {...{
                                                    handleDelete,
                                                    loadingUpload,
                                                  }}
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                  <MultipleInputs
                                    addType={fr.kind}
                                    initialCount={fr.items.length === 0 ? 1 : 0}
                                    recordId={fr.id}
                                    {...{
                                      handleUploadRecordItem,
                                      loadingUpload,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="formation-docs-details-data-item-upload">
                                  {fr.items.length === 0 ? (
                                    <>
                                      <Inputs
                                        name={fr.kind}
                                        recordId={fr.id}
                                        {...{
                                          handleUploadRecordItem,
                                          loadingUpload,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <div className="formation-docs-details-data-record-list-item-upload-uploaded">
                                      <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial">
                                        <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-name">
                                          {fr.items[0].name}
                                        </div>
                                        <div className="formation-docs-details-data-record-list-item-upload-uploaded-initial-svg">
                                          <TextSnippetOutlinedIcon />
                                        </div>
                                      </div>
                                      <div className="formation-docs-details-data-record-list-item-upload-uploaded-hovered">
                                        <Tooltip title="Rename">
                                          <Rename
                                            itemId={fr.items[0].id}
                                            recordId={fr.id}
                                            initialName={fr.items[0].name}
                                            {...{
                                              handleRename,
                                              loadingUpload,
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip title="Download">
                                          <div
                                            className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
                                            onClick={() => {
                                              handleDownload({
                                                itemId: fr.items[0].id,
                                                recordId: fr.id,
                                              });
                                            }}
                                          >
                                            <img src={download} alt="" />
                                          </div>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <Deletes
                                            itemId={fr.items[0].id}
                                            recordId={fr.id}
                                            {...{
                                              handleDelete,
                                              loadingUpload,
                                            }}
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

interface RenameProps {
  initialName: string;
  itemId: string | undefined;
  recordId: string | undefined;
  loadingUpload: boolean;
  handleRename: ({
    itemId,
    recordId,
    fileName,
  }: fileRename) => Promise<boolean | undefined>;
}

interface DeleteProps {
  itemId: string | undefined;
  recordId: string | undefined;
  loadingUpload: boolean;
  handleDelete: ({
    itemId,
    recordId,
  }: fileDownload) => Promise<boolean | undefined>;
}

interface InputProps {
  name: string;
  handleUploadRecordItem: ({
    imageFile,
    recordId,
  }: fileUpload) => Promise<boolean | undefined>;
  recordId: string | null;
  loadingUpload: boolean;
}
interface FundingProps {
  btnType: "text" | "outlined" | "contained" | undefined;
  loadingUpload: boolean;
  handleAddFunding: ({ fundingName }: addFund) => any | undefined;
}

const MultipleInputs = ({
  addType,
  initialCount,
  recordId,
  handleUploadRecordItem,
  loadingUpload,
}: any) => {
  const [count, setCount] = useState<number>(initialCount);
  return (
    <>
      {[...Array(count)].map((e, i) => (
        <>
          <Inputs
            name={addType}
            {...{ recordId, handleUploadRecordItem, loadingUpload }}
          />
        </>
      ))}
      <Button
        className="formation-docs-details-data-record-list-item-upload-initial-button-dialog"
        onClick={() => {
          setCount(count + 1);
        }}
      >
        <span>Add</span>
      </Button>
    </>
  );
};

const Rename: React.FC<RenameProps> = (props) => {
  const { itemId, recordId, handleRename, initialName, loadingUpload } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>(initialName);
  return (
    <>
      <Tooltip title="Rename">
        <div
          className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
          onClick={() => setOpen(true)}
        >
          <img src={rename} alt="" />
        </div>
      </Tooltip>
      <Dialog TransitionComponent={Transition} open={open}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Edit Your Name</div>
          <Form
            className="register-form"
            onFinish={async () => {
              const val = await handleRename({
                itemId,
                recordId,
                fileName: name,
              });
              if (val) {
                setOpen(false);
              }
            }}
          >
            <Form.Item
              className="register-form-item"
              name="firstName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter your first name",
                },
              ]}
              initialValue={name}
            >
              <Input
                className="register-form-input-name"
                placeholder="First Name"
                onChange={(e: any) => setName(e.target.value)}
              />
            </Form.Item>

            <div className="register-form-submit">
              {loadingUpload ? (
                <div className="buttons-loading">
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  className={`register-form-submit-button`}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={initialName === name ? true : false}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Dialog>
    </>
  );
};

const Deletes: React.FC<DeleteProps> = (props) => {
  const { itemId, recordId, loadingUpload, handleDelete } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {" "}
      <Tooltip title="Delete">
        <div
          className="formation-docs-details-data-record-list-item-upload-uploaded-hovered-icon"
          onClick={() => setOpen(true)}
        >
          <img src={deleteIcon} alt="" />
        </div>
      </Tooltip>
      <Dialog TransitionComponent={Transition} open={open}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Delete this File</div>
          <div className="dialog-content">
            Are you sure you want to delete this File?
          </div>
          <div className="dialog-buttons">
            {loadingUpload ? (
              <div className="buttons-loading">
                <CircularProgress />
              </div>
            ) : (
              <Button
                className="dialog-buttons-contained"
                variant="contained"
                onClick={async () => {
                  const val = await handleDelete({ itemId, recordId });
                  if (val) {
                    setOpen(false);
                  }
                }}
              >
                Delete
              </Button>
            )}
            <Button
              className="dialog-buttons-outlined"
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const Inputs: React.FC<InputProps> = (props) => {
  const { name, handleUploadRecordItem, recordId, loadingUpload } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<any>(null);

  const handleRecordChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      setImageFile(file);
    }
  };

  return (
    <>
      <Button
        className="formation-docs-details-data-record-list-item-upload-initial-button-dialog"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        <span>+ Add File</span>
      </Button>
      <Dialog TransitionComponent={Transition} open={open}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Uplaod File</div>
          <div style={{ marginTop: "2.7rem" }}></div>
          <div className="dialog-file-upload">
            <input
              type="file"
              name="record_item_upload"
              id="record_item_upload"
              accept="image/*"
              onChange={handleRecordChange}
            />
            {imageFile ? (
              <label htmlFor="record_item_upload" className="upload_logo_label">
                <CloudUploadIcon htmlColor="#1161d6" /> &nbsp;&nbsp;&nbsp;
                {imageFile.name}
              </label>
            ) : (
              <label htmlFor="record_item_upload" className="upload_logo_label">
                <CloudUploadIcon htmlColor="#1161d6" />
                &nbsp;&nbsp;&nbsp;Choose File
              </label>
            )}
          </div>{" "}
          <div style={{ marginTop: "2.7rem" }}></div>
          <div className="dialog-buttons">
            {loadingUpload ? (
              <div className="buttons-loading">
                <CircularProgress />
              </div>
            ) : (
              <Button
                className="dialog-buttons-contained"
                variant="contained"
                disabled={imageFile ? false : true}
                onClick={async () => {
                  const val = await handleUploadRecordItem({
                    imageFile,
                    recordId,
                  });
                  if (val) {
                    setOpen(false);
                  }
                }}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

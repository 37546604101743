import { loaderActions } from "./actions";

export const setLoader = (load: boolean) => {
  return {
    type: loaderActions.SET_LOADING,
    payload: load,
  };
};
export const setLoadMessage = (loadText: string) => {
  return {
    type: loaderActions.LOADING_TEXT,
    payload: loadText,
  };
};

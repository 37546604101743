// @flow
import * as React from "react";

import { InvoiceTemplatesFooter } from "./InvoiceTemplatesFooter/InvoiceTemplatesFooter";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
import { TabsIn } from "../../../UILibrary/Tabs/Tabs";
import { Tab } from "../../../Types/global.types";

type Props = {
  invoiceTemplates: any;
  handleEdit: Function;
  handleSingleDelete: Function;
  tabs: Tab[];
};
export const InvoiceTemplatesUI: React.FC<Props> = ({
  invoiceTemplates,
  tabs,
}: Props) => {

  return (
    <>
      <AppContentCard>
        <TabsIn tabs={tabs} />
      </AppContentCard>

      <InvoiceTemplatesFooter />
    </>
  );
};

// @flow
import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";

import { TextButton } from "../../Buttons/TextButton/TextButton";
import { Space } from "antd";
import { Column } from "../../../Types/global.types";

type Props = {
  columns: any;
  selectedRows?: string[];
  selectableRows?: boolean;
  removableRows?: boolean;
  onRemoveRow?: Function;
  handleCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  row: any;
  index: number;
  extraFunctionality?: Function;
  cellPadding?: string;
};
export const TableRowIn = ({
  columns,
  selectedRows,
  selectableRows,
  removableRows,
  onRemoveRow,
  row,
  index,
  extraFunctionality,
  handleCheckboxClick,
  cellPadding,
}: Props) => {
  return (
    <>
      <tr
        className={extraFunctionality ? "row_with_extra_functionality" : ""}
        style={{ position: "relative" }}
      >
        {selectableRows && (
          <td style={{ width: "62px", padding: cellPadding || "14px 16px" }}>
            <input
              className="row-list-table-checkbox"
              type="checkbox"
              value={row.key || row.id}
              checked={selectedRows?.includes(row.key || row.id)}
              onChange={handleCheckboxClick}
            />
          </td>
        )}
        {columns.map((column: Column) => (
          <td
            key={column.key}
            style={{
              width: `${column.width}`,
              padding: cellPadding || "14px 16px",
            }}
            className={`${!extraFunctionality ? "table_td_border_bottom" : ""}`}
            align={column.align || "left"}
          >
            <Space size={5} align="center">
              {column.isInputType || row?.[column.columnKey] ? column.prefix : ""}

              {column.isInputType ? (
                <input
                  name={column.columnKey}
                  value={row?.[column?.columnKey]}
                  placeholder={column.placeholder || ""}
                  type={column.inputType || "text"}
                  className="row-list-table-input"
                  defaultValue={column.defaultValue}
                  min={column.minInputValue}
                  onChange={event => {
                    column?.onInputChange?.(event, { ...row }, index);
                  }}
                  onBlur={(e) => column?.onBlur?.(e, { ...row }, index)}
                />
              ) : column.render ? (
                column.render(row[column.columnKey], row, index)
              ) : row[column.columnKey] === undefined ||
                row[column.columnKey] === null ||
                row[column.columnKey] === "" ? (
                "-"
              ) : (
                row[column.columnKey]
              )}

              {column.isInputType || row[column.columnKey] ? column.suffix : ""}
            </Space>
          </td>
        ))}

        {removableRows && (
          <td style={{ width: "100%", padding: cellPadding || "14px 16px" }}>
            <TextButton
              onClick={() => {
                onRemoveRow?.(row, index);
              }}
            >
              <CloseIcon />
            </TextButton>
          </td>
        )}
      </tr>

      {extraFunctionality?.(row, index) || ""}
    </>
  );
};

// @flow
import * as React from "react";
import { toast } from "react-toastify";
type Props = {};
export const toastIn = {
  error: (message: string | React.ReactElement) =>
    toast.error(message || "Error!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }),
  success: (message: string | React.ReactElement) =>
    toast.success(message || "Success!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }),
  info: (message: string | React.ReactElement) =>
    toast.info(message || "", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }),
};

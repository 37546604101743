import React, { useEffect, useState } from "react";
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { saveAs } from "file-saver";
import auth from "../../../firebase/auth";
import { getIndivCompany } from "../../../api/companies/companies";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Entity } from "../../constants";
import ComplianceUI from "./ComplianceUI";
import {
  addRecord,
  addRecordItem,
  deleteRecordItem,
  deleteRecords,
  downloadRecordItem,
  getDocs,
  renameRecordItem,
  shareRecords,
} from "../../../api/docStorage/docStorage";
import {
  checkRecordType,
  complianceDocs,
  fileDownload,
  fileRename,
  fileUpload,
} from "./ComplianceTypes";
import path from "path";

const Compliance: React.FC = () => {
  const state: any = useSelector((state) => state);
  const userDetail = state.user.userDetails;
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [entity, setEntity] = useState<Entity>({
    entityName: "",
    website: "",
    logo: "",
  });

  const [tokenValue, setToken] = useState<string>("");
  const [complianceDocsData, setComplianceDocsData] = useState<
    complianceDocs[] | null
  >(null);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [checkRecords, setCheckRecords] = useState<checkRecordType[]>([]);
  const [showEmptyCheck, setShowEmptyCheck] = useState<boolean>(false);

  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        if (token) {
          setToken(token);
          const entityDetails = await getIndivCompany({ token, id });

          if (entityDetails && entityDetails.type === "success") {
            setEntity({
              entityName: entityDetails.data.name,
              website: entityDetails.data.website,
              logo: entityDetails.data.logoUrl,
            });
            const complianceDetails = await getDocs({
              id,
              type: "compliance-docs",
              token,
            });
            if (complianceDetails && complianceDetails.type === "success") {
              setComplianceDocsData(complianceDetails.data);
              setLoading(false);
            } else if (
              complianceDetails &&
              complianceDetails.type === "error"
            ) {
              setError(entityDetails.errorType);
              setLoading(false);
            }
            setLoading(false);
            setLoading(false);
          } else if (entityDetails && entityDetails.type === "error") {
            setError(entityDetails.errorType);
            setLoading(false);
          }
        }
      }
    });
  }, []);

  const handleUploadRecordItem = async ({
    imageFile,
    recordId,
  }: fileUpload) => {
    setLoadingUpload(true);
    const formData = new FormData();
    formData.append("docItem", imageFile);
    const uploadImage = await addRecordItem({
      id,
      type: "compliance-docs",
      recordId,
      data: formData,
      token: tokenValue,
    });
    if (uploadImage && uploadImage.type === "success") {
      const complianceDetails = await getDocs({
        id,
        type: "compliance-docs",
        token: tokenValue,
      });
      if (complianceDetails && complianceDetails.type === "success") {
        toast.success("Record Item Added Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setComplianceDocsData(complianceDetails.data);
        setLoadingUpload(false);
        return true;
      } else if (complianceDetails && complianceDetails.type === "error") {
        toast.error("Error while adding Record Item", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingUpload(false);

        return false;
      }
    } else if (uploadImage && uploadImage.type === "error") {
      toast.error("Error while adding Record Item", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpload(false);
      return false;
    }
  };

  const handleRename = async ({ itemId, recordId, fileName }: fileRename) => {
    setLoadingUpload(true);

    const renameFile = await renameRecordItem({
      id,
      type: "compliance-docs",
      recordId,
      itemId,
      fileName,
      token: tokenValue,
    });
    if (renameFile && renameFile.type === "success") {
      const complianceDetails = await getDocs({
        id,
        type: "compliance-docs",
        token: tokenValue,
      });
      if (complianceDetails && complianceDetails.type === "success") {
        toast.success("Record Item Renamed Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setComplianceDocsData(complianceDetails.data);
        setLoadingUpload(false);
        return true;
      } else if (complianceDetails && complianceDetails.type === "error") {
        toast.error("Error while renaming Record Item", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingUpload(false);

        return false;
      }
    } else if (renameFile && renameFile.type === "error") {
      toast.error("Error while renaming Record Item", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpload(false);
      return false;
    }
  };

  const handleDelete = async ({ itemId, recordId }: fileDownload) => {
    setLoadingUpload(true);

    const deleteFile = await deleteRecordItem({
      id,
      type: "compliance-docs",
      recordId,
      itemId,
      token: tokenValue,
    });
    if (deleteFile && deleteFile.type === "success") {
      const complianceDetails = await getDocs({
        id,
        type: "compliance-docs",
        token: tokenValue,
      });
      if (complianceDetails && complianceDetails.type === "success") {
        toast.success("Record Item Deleted Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setComplianceDocsData(complianceDetails.data);
        setLoadingUpload(false);
        return true;
      } else if (complianceDetails && complianceDetails.type === "error") {
        toast.error("Error while deleting Record Item", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingUpload(false);

        return false;
      }
    } else if (deleteFile && deleteFile.type === "error") {
      toast.error("Error while deleting File", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpload(false);
      return false;
    }
  };

  const handleDownload = async ({ itemId, recordId }: fileDownload) => {
    setLoadingUpload(true);

    const downloadFile = await downloadRecordItem({
      id,
      type: "compliance-docs",
      recordId,
      itemId,
      token: tokenValue,
    });
    if (downloadFile && downloadFile.type === "success") {
      const file = new Blob([downloadFile.data], {
        type: "application/octet-stream",
      });
      saveAs(file, `name.png`);
      toast.success("File Downloaded Successfully", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpload(false);
    } else if (downloadFile && downloadFile.type === "error") {
      toast.error("Error while downloading File", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingUpload(false);
      return false;
    }
  };

  const handleChecks = (it: any) => {
    if (checkRecords.filter((checking) => checking.id === it.id).length !== 0) {
      setCheckRecords(checkRecords.filter((item: any) => item.id !== it.id));
    } else {
      setCheckRecords([
        ...checkRecords,
        {
          id: it.id,
          name: it.kind,
        },
      ]);
    }
  };
  const handleEmptyView = () => {
    setShowEmptyCheck(!showEmptyCheck);
  };

  const handleDeleteMultiple = async () => {
    setDeleteLoading(true);
    const deleteFile = await deleteRecords({
      id,
      recordIds: checkRecords.map((it) => it.id),
      token: tokenValue,
    });
    if (deleteFile && deleteFile.type === "success") {
      const complianceDetails = await getDocs({
        id,
        type: "compliance-docs",
        token: tokenValue,
      });
      if (complianceDetails && complianceDetails.type === "success") {
        toast.success("Record Items Deleted Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCheckRecords([]);
        setComplianceDocsData(complianceDetails.data);
        setDeleteLoading(false);
        return true;
      } else if (complianceDetails && complianceDetails.type === "error") {
        toast.error("Error while deleting Record Items", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setDeleteLoading(false);
        return false;
      }
    } else if (deleteFile && deleteFile.type === "error") {
      toast.error("Error while deleting Record Items", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteLoading(false);
      return false;
    }
  };

  const handleShareMultiple = async () => {
    setShareLoading(true);

    const shareFile = await shareRecords({
      id,
      recordIds: checkRecords.map((it) => it.id),
      token: tokenValue,
    });
    if (shareFile && shareFile.type === "success") {
      toast.success("Share Link Copied Successfully", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShareLoading(false);

      navigator.clipboard.writeText(shareFile.data.shareLink);
      return true;
    } else if (shareFile && shareFile.type === "error") {
      toast.error("Error while deleting Sharing Items", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShareLoading(false);
      return false;
    }
  };

  const handleRemoveCheck = (id: string) => {
    setCheckRecords(checkRecords.filter((it) => it.id !== id));
  };

  const handleAddRowItem = async (
    name: string,
    type: string,
    category: string,
    path: string[]
  ) => {
    setAddLoading(true);

    const addRecordItem = await addRecord({
      id,
      kind: name,
      multipleUpload: type === "single-file" ? false : true,
      category,
      path,
      token: tokenValue,
    });
    if (addRecordItem && addRecordItem.type === "success") {
      const complianceDetails = await getDocs({
        id,
        type: "compliance-docs",
        token: tokenValue,
      });
      if (complianceDetails && complianceDetails.type === "success") {
        toast.success("Record Item Added Successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setComplianceDocsData(complianceDetails.data);
        setAddLoading(false);
        return true;
      } else if (complianceDetails && complianceDetails.type === "error") {
        toast.error("Error while adding Record Item", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setAddLoading(false);

        return false;
      }
    } else if (addRecordItem && addRecordItem.type === "error") {
      toast.error("Error while adding Record Item", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAddLoading(false);
      return false;
    }
  };

  if (!id || error === "Company not found. Please retry.") {
    return <>{loading ? <>Loading...</> : <Navigate to="/" replace />}</>;
  } else {
    return (
      <>
        {loading ? (
          <>Loading...</>
        ) : (
          <ComplianceUI
            {...{
              complianceDocsData,
              handleUploadRecordItem,
              loadingUpload,
              handleRename,
              handleDownload,
              handleDelete,
              checkRecords,
              handleChecks,
              showEmptyCheck,
              handleEmptyView,
              handleDeleteMultiple,
              handleShareMultiple,
              handleRemoveCheck,
              shareLoading,
              deleteLoading,
              handleAddRowItem,
              addLoading,
            }}
          />
        )}
      </>
    );
  }
};

export default Compliance;

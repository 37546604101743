import React, { useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Dialog, Button, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface Props {
  entity: any;
  changeLogo: boolean;
  setChangeLogo: any;
  handleRemoveLogo: () => void;
  logoState: string;
  setLogoState: any;
  handleUploadLogo: (imageFile: any) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const EntityLogo: React.FC<Props> = (props) => {
  const {
    entity,
    changeLogo,
    setChangeLogo,
    handleUploadLogo,
    handleRemoveLogo,
    logoState,
    setLogoState,
  } = props;

  const [imageFile, setImageFile] = useState<any>(null);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  return (
    <>
      <div
        className={`setting-entity-image ${!entity.logo && "show-logo-back"}`}
        onClick={() => {
          setChangeLogo(true);
        }}
      >
        {entity.logo ? (
          <img src={entity.logo} alt="" />
        ) : (
          <>{entity.entityName[0]}</>
        )}
        <div className="setting-entity-image-change">
          <CameraAltIcon />
        </div>
      </div>
      {/* DELETE DIALOG */}
      <Dialog TransitionComponent={Transition} open={changeLogo}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setChangeLogo(false);
                setLogoState("initial");
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {logoState === "initial" ? (
            <>
              <div className="dialog-heading">Change Logo</div>
              <div style={{ marginTop: "3.2rem" }}></div>
              <div className="dialog-buttons">
                <Button
                  className="dialog-buttons-contained"
                  variant="contained"
                  onClick={() => setLogoState("notInitial")}
                >
                  Upload New Logo
                </Button>
                <Button
                  className="dialog-buttons-outlined"
                  disabled={entity.logo ? false : true}
                  variant="outlined"
                  onClick={handleRemoveLogo}
                >
                  Remove Logo
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="dialog-heading">Change Logo</div>
              <div style={{ marginTop: "2.7rem" }}></div>
              <div className="dialog-file-upload">
                <input
                  type="file"
                  name="id_logo_upload"
                  id="id_logo_upload"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {imageFile ? (
                  <label htmlFor="id_logo_upload" className="upload_logo_label">
                    <CloudUploadIcon htmlColor="#1161d6" /> &nbsp;&nbsp;&nbsp;
                    {imageFile.name}
                  </label>
                ) : (
                  <label htmlFor="id_logo_upload" className="upload_logo_label">
                    <CloudUploadIcon htmlColor="#1161d6" />
                    &nbsp;&nbsp;&nbsp;Choose File
                  </label>
                )}
              </div>{" "}
              <div style={{ marginTop: "2.7rem" }}></div>
              <div className="dialog-buttons">
                <Button
                  className="dialog-buttons-contained"
                  variant="contained"
                  onClick={() => {
                    handleUploadLogo(imageFile);
                  }}
                >
                  Upload New Logo
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default EntityLogo;

import { modalInitialState } from "./modalInitial";
import { modalActions } from "./modalActions";
import { modalType } from "./modalTypes";

const modalReducer = (state = modalInitialState, action: modalType) => {
  switch (action.type) {
    case modalActions.SET_OPEN_TEMPLATE_NAME_MODAL:
      return {
        ...state,
        openTemplateNameModal: action.payload,
      };
     
    default:
      return state;
  }
};

export default modalReducer;

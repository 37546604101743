import axios from "axios";
import { Create, user } from ".";
import auth from "../../firebase/auth";

export const getUser = async () => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "get",
      url: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const createUser = async ({ firstName, lastName }: Create) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const upload = await axios({
      method: "post",
      url: user,
      data: {
        firstName,
        lastName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: upload.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const updateUser = async ({ firstName, lastName }: Create) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const upload = await axios({
      method: "patch",
      url: user,
      data: {
        firstName,
        lastName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: upload.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

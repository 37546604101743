import axios from "axios";
import auth from "../../firebase/auth";
import { apiFunctionResponse } from "../../Types/api.types";
import { toastIn } from "../../UILibrary/Toast/Toast";
import { Invoice } from "../../Types/invoice.types";
const baseApi = process.env.REACT_APP_BASE_URL;

export const postInvoiceTemplate: (
  companyId: string | null,
  templateName: string | null
) => Promise<apiFunctionResponse> = async (companyId, templateName) => {
  try {
    if (!companyId) throw new Error("Invalid campanyId!");
    if (!templateName) throw new Error("Template Name can't be empty!");
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "post",
      url: `${baseApi}/invoice/${companyId}/templates`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        templateName,
      },
    });
    console.log(response);
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

export const postInvoice: (
  companyId: string | null,
  invoiceNumber: string | null,
  templateId: string | null
) => Promise<apiFunctionResponse> = async (
  companyId,
  invoiceNumber,
  templateId
) => {
    try {
      if (!companyId) throw new Error("Invalid campanyId!");
      if (!invoiceNumber) throw new Error("Invoice can't be empty!");
      if (!templateId) throw new Error("TemplateId can't be empty!");

      const token: string | undefined = await auth.currentUser?.getIdToken();
      if (!token) throw new Error("Authentication token not found");

      const response = await axios({
        method: "post",
        url: `${baseApi}/invoice/${companyId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          templateId,
          invoiceNumber,
        },
      });
      console.log(response);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  };

export const deleteInvoice: (
  companyId: string | null,
  invoiceId: string | null
) => Promise<apiFunctionResponse> = async (
  companyId,
  invoiceId,
) => {
    try {
      if (!companyId) throw new Error("Invalid campanyId!");
      if (!invoiceId) throw new Error("InvoiceId can't be empty!");

      const token: string | undefined = await auth.currentUser?.getIdToken();
      if (!token) throw new Error("Authentication token not found");

      const response = await axios({
        method: "delete",
        url: `${baseApi}/invoice/${companyId}/single/${invoiceId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      console.log(response);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  };

export const patchInvoiceTemplate: (
  companyId: string | null,
  templateId: string | null,
  data: Partial<Invoice & { id: string; templateId: string | null }>
) => Promise<apiFunctionResponse> = async (companyId, templateId, data) => {
  try {
  
    if (!companyId) throw new Error("Invalid campanyId!");
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) throw new Error("Authentication token not found");

    const modifiedData = {
      ...data,
      ...(data?.termsAndConditions && {
        termsAndConditions: (data?.termsAndConditions as string[]).join("\n"),
      }),
    };

    delete modifiedData.attachments;
    delete modifiedData.signature;
    delete modifiedData.vendor;
    delete modifiedData.client;
    delete modifiedData.id;
    delete modifiedData.companyId;
    delete modifiedData.isDeleted;
    delete modifiedData.createdAt;
    delete modifiedData.updatedAt;

    const response = await axios({
      method: "patch",
      url: `${baseApi}/invoice/${companyId}/templates/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: modifiedData,
    });
    return {
      ...response.data,
      ...(response?.data?.termsAndConditions && {
        termsAndConditions: [
          ...(response.data.termsAndConditions.split("\n") || []),
        ],
      }),
    };
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceTemplates: (
  companyId: string | null
) => Promise<apiFunctionResponse> = async companyId => {
  try {
    if (!companyId) throw new Error("Invalid companyId");
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/templates`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceTemplateById: (
  companyId: string | null,
  templateId: string | null
) => Promise<apiFunctionResponse> = async (companyId, templateId) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    if (!companyId || !templateId)
      throw new Error("Parameters not passed correctly");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/templates/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getInvoiceTemplateById -> ", response);
    return {
      ...response.data,
      ...(response?.data?.termsAndConditions && {
        termsAndConditions: [
          ...(response.data.termsAndConditions.split("\n") || []),
        ],
      }),
    };
  } catch (error: any) {
    throw error;
  }
};

export const deleteInvoiceTemplateById: (
  companyId: string | null,
  templateId: string | null
) => Promise<apiFunctionResponse> = async (companyId, templateId) => {
  try {
    if (!companyId || !templateId)
      throw new Error("Parameters not passed correctly");
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "delete",
      url: `${baseApi}/invoice/${companyId}/templates/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("deleteInvoiceTemplateById -> ", response.data);
    // return {
    //   ...response.data,
    //   ...(response?.data?.termsAndConditions && {
    //     termsAndConditions: [
    //       ...(response.data.termsAndConditions.split("\n") || []),
    //     ],
    //   }),
    // };
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceList: (
  companyId: string
) => Promise<apiFunctionResponse> = async companyId => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    console.log(token);
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/all`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response: getInvoiceList -> ", response);

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceById = async (invoiceId: string, companyId: string) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/single/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      ...response.data,
      ...(response?.data?.termsAndConditions && {
        termsAndConditions: response.data.termsAndConditions.split("\n"),
      }),
    };
  } catch (error: any) {
    throw error;
  }
};

export const getBusinesses: (
  companyId: string | null
) => Promise<apiFunctionResponse> = async companyId => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/businesses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getClients: (
  companyId: string | null
) => Promise<apiFunctionResponse> = async companyId => {
  if (!companyId) return toastIn.error("Company Id not found");
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: `${baseApi}/invoice/${companyId}/clients`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getClientById: (
  companyId: string | null,
  clientId: string | null
) => Promise<apiFunctionResponse> = async (companyId, clientId) => {
  try {
    if (!companyId || !clientId)
      throw new Error("Parameters not passed correctly!");

    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    // const response = await axios({
    //   method: "get",
    //   url: `${baseApi}/invoice/${companyId}/clients/${clientId}`,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    // return response.data;
    return {}
  } catch (error: any) {
    throw error;
  }
};

export const postBusiness: (
  companyId: string | null,
  businessDetails: any
) => Promise<apiFunctionResponse> = async (companyId, businessDetails) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "post",
      url: `${baseApi}/invoice/${companyId}/businesses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: businessDetails,
    });

    console.log("POST BUSINESS", response);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const patchBusiness: (
  companyId: string | null,
  businessDetails: any
) => Promise<apiFunctionResponse> = async (companyId, businessDetails) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "patch",
      url: `${baseApi}/invoice/${companyId}/businesses/${businessDetails.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: businessDetails,
    });

    console.log("Put BUSINESS", response);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const postClient: (
  companyId: string | null,
  clientDetails: any
) => Promise<apiFunctionResponse> = async (companyId, clientDetails) => {
  if (!companyId) return toastIn.error("Company Id not found");
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "post",
      url: `${baseApi}/invoice/${companyId}/clients`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: clientDetails,
    });

    console.log("POST client", response);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const patchClient: (
  companyId: string | null,
  clientDetails: any
) => Promise<apiFunctionResponse> = async (companyId, clientDetails) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId || !clientDetails?.id) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "patch",
      url: `${baseApi}/invoice/${companyId}/clients/${clientDetails.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: clientDetails,
    });

    console.log("PUT client", response);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const deleteClient: (
  companyId: string | null,
  clientDetails: any
) => Promise<apiFunctionResponse> = async (companyId, clientId) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!companyId || !clientId) throw new Error("Parameters not passed correctly!");
    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "delete",
      url: `${baseApi}/invoice/${companyId}/clients/${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("Delete client", response);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const uploadFile = async (
  data: any,
  path: string
) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) throw new Error("Authentication token not found");
    const uploadImage = await axios({
      method: "post",
      url: `${baseApi}/${path}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });


    return {
      type: "success",
      message: "Logo Uploaded",
      data: uploadImage?.data
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

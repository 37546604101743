// @flow
import { Typography } from "@mui/material";
import * as React from "react";
type Props = {
  title: React.ReactElement | string;
  value: React.ReactElement | string | number;
  totalWidth?: number | string;
  titleWidth?: number | string;
  titleWeight?: number | string;
  valueWeight?: number | string;
  titleFontSize?: number | string;
  titleColor?: string;
  valueColor?: string;
  valueFontSize?: number | string;
  titleAlign?: "center" | "left" | "right";
  valueAlign?: "center" | "left" | "right";
  direction?: "row" | "column";
  alignItems?: string;
  justifyContent?: string;
  className?: string;
  marginBottom?: string;
};

export const KeyValue = ({
  title,
  value,
  totalWidth,
  titleWeight,
  valueWeight,
  titleAlign,
  valueAlign,
  titleFontSize,
  valueFontSize,
  titleColor,
  valueColor,
  direction,
  className,
  titleWidth,
  alignItems,
  justifyContent,
  marginBottom,
}: Props) => {
  return (
    <div
      style={{
        marginBottom: marginBottom || "1rem",
        width: totalWidth,
        display: "flex",
        flexDirection: direction || "row",
        alignItems: alignItems || "center",
        justifyContent: justifyContent || "space-between",
      }}
      className={className}
    >
      <div style={{ textAlign: titleAlign || "left" }}>
        <Typography
          fontWeight={titleWeight}
          fontSize={titleFontSize || "14px"}
          variant="body2"
          color={titleColor || "rgba(0, 0, 0, 0.5)"}
          width={titleWidth}
        >
          {title}
        </Typography>
      </div>
      <div style={{ flexGrow: 100 }}>
        <Typography
          variant="body2"
          fontWeight={valueWeight}
          fontSize={valueFontSize || "14px"}
          style={{ textAlign: valueAlign || "left" }}
          color={valueColor || "Black"}
        >
          {value}
        </Typography>
      </div>
    </div>
  );
};

import axios from "axios";
import auth from "../../firebase/auth";
import { Props, documentGen } from ".";

export const generateTemplate = async ({ id, tempCode }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "get",
      url: documentGen + "/" + id + "/template/" + tempCode,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const customTemplateGeneration = async ({ id, data }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: documentGen + "/" + id + "/custom-request",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const generateDocument = async ({ id, docCode, type, data }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: documentGen + "/" + id + "/" + docCode + "/" + type,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

// @flow
import { Col, Image, Modal, Row, Space, Upload, UploadFile } from "antd";
import ImgCrop from "antd-img-crop";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import * as React from "react";
import "./BusinessModal.scss";

import { Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import {
  patchClient,
  postBusiness,
  postClient,
  patchBusiness,
  uploadFile,
} from "../../api/invoice/invoice.api";
import { toastIn } from "../../UILibrary/Toast/Toast";
import { BusinessModalState } from "../../Types/global.types";
import { taxes } from "../../components/Invoice/taxConfig";
import { Business } from "../../Types/invoice.types";
import { TextButton } from "../../UILibrary/Buttons/TextButton/TextButton";
type Props = {
  businessModalState: BusinessModalState;
  handleCancel: () => void;
  companyId: string | null;
  logo: any;
  onLogoChange: Function;
  onPreview: (file: UploadFile<any>) => void;
};
export const BusinessModal = ({
  businessModalState,
  handleCancel,
  companyId,
  logo,
  onLogoChange,
  onPreview,
}: Props) => {
  const queryClient = useQueryClient();
  const [businessDetails, setBusinessDetails] = React.useState(
    businessModalState?.businessDetails || {
      ...(businessModalState.type === "CLIENT" && { type: "Business" }),
    }
  );
  const [uploadingLogo, setUploadingLogo] = React.useState(false);
  const [taxInformation, setTaxInformation] = React.useState(
    businessModalState?.businessDetails?.taxInformation || { taxScheme: "IGST" }
  );

  const handleUploadLogo = async (data: Business) => {
    if (logo.length > 0) {
      setUploadingLogo(true);
      const formData = new FormData();
      formData.append("business-logo", logo[0].originFileObj);
      await uploadFile(
        formData,
        `invoice/${companyId}/businesses/${data?.id}/upload-logo`
      );

      setUploadingLogo(false);
    }
  };

  const handleInvalidateAndCancel = (key: string) => {
    queryClient.invalidateQueries({
      queryKey: [companyId, key],
    });
    handleCancel();
  };

  const { mutate: mutatePostBusiness, isLoading: isMutateBusinessLoading } =
    useMutation({
      mutationFn: details => postBusiness(companyId, details),
      onSuccess: async data => {
        await handleUploadLogo(data);
        handleInvalidateAndCancel("billedByBusinesses");
      },
      onError: (error: Error) => {
        toastIn.error(error.message);
      },
    });
  const { mutate: mutatePutBusiness, isLoading: isMutatePutBusinessLoading } =
    useMutation({
      mutationFn: details => patchBusiness(companyId, details),
      onSuccess: async data => {
        await handleUploadLogo(data);
        handleInvalidateAndCancel("billedByBusinesses");
      },
      onError: (error: Error) => {
        toastIn.error(error.message);
      },
    });
  const { mutate: mutatePatchClient, isLoading: isMutatePatchClientLoading } =
    useMutation({
      mutationFn: details => patchClient(companyId, details),
      onSuccess: () => handleInvalidateAndCancel("billedToBusinesses"),
      onError: (error: Error) => {
        toastIn.error(error.message);
      },
    });

  const { mutate: mutatePostClient, isLoading: isMutateClientLoading } =
    useMutation({
      mutationFn: details => postClient(companyId, details),
      onSuccess: async () => handleInvalidateAndCancel("billedToBusinesses"),
      onError: (error: Error) => {
        toastIn.error(error.message);
      },
    });
  const onChange = (
    ev: any,
    key?: string | Number | Symbol | any,
    value?: string | Number | Symbol | any
  ) =>
    setBusinessDetails((details: any) => ({
      ...details,
      [key ?? ev?.target?.name]: value ?? ev?.target?.value,
    }));
  const onTaxInformationChange = (
    ev: any,
    key?: string | Number | Symbol | any,
    value?: string | Number | Symbol | any
  ) =>
    setTaxInformation((details: any) => ({
      ...details,
      [key ?? ev?.target?.name]: value ?? ev?.target?.value,
    }));

  const handleOk = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      if (businessModalState.type === "BUSINESS") {
        if (businessModalState?.mode === "ADD") {
          mutatePostBusiness({ ...businessDetails, taxInformation });
        } else {
          mutatePutBusiness({ ...businessDetails, taxInformation });
        }
      } else if (businessModalState.type === "CLIENT") {
        if (businessModalState?.mode === "ADD") {
          mutatePostClient({ ...businessDetails, taxInformation });
        } else {
          mutatePatchClient({ ...businessDetails, taxInformation });
        }
      }
    } catch (error) { }
  };


  return (
    <Modal
      title={<Typography variant="h6">{businessModalState.title}</Typography>}
      open={businessModalState.open}
      onOk={handleOk}
      confirmLoading={
        isMutateBusinessLoading || isMutateClientLoading || uploadingLogo
      }
      onCancel={handleCancel}
      className="business_modal"
      width={800}
    >
      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Basic Details:</p>
        </Col>

        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="name"
            id="name"
            placeholder="Business name"
            value={businessDetails?.name}
          />
        </Col>
        <Col span={12}>
          <Row justify={"end"}>
            {!businessDetails?.logoUrl &&
              <ImgCrop rotationSlider>
                <Upload
                  beforeUpload={() => false}
                  listType="picture-card"
                  fileList={logo}
                  maxCount={1}
                  multiple={false}
                  onChange={({ fileList }) => onLogoChange(fileList)}
                  onPreview={onPreview}
                  className="logo_upload"
                >
                  {logo.length === 0 && (
                    <Space
                      size={10}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <AddPhotoAlternateIcon color="inherit" />
                      <span>Add Business Logo</span>
                    </Space>
                  )}
                </Upload>
              </ImgCrop>
            }

            {businessDetails?.logoUrl &&
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Image
                  width={100}
                  height={100}
                  src={businessDetails?.logoUrl}
                />
                <TextButton active style={{ display: "block" }} onClick={() => setBusinessDetails(prev => ({ ...prev, logoUrl: "" }))}>Remove</TextButton>
              </div>
            }
          </Row>

        </Col>
        {businessModalState?.type === "CLIENT" && (
          <Col span={12}>
            <select
              className="input"
              name="type"
              id="type"
              onChange={onChange}
              placeholder="Type of Client"
              value={businessDetails?.type}
            >
              <option value={"Business"}>Business</option>
              <option value={"Consumer"}>Consumer</option>
            </select>
          </Col>
        )}
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Address:</p>
        </Col>

        <Col span={24}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="streetAddress"
            id="streetAddress"
            placeholder="Street Address"
            value={businessDetails?.street}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="postalCode"
            id="postalCode"
            placeholder="Postal Code / Zip Code"
            value={businessDetails?.postalCode}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="city"
            id="city"
            placeholder="City"
            value={businessDetails?.city}
          />
        </Col>

        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="state"
            id="state"
            placeholder="State"
            value={businessDetails?.state}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="country"
            id="country"
            placeholder="Country"
            value={businessDetails?.country}
          />
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Tax information (Optional):</p>
        </Col>

        <Col span={12}>
          <select
            className="input"
            name="taxScheme"
            id="taxScheme"
            onChange={onTaxInformationChange}
            placeholder="Tax Scheme"
            value={taxInformation?.taxScheme}
          >
            {taxes?.map((tax: any) => (
              <option value={tax.id}>{tax.label}</option>
            ))}
          </select>
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onTaxInformationChange}
            type="text"
            name="taxId"
            id="taxId"
            placeholder="Business Tax Id"
            value={taxInformation?.taxId}
          />
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="form-row">
        <Col span={24}>
          <p className="form-subheading">Contact (Optional):</p>
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            value={businessDetails?.email}
          />
        </Col>
        <Col span={12}>
          <input
            className="input"
            onChange={onChange}
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Phone"
            value={businessDetails?.phoneNumber}
          />
        </Col>

        {/* <Col span={12}>
          <div className="checkbox-input">
            <input
              className="input checkbox"
              type="checkbox"
              name="addEmailToInvoice"
              id="addEmailToInvoice"
              placeholder="addEmailToInvoice"
              defaultChecked={false}
              value={businessDetails?.addEmailToInvoice}
              onChange={e => {
                setBusinessDetails((prev: any) => ({
                  ...prev,
                  addEmailToInvoice: !prev?.addEmailToInvoice,
                }));
              }}
            />
            Show Email in Invoice
          </div>
        </Col>
        <Col span={12}>
          <div className="checkbox-input">
            <input
              className="input checkbox"
              type="checkbox"
              name="addPhoneNumberToInvoice"
              id="addPhoneNumberToInvoice"
              defaultChecked={false}
              value={businessDetails?.addPhoneNumberToInvoice}
              onChange={e => {
                setBusinessDetails((prev: any) => ({
                  ...prev,
                  addPhoneNumberToInvoice: !prev?.addPhoneNumberToInvoice,
                }));
              }}
            />
            Show Phone in Invoice
          </div>
        </Col> */}
      </Row>
    </Modal>
  );
};

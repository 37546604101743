import React from "react";
import { Companies, Data, Props } from "./type";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const CompanyListUI: React.FC<Props> = (props) => {
  const {
    data,
    handleAdd,
    handleCompanyClick,
    handleAccpetInvite,
    handleLogOut,
  } = props;
  return (
    <div className="company-list">
      <div className="company-list-welcome">{data.welcomeText}</div>
      <div className="company-list-companies">
        <div className="company-list-companies-details">
          <div
            className="company-list-companies-details-item company-list-companies-details-add"
            onClick={handleAdd}
          >
            <div className="company-list-companies-details-add-logoadd">
              <AddCircleOutlineIcon
                sx={{ fontSize: "4.6rem" }}
                htmlColor="#b5bbc5"
              />
            </div>
            <div className="company-list-companies-details-add-message">
              Create New
            </div>
          </div>
          {data.companies.map((item: Companies) => {
            return (
              <div
                className="company-list-companies-details-item"
                key={item.id}
                onClick={() =>
                  item.userInviteStatus === "PENDING"
                    ? handleAccpetInvite(
                        item.id,
                        item.name,
                        item.creatorFirstName,
                        item.creatorlastName
                      )
                    : handleCompanyClick(item.id)
                }
              >
                <div
                  className={`company-list-companies-details-item-logo ${
                    !item.logoUrl && "add-home-back"
                  }`}
                >
                  {item.logoUrl ? (
                    <img src={item.logoUrl} alt="" />
                  ) : (
                    <>
                      <div
                        className={`company-list-companies-details-item-logo-image `}
                      >
                        {item.name[0]}
                      </div>
                    </>
                  )}
                </div>
                <div className="company-list-companies-details-item-logo-name">
                  {item.name}
                </div>
                {item.userInviteStatus === "PENDING" && (
                  <div className="company-list-companies-details-item-pending">
                    You have been Invited
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="logout">
        Not seeing your entity?{" "}
        <span onClick={handleLogOut}>
          Try using a different different email address
        </span>
      </div>
    </div>
  );
};

export default CompanyListUI;

// @flow

import { Typography } from "@mui/material";
import * as React from "react";
import { KeyValue } from "../../../../UILibrary/KeyValue/KeyValue";
import { Divider, Input, Radio, Space } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootReducer } from "../../../../redux/rootReducer";
import { handleInvoiceKeyValueChange } from "../../../../redux/Invoice/invoiceActionCreator";
import { Invoice } from "../../../../Types/invoice.types";
type Props = {
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
};
export const RecurringInvoice = ({ handleInvoiceChangeOnBlur }: Props) => {
  const dispatch = useDispatch();
  const { isRecurring, nextRepeatDate, recurringFrequency, repeatDate } =
    useSelector((state: RootReducer) => state?.invoice);

  return (
    <div className="new_invoice_recurring_invoice">
      <div>
        <input
          className="input checkbox"
          type="checkbox"
          name="isRecurring"
          id="isRecurring"
          placeholder="isRecurring"
          checked={isRecurring}
          onChange={e => {
            dispatch(
              handleInvoiceKeyValueChange(e, "isRecurring", !isRecurring)
            );
          }}
          onBlur={() =>
            handleInvoiceChangeOnBlur({ isRecurring: isRecurring || false })
          }
        />
        This is a recurring invoice.
      </div>
      <Typography variant="subtitle2" color={"GrayText"}>
        A draft invoice will be created with same details every next period.
      </Typography>

      <Divider />

      {isRecurring && (
        <div className="new_invoice_recurring_invoice_setting">
          <div className="new_invoice_recurring_invoice_setting_item">
            <KeyValue
              title={"Recurring Frequency"}
              titleFontSize={"1rem"}
              value={
                <select
                  className="input select outlined"
                  name="recurringFrequency"
                  id="recurringFrequency"
                  value={recurringFrequency}
                  onChange={e => {
                    dispatch(handleInvoiceKeyValueChange(e));
                  }}
                  onBlur={() =>
                    handleInvoiceChangeOnBlur({
                      recurringFrequency: recurringFrequency || "",
                    })
                  }
                >
                  <option value="DAILY">Dayly</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                  <option value="QUARTERLY">Quarterly</option>
                  <option value="HALF_YEARLY">Half Yearly</option>
                  <option value="YEARLY">Yearly</option>
                </select>
              }
              titleWidth={"12rem"}
            />
          </div>
          {["MONTHLY", "QUARTERLY", "HALF_YEARLY", "YEARLY"].includes(
            recurringFrequency || ""
          ) && (
            <div className="new_invoice_recurring_invoice_setting_item">
              <KeyValue
                title={"Repeat Date"}
                titleFontSize={"1rem"}
                value={
                  <input
                    className="input outlined"
                    type="date"
                    name="repeatDate"
                    id="repeatDate"
                    value={repeatDate}
                    onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
                    onBlur={() =>
                      handleInvoiceChangeOnBlur({
                        repeatDate: repeatDate || "",
                      })
                    }
                  />
                }
                titleWidth={"12rem"}
              />
            </div>
          )}
          <div className="new_invoice_recurring_invoice_setting_item">
            <KeyValue
              title={"Next Repeat Date"}
              titleFontSize={"1rem"}
              value={
                <input
                  className="input outlined"
                  type="date"
                  name="nextRepeatDate"
                  id="nextRepeatDate"
                  value={nextRepeatDate}
                  onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
                  onBlur={() =>
                    handleInvoiceChangeOnBlur({
                      nextRepeatDate: nextRepeatDate || "",
                    })
                  }
                />
              }
              titleWidth={"12rem"}
            />
          </div>
          <Divider />
        </div>
      )}
    </div>
  );
};

import React, { ReactElement } from "react";

import "./styles.scss";

interface Props {
  children: ReactElement;
}

const AuthContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="auth">
      <div className="auth-top">
        <img src="./images/auth-top.svg" alt="Top" />
      </div>
      <div className="auth-main">{children}</div>
      <div className="auth-bottom">
        <div className="auth-bottom-item">
          <div className="auth-bottom-item-icon">
            <img src="./images/add.svg" alt="" />
          </div>
          <div className="auth-bottom-item-type">
            <div className="auth-bottom-item-type-head">
              Document Generation
            </div>
            <div className="auth-bottom-item-type-body">
              Enter details to generate an automatically edited document ready
              to download and share
            </div>
          </div>
        </div>
        <div className="auth-bottom-item">
          <div className="auth-bottom-item-icon">
            <img src="./images/doc.svg" alt="" />
          </div>
          <div className="auth-bottom-item-type">
            <div className="auth-bottom-item-type-head">
              Document Management
            </div>
            <div className="auth-bottom-item-type-body">
              Store all the important documents of your business at a single
              place
            </div>
          </div>
        </div>
        <div className="auth-bottom-item">
          <div className="auth-bottom-item-icon">
            <img src="./images/share.svg" alt="" />
          </div>
          <div className="auth-bottom-item-type">
            <div className="auth-bottom-item-type-head">Document Sharing </div>
            <div className="auth-bottom-item-type-body">
              Share custom made zip with businesses, stakeholders or anyone else
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;

import "./_appContentCard.scss";

import * as React from "react";
type Props = {
  children: React.ReactNode;
  style?: any;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
};
export const AppContentCard = ({ style, children, className, ref }: Props) => {
  return (
    <div
      className={`app-content-card ${className || ""}`}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  );
};

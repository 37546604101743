import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Popover, Form, Input } from "antd";
import { Dialog, Button, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
// import { signOut } from "../../../../../redux/auth/authActionCreator";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { toast } from "react-toastify";
import auth from "../../../firebase/auth";
import { getUser, updateUser } from "../../../api/users/user";
import { createUser } from "../../auth/states/user/actions/userApiActionCreator";
import { signOut } from "../../auth/states/auth/actions/authActionCreator";
const Person = require("../assets/images/person.png");
const Help = require("../assets/images/help.png");
const Switch = require("../assets/images/switch.png");
const Logout = require("../assets/images/logout.png");
const Gmail = require("../assets/images/gmail.png");
const Outlook = require("../assets/images/outlook.png");

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface userInput {
  firstName: string;
  lastName: string;
}

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const state: any = useSelector((state) => state);
  const user = state.user.userDetails;
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [personOpen, setPersonOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [logOutOpen, setLogOutOpen] = useState<boolean>(false);
  const [userInputs, setUserInputs] = useState<userInput>({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  const handleDetails = (name: string, e: any) => {
    setUserInputs({
      ...userInputs,
      [name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    try {
      const { firstName, lastName } = userInputs;
      const submitUser = await updateUser({ firstName, lastName });
      if (submitUser.type === "success") {
        const getUserDet = await getUser();
        if (getUserDet && getUserDet.type === "success") {
          dispatch(createUser(getUserDet.data));
          setPersonOpen(false);
        }
      } else {
        toast.error(
          "Error occured while creating your accounts. Please try again.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      toast.error(
        "Error occured while creating your account. Please try again.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };
  return (
    <>
      <Popover
        trigger="click"
        placement="bottomRight"
        visible={show}
        onVisibleChange={() => setShow(false)}
        content={
          <div className="profile-popup">
            <div className="profile-popup-userdetails">
              <div className="profile-popup-userdetails-logo">
                {user.firstName[0]}
                {user.lastName[0]}
              </div>
              <div className="profile-popup-userdetails-details">
                <div className="profile-popup-userdetails-details-name">
                  {user.firstName}&nbsp;{user.lastName}
                </div>
                <div className="profile-popup-userdetails-details-email">
                  {user.email}
                </div>
              </div>
            </div>
            <div className="profile-popup-links">
              <div
                className="profile-popup-links-item"
                onClick={() => {
                  setShow(false);
                  setPersonOpen(true);
                }}
              >
                <div className="profile-popup-links-item-img">
                  <img src={Person} alt="person" />
                </div>
                <div className="profile-popup-links-item-name">Profile</div>
              </div>
              <div
                className="profile-popup-links-item"
                onClick={() => {
                  setShow(false);
                  setHelpOpen(true);
                }}
              >
                <div className="profile-popup-links-item-img">
                  <img src={Help} alt="person" />
                </div>
                <div className="profile-popup-links-item-name">Get Help</div>
              </div>
              <div
                className="profile-popup-links-item"
                onClick={() => {
                  navigate("/");
                }}
              >
                <div className="profile-popup-links-item-img">
                  <img src={Switch} alt="person" />
                </div>
                <div className="profile-popup-links-item-name">
                  Switch Entity
                </div>
              </div>
              <div
                className="profile-popup-links-item"
                onClick={() => {
                  setShow(false);
                  setLogOutOpen(true);
                }}
              >
                <div className="profile-popup-links-item-img">
                  <img src={Logout} alt="person" />
                </div>
                <div className="profile-popup-links-item-name">Logout</div>
              </div>
            </div>
          </div>
        }
      >
        <Button
          variant="contained"
          className="profile-icon"
          onClick={() => setShow(true)}
        >
          {user.firstName[0]}
          {user.lastName[0]}
        </Button>
      </Popover>
      {/* --------------------- */}
      {/* LOG OUT DIALOG */}
      <Dialog TransitionComponent={Transition} open={logOutOpen}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setLogOutOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Log out</div>
          <div className="dialog-content">
            Are you sure you want to log out of Cashwise?
          </div>
          <div className="dialog-buttons">
            <Button
              className="dialog-buttons-contained"
              variant="contained"
              onClick={() => {
                auth.signOut();
                dispatch(signOut());
                toast.success("Logo Added Successfully", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setLogOutOpen(false);
              }}
            >
              Log Out
            </Button>
            <Button
              className="dialog-buttons-outlined"
              variant="outlined"
              onClick={() => {
                setLogOutOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      {/* ----------------------- */}
      {/* HELP DIALOG */}
      <Dialog TransitionComponent={Transition} open={helpOpen}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setHelpOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Get Help</div>
          <div className="dialog-content">
            Send your query to the team for quick resolution at
            support@cashwise.in
          </div>
          <div className="dialog-buttons">
            <Button
              className="sent-buttons-mail"
              variant="outlined"
              target="_blank"
              href="https://mail.google.com/mail/"
            >
              <img src={Gmail} alt="gmail" />
              Open Gmail
            </Button>
            <Button
              className="sent-buttons-outlook"
              variant="outlined"
              target="_blank"
              href="https://outlook.office.com/mail/"
            >
              <img src={Outlook} alt="outlook" />
              Open Outlook
            </Button>
          </div>
        </div>
      </Dialog>
      {/* ----------------------- */}
      {/* PERSON DIALOG */}
      <Dialog TransitionComponent={Transition} open={personOpen}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                setPersonOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Edit Your Name</div>
          <Form className="register-form" onFinish={handleSubmit}>
            <Form.Item
              className="register-form-item"
              name="firstName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter your first name",
                },
              ]}
              initialValue={userInputs.firstName}
            >
              <Input
                className="register-form-input-name"
                placeholder="First Name"
                onChange={(e) => handleDetails("firstName", e)}
              />
            </Form.Item>
            <Form.Item
              className="register-form-item"
              name="lastName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please enter your last name",
                },
              ]}
              initialValue={userInputs.lastName}
            >
              <Input
                className="register-form-input-name"
                placeholder="Last Name"
                onChange={(e) => handleDetails("lastName", e)}
              />
            </Form.Item>
            <div className="register-form-submit">
              <Button
                className={`register-form-submit-button ${
                  (user.firstName !== userInputs.firstName ||
                    user.lastName !== userInputs.lastName) &&
                  "register-success"
                }`}
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  user.firstName === userInputs.firstName &&
                  user.lastName === userInputs.lastName
                    ? true
                    : false
                }
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Dialog>
    </>
  );
};

export default Profile;

import { Input } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";

const HeadingCompliance: React.FC = () => {
  return (
    <div className="compliance-docs-heading">
      <div className="compliance-docs-heading-title">Filing Documents</div>
    </div>
  );
};

export default HeadingCompliance;

// @flow
import * as React from "react";
import { NewInvoiceUI } from "./NewInvoiceUI";
import type { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/es/upload/interface";

import { useDispatch } from "react-redux";

import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { setInvoiceDetails } from "../../../redux/Invoice/invoiceActionCreator";
import { BusinessModal } from "../../../Modals/BusinessModal/BusinessModal";
import {
  getBusinesses,
  getClients,
  getInvoiceTemplateById,
  patchInvoiceTemplate,
  uploadFile,
} from "../../../api/invoice/invoice.api";
import { BusinessModalState } from "../../../Types/global.types";
import { RootReducer } from "../../../redux/rootReducer";

import { toastIn } from "../../../UILibrary/Toast/Toast";
import { Invoice } from "../../../Types/invoice.types";
import { InvoiceNumberModal } from "../../../Modals/InvoiceNumberModal/InvoiceNumberModal";
import { setOpenTemplateNameModal } from "../../../redux/Modals/modalActionCreator";
import { setLoadMessage } from "../../Loader/state/action/actionCreator";

type Props = {};
export const NewInvoice: React.FC = (props: Props) => {
  const dispatch = useDispatch();
  const invoiceDetails = useSelector((state: RootReducer) => state?.invoice);

  const [openInvoiceNumberModal, setOpenInvoiceNumberModal] =
    React.useState(false);
  const [showShippingDetails, setShowShippingDetails] =
    React.useState<boolean>(false);
  const [businessModalState, setBusinessModalState] =
    React.useState<BusinessModalState>({
      open: false,
      title: "",
      confirmLoading: false,
      type: "",
      mode: "",
    });


  const [logo, setLogo] = React.useState<UploadFile[]>([]);
  const [signature, setSignature] = React.useState<UploadFile[]>([]);

  const [searchparams] = useSearchParams();
  const id: string | null = searchparams.get("id");
  // const mode: string | null = searchparams.get("mode");
  const templateId: string | null = searchparams.get("template-id");

  const {
    mutate: mutatePatchInvoiceTemplate,
    isLoading: isMutatePatchInvoiceTemplateLoading,
  } = useMutation(
    [""],
    (payload: any): Promise<unknown> => {
      const { data } = payload
      return patchInvoiceTemplate(id, templateId, data)
    },
    {
      onSuccess: (_, { callback }) => {
        if (callback) callback();
      },
    }
  );

  const { isLoading, data: invoiceTemplate } = useQuery(
    [id, "invoiceTemplate", templateId],
    () => getInvoiceTemplateById(id, templateId)
  );

  const { isLoading: isBilledByLoading, data: billedByBusinesses } = useQuery(
    [id, "billedByBusinesses"],
    () => getBusinesses(id)
  );

  const { isLoading: isBilledToLoading, data: billedToBusinesses } = useQuery({
    queryKey: [id, "billedToBusinesses"],
    queryFn: () => getClients(id),
  });

  React.useEffect(() => {
    if (!templateId && id) {
      dispatch(setOpenTemplateNameModal({ key: true }))
    }

    if (isMutatePatchInvoiceTemplateLoading || isLoading || isBilledByLoading || isBilledToLoading) {
      dispatch(setLoadMessage("Loading Template.."))
    } else {
      dispatch(setLoadMessage(""))

    }

  }, [dispatch, id, isBilledByLoading, isBilledToLoading, isLoading, isMutatePatchInvoiceTemplateLoading, templateId])

  React.useEffect(() => {
    if (invoiceTemplate) {
      dispatch(
        setInvoiceDetails({
          ...invoiceTemplate,
          daysForPayment: invoiceTemplate?.daysForPayment || 0,
          clientId: invoiceTemplate?.clientId || billedToBusinesses?.[0]?.id,
          vendorId: invoiceTemplate?.vendorId || billedByBusinesses?.[0]?.id,
          taxScheme: invoiceTemplate?.taxScheme || "CGST & SGST",
          currency: invoiceTemplate?.currency || "INR",
          termsAndConditions: invoiceTemplate?.termsAndConditions || [""],
          lineItems: invoiceTemplate?.lineItems || [
            {
              itemName: "",
              hsnsac: "",
              taxRate: 0,
              quantity: 1,
              rate: 1,
              cgst: 0,
              sgst: 0,
              amount: 1,
              total: 1,
            },
          ],
          additionalCharges: invoiceTemplate?.additionalCharges || [],
          reductions: invoiceTemplate?.reductions || [],
        })
      );

    }
  }, [dispatch, invoiceTemplate, billedToBusinesses, billedByBusinesses]);



  const selectedBillByBusiness = React.useMemo(
    () =>
      invoiceDetails?.vendorId
        ? billedByBusinesses?.find(
          (each: any) => each.id === invoiceDetails?.vendorId
        )
        : undefined,
    [billedByBusinesses, invoiceDetails]
  );

  const selectedBillToBusiness = React.useMemo(
    () =>
      invoiceDetails?.clientId
        ? billedToBusinesses?.find(
          (each: any) => each.id === invoiceDetails?.clientId
        )
        : undefined,
    [billedToBusinesses, invoiceDetails]
  );



  const onSignatureChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    setSignature(newFileList);

    // loading: true
    try {
      const formData = new FormData();
      formData.append("signature", newFileList?.[0]?.originFileObj as RcFile);
      const uploadImageResponse = await uploadFile(
        formData,
        `invoice/${id}/templates/${templateId}/upload-signature`
      );
      // loading: false

      dispatch(setInvoiceDetails({ signature: uploadImageResponse?.data?.signature }))

    } catch (error) {
      toastIn.error("Error in uploading signature");

    }
  };


  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleCancel = () => {
    setBusinessModalState(prev => ({ ...prev, open: false }));
  };

  const handleInvoiceChangeOnBlur = (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => {
    if (isLoading) return
    mutatePatchInvoiceTemplate({ data, callback: undefined });
  };

  const handleSaveAndContinue = (data: any, callback: Function) => {
    if (isLoading) return
    mutatePatchInvoiceTemplate({ data, callback });
  };

  return (
    <>
      <NewInvoiceUI
        signature={signature}
        onSignatureChange={onSignatureChange}
        onPreview={onPreview}
        showShippingDetails={showShippingDetails}
        setShowShippingDetails={setShowShippingDetails}
        billedByBusinesses={billedByBusinesses}
        billedToBusinesses={billedToBusinesses}
        setBusinessModalState={setBusinessModalState}
        selectedBillByBusiness={selectedBillByBusiness}
        selectedBillToBusiness={selectedBillToBusiness}
        companyId={id}
        handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur}
        handleSaveAndContinue={handleSaveAndContinue}
        isMutatePatchInvoiceTemplateLoading={
          isMutatePatchInvoiceTemplateLoading
        }
        setOpenInvoiceNumberModal={setOpenInvoiceNumberModal}
        templateId={templateId}
        isLoading={isLoading}
      />
      {businessModalState.open && (
        <BusinessModal
          businessModalState={businessModalState}
          handleCancel={handleCancel}
          companyId={id}
          logo={logo}
          onLogoChange={setLogo}
          onPreview={onPreview}
        />
      )}
      {openInvoiceNumberModal && (
        <InvoiceNumberModal
          open={openInvoiceNumberModal}
          handleCancel={() => setOpenInvoiceNumberModal(false)}
          companyId={id}
          templateId={templateId}
        />
      )}

    </>
  );
};

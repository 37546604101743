import React, { useState, ReactElement } from "react";
import Button from "@mui/material/Button";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
// import { AccordianIn } from "../../../UILibrary/Accordian/Accordian";
import { PanelItem } from "./PanelItem/PanelItem";
import { AccordianIn } from "../../../UILibrary/Accordian/Accordian";
const projectBack = require("../../../images/logo.png");

interface Entity {
  entityName: string;
  website: string;
  logo: string | null;
}

interface Props {
  entity: Entity;
  onBackClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const SidePanel: React.FC<Props> = props => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const handleSettings = () => {
    navigate({
      pathname: "/dashboard/companies/settings",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };
  const { entity } = props;
  return (
    <div className="details-sidepanel">
      <div className="details-sidepanel-logo">
        <img src={projectBack} alt="" />
      </div>
      <div className="details-sidepanel-profile">
        <div className="person_details">
          <div className={`image ${!entity.logo ? "show-back" : "no-back"}`}>
            {entity.logo ? (
              <img src={entity.logo} alt="" />
            ) : (
              <>{entity.entityName[0]}</>
            )}
          </div>
          <div className="name_item">
            <div className="person_name">{entity.entityName}</div>
            {entity.website ? (
              <a href={entity.website} className="person_website-added">
                {entity.website}
              </a>
            ) : (
              <div className="person_website-none">No Website Added</div>
            )}
          </div>
        </div>
        <div className="modal_btn">
          <Button
            variant="text"
            color="success"
            className="open_dialog_button"
            onClick={handleSettings}
          >
            <SettingsOutlinedIcon />
          </Button>
        </div>
      </div>
      <div className="details-sidepanel-links">
        <PanelItem
          icon={<NoteAddOutlinedIcon />}
          title={"Document Generation"}
          navigateTo={{
            pathname: `/dashboard/documentGeneration`,
            search: createSearchParams({
              id,
            }).toString(),
          }}
        />

        <AccordianIn
          icon={<CloudDoneOutlinedIcon />}
          title={"Document Storage"}
        >
          <PanelItem
            title={"Corporate Documents"}
            navigateTo={{
              pathname: `/dashboard/formationDiligence`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />

          <PanelItem
            title={"Filing Documents"}
            navigateTo={{
              pathname: `/dashboard/compliance`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />
        </AccordianIn>

        {/* Invoice generation */}
        <AccordianIn
          icon={<ReceiptLongOutlinedIcon />}
          title={"Invoice Management"}
        >
          <PanelItem
            title={"Invoice Template"}
            navigateTo={{
              pathname: `/dashboard/template/new`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />
          <PanelItem
            title={"Invoice Summary"}
            navigateTo={{
              pathname: `/dashboard/invoice/summary`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />
          <PanelItem
            title={"Invoice Templates"}
            navigateTo={{
              pathname: `/dashboard/invoice/templates`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />

          <PanelItem
            title={"Client List"}
            navigateTo={{
              pathname: `/dashboard/invoice/clients`,
              search: createSearchParams({
                id,
              }).toString(),
            }}
          />
        </AccordianIn>
      </div>
    </div>
  );
};

export default SidePanel;

export const termsConditions = {
  name: "Terms and Conditions",
  docCodeType: "single",
  path: "nda",
  type: "Product ",
  tempCode: "terms-and-conditions",
  disclaimer:
    "Terms and Conditions are the legal agreements between a service provider and a party that wants to use it.",
  docCodeValues: [
    {
      docCode: "terms-and-conditions",
    },
  ],
  page: 1,
  pageHeading:
    "Enter below details to generate an automatically edited Terms and Conditions for your website!",
  fields: [
    {
      page: 1,
      name: "Date on which these Terms go up on the relevant website:",
      field: [
        {
          key: "effective_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "The website where these Terms will be posted:",
      field: [
        {
          key: "company_name",
          type: "input",
          width: "half",
          placeholder: "Website Address",
        },
      ],
    },
    {
      page: 1,
      name: "Name fo the entity or person that owns the website:",
      field: [
        {
          key: "entity_name",
          type: "input",
          width: "half",
          placeholder: "Name",
        },
      ],
    },
    {
      page: 1,
      name: "The website where these Terms will be posted:",
      field: [
        {
          key: "web_url",
          type: "input",
          width: "half",
          placeholder: "Website Address",
        },
      ],
    },
    {
      page: 1,
      name: "City and state where the parties can file for action in court in case of disputes:",
      field: [
        {
          key: "court_city",
          type: "input",
          width: "half",
          placeholder: "City",
        },
        {
          key: "court_state",
          type: "select",
          width: "half",
          placeholder: "State",
          options: [
            {
              label: "Andaman and Nicobar Islands",
              value: "Andaman and Nicobar Islands",
            },
            {
              label: "Andhra Pradesh",
              value: "Andhra Pradesh",
            },
            {
              label: "Arunachal Pradesh",
              value: "Arunachal Pradesh",
            },
            {
              label: "Assam",
              value: "Assam",
            },
            {
              label: "Bihar",
              value: "Bihar",
            },
            {
              label: "Chandigarh",
              value: "Chandigarh",
            },
            {
              label: "Chhattisgarh",
              value: "Chhattisgarh",
            },
            {
              label: "Dadra and Nagar Haveli",
              value: "Dadra and Nagar Haveli",
            },
            {
              label: "Daman and Diu",
              value: "Daman and Diu",
            },
            {
              label: "Delhi",
              value: "Delhi",
            },
            {
              label: "Goa",
              value: "Goa",
            },
            {
              label: "Gujarat",
              value: "Gujarat",
            },
            {
              label: "Haryana",
              value: "Haryana",
            },
            {
              label: "Himachal Pradesh",
              value: "Himachal Pradesh",
            },
            {
              label: "Jammu and Kashmir",
              value: "Jammu and Kashmir",
            },
            {
              label: "Jharkhand",
              value: "Jharkhand",
            },
            {
              label: "Karnataka",
              value: "Karnataka",
            },
            {
              label: "Kerala",
              value: "Kerala",
            },
            {
              label: "Lakshadweep",
              value: "Lakshadweep",
            },
            {
              label: "Madhya Pradesh",
              value: "Madhya Pradesh",
            },
            {
              label: "Maharashtra",
              value: "Maharashtra",
            },
            {
              label: "Manipur",
              value: "Manipur",
            },
            {
              label: "Meghalaya",
              value: "Meghalaya",
            },
            {
              label: "Mizoram",
              value: "Mizoram",
            },
            {
              label: "Nagaland",
              value: "Nagaland",
            },
            {
              label: "Odisha",
              value: "Odisha",
            },
            {
              label: "Puducherry",
              value: "Puducherry",
            },
            {
              label: "Punjab",
              value: "Punjab",
            },
            {
              label: "Rajasthan",
              value: "Rajasthan",
            },
            {
              label: "Sikkim",
              value: "Sikkim",
            },
            {
              label: "Tamil Nadu",
              value: "Tamil Nadu",
            },
            {
              label: "Telangana",
              value: "Telangana",
            },
            {
              label: "Tripura",
              value: "Tripura",
            },
            {
              label: "Uttar Pradesh",
              value: "Uttar Pradesh",
            },
            {
              label: "Uttarakhand",
              value: "Uttarakhand",
            },
            {
              label: "West Bengal",
              value: "West Bengal",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Email for contact:",
      field: [
        {
          key: "contact_email",
          type: "input",
          width: "half",
          placeholder: "Email",
        },
      ],
    },
  ],
};

import * as React from "react";
import "./ClientListFooter.scss";
import { NavLink, createSearchParams, useSearchParams } from "react-router-dom";

export const ClientsFooterUI = () => {
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");

  return (
    <div className="clients_footer">
      {/* <button className="clients_footer_action outlined">Upload Clients</button> */}
      <NavLink
        to={{
          pathname: `/dashboard/invoice/client`,
          search: createSearchParams({
            id,
            mode: "ADD",
          }).toString(),
        }}
      >
        <button className="clients_footer_action contained">Add Client</button>
      </NavLink>
    </div>
  );
};

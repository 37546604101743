import { userApiActions } from "../actions/userApiActions";
import { userApiInitialState } from "./userApiInitial";
import { userApiType } from "../type";

const userApiReducer = (state = userApiInitialState, action: userApiType) => {
  switch (action.type) {
    case userApiActions.LOAD_USER:
      return {
        ...state,
        userLoaded: true,
      };
    case userApiActions.CREATE_USER:
      return {
        ...state,
        userDetails: {
          ...action.payload,
        },
      };
    case userApiActions.UPDATE_USER:
      return {
        ...state,
        userDetails: {
          ...action.payload,
        },
      };
    case userApiActions.USER_CREATED:
      return {
        ...state,
        user: true,
      };
    default:
      return state;
  }
};

export default userApiReducer;

// @flow
import * as React from "react";
import SidePanel from "./SidePanel/SidePanel";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { Space } from "antd";
import Profile from "./SidePanel/Profile";
import "./AppWrapper.scss";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { dashboardRoutes } from "../../routes/routes";
import { getIndivCompany } from "../../api/companies/companies";
import { useDispatch } from "react-redux";
import {
  setLoadMessage,
  setLoader,
} from "../Loader/state/action/actionCreator";
import { toast } from "react-toastify";
import { TemplateNameModal } from "../../Modals/TemplateNameModal/TemplateNameModal";
import { setOpenTemplateNameModal } from "../../redux/Modals/modalActionCreator";
import { useSelector } from "react-redux";

interface Entity {
  entityName: string;
  website: string;
  logo: string | null;
}

interface Props {
  // entity: Entity;
  // onBackClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  children?: React.ReactElement<any, any>;
}

export const AppWrapper = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modal = useSelector((state: any) => state.modal)
  const [entity, setEntity] = React.useState<any>({
    entityName: "",
    website: "",
    logo: "",
  });
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const mode: any = searchparams.get("mode");
  const [error, setError] = React.useState<string>("");
  React.useEffect(() => {
    dispatch(setLoader(true));
    dispatch(setLoadMessage("Loading Entity Details"));
    (async () => {
      try {
        const data = await getIndivCompany({ id });
        if (data && data.type === "success") {
          setEntity({
            entityName: data.data.name,
            website: data.data.website,
            logo: data.data.logoUrl,
          });
          dispatch(setLoader(false));
        } else throw new Error("Something Went Wrong");
      } catch (error) {
        toast.error("Something went wrong");
        dispatch(setLoader(false));
        setError("Company not found. Please retry.");
      }
    })();
  }, []);

  const currentRoute: any = dashboardRoutes(id).find(each => {
    if (each.path === location.pathname) {
      return true;
    } else if (each.path.split(":").length > 1) {
      return true;
    }
  });

  if (!id || error === "Company not found. Please retry.") {
    return <Navigate to="/" replace />;
  } else {
    return (
      <>
        <div className="details">
          <SidePanel entity={entity} />
          <div className="details-main">
            <div className="details-main-head">
              <div className="details-main-head-heading">
                {currentRoute?.backNavigation && (
                  <span
                    onClick={() => navigate(currentRoute.backNavigation)}
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      marginRight: 20,
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </span>
                )}

                {mode && currentRoute?.dynamicHeading
                  ? currentRoute?.dynamicHeading(mode)
                  : currentRoute?.heading}
              </div>

              <div className="details-main-head-profile">
                <Profile />
              </div>
            </div>
            <div className="details-main-body">
              <Outlet />
            </div>
          </div>
        </div>
        {modal?.openTemplateNameModal?.key && (
          <TemplateNameModal
            open={modal?.openTemplateNameModal?.key}
            handleCancel={() => dispatch(setOpenTemplateNameModal({ key: false }))}
            companyId={id}
          />
        )}
      </>
    );
  }
};

import * as React from "react";
import "./TextButton.scss";

type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  style?: any;
  onClick?: any;
};
export const TextButton = ({
  disabled,
  active,
  children,
  style,
  onClick,
  icon,
}: Props) => {
  return (
    <button
      style={style}
      disabled={disabled}
      className={`text-button ${active ? "active-button" : ""}`}
      onClick={onClick}
    >
      {icon && (
        <span style={{ marginRight: children ? "0.5rem" : 0 }}>{icon}</span>
      )}
      {children}
    </button>
  );
};

import { Button } from "@mui/material";
import React from "react";
import { Props } from "./type";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";

const CompanyInvitedUI: React.FC<Props> = (props) => {
  const {
    id,
    name,
    creatorFirstName,
    creatorlastName,
    acceptInviteReq,
    acceptLoading,
  } = props;
  return (
    <div className="add invited">
      <div className="invited-message">You have been added to {name}</div>
      <div className="invited-names">
        You were invited by {creatorFirstName} {creatorlastName}. We have added
        you to their team.
      </div>
      <div className="add-form-submit">
        {acceptLoading ? (
          <MiniLoaderButton />
        ) : (
          <Button
            className="add-form-submit-button"
            variant="contained"
            color="primary"
            onClick={acceptInviteReq}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default CompanyInvitedUI;

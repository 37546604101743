import axios from "axios";
import { CompanyType, allCompanies, company } from ".";
import auth from "../../firebase/auth";

// Get Companies

export const deleteLogo = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const deleteImage = await axios({
      method: "post",
      url: `${company}/${id}/delete-logo`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      message: "Logo Deleted",
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};
export const uploadLogo = async ({ id, data }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) throw new Error("Authentication token not found");
    const uploadImage = await axios({
      method: "post",
      url: `${company}/${id}/upload-logo`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      type: "success",
      message: "Logo Uploaded",
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const getAllCompany = async () => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: allCompanies,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const getIndivCompany = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const response = await axios({
      method: "get",
      url: company + "/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

// Add and updates

export const addNewCompany = async ({
  companyName,
  numberOfEmployees,
  country,
  companyType,
}: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");

    const upload = await axios({
      method: "post",
      url: company,
      data: {
        companyName,
        numberOfEmployees,
        country,
        companyType,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: upload.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error,
    };
  }
};

export const updateCompany = async ({
  id,
  companyName,
  numberOfEmployees,
  website,
  description,
}: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const upload = await axios({
      method: "patch",
      url: company + id,
      data: {
        companyName,
        numberOfEmployees,
        website,
        description,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: upload.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

// Delete and Leave

export const deleteCompany = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const deleteComp = await axios({
      method: "post",
      url: company + "/" + id + "/delete",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      message: "company deleted",
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};
export const leaveCompany = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const deleteComp = await axios({
      method: "post",
      url: company + "/" + id + "/leave",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      message: "company left",
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

// Collabrators

export const acceptInvite = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const accept = await axios({
      method: "post",
      url: company + "/" + id + "/accept-invite",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      message: "Invitation Accepted",
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const addCollabrators = async ({ id, invites }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const sendInvite = await axios({
      method: "post",
      url: company + "/" + id + "/send-invite",
      data: {
        ...invites,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      message: "invitation sent",
      data: sendInvite?.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error,
    };
  }
};

export const getAllCollabrator = async ({ id }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const response = await axios({
      method: "get",
      url: company + "/" + id + "/collaborators",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};
export const deleteCollabrator = async ({ id, emails }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const response = await axios({
      method: "post",
      url: company + "/" + id + "/collaborators/delete",

      data: emails,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const updateCollabrator = async ({ id, email, role }: CompanyType) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();

    if (!token) throw new Error("Authentication token not found");
    const response = await axios({
      method: "post",
      url: company + "/" + id + "/update-role",
      data: {
        email: email,
        newRole: role,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

// import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";

// import { UniqueIdentifier } from "@dnd-kit/core";

import DeleteIcon from "@mui/icons-material/Delete";
import { TextButton } from "../../../../UILibrary/Buttons/TextButton/TextButton";

type TermsProps = {
  term: string;
  index: number;
  handleTermChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleTermDelete: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleOnBlur: (
    event: React.ChangeEvent<HTMLInputElement> 
  ) => void;
};
export const Terms = ({
  term,
  index,
  handleTermChange,
  handleTermDelete,
  handleOnBlur
}: TermsProps) => {
  return (
    <div className="new_invoice_terms_and_conditions_list_item">
      <span> {index + 1} ::</span>
      <input
        className="new_invoice_terms_and_conditions_list_item_input"
        onChange={e => handleTermChange(e, index)}
        type="text"
        name="term"
        id="term"
        placeholder="Add terms"
        value={term}
        onBlur={handleOnBlur}
      />
      <span>
        <TextButton
          icon={<DeleteIcon />}
          active
          onClick={e => handleTermDelete(e, index)}
        />
      </span>
      {/* <Divider style={{ margin: "0.5rem 0" }} /> */}
    </div>
  );
};

// @flow
import * as React from "react";
import { OutlinedButton } from "../../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import { TableIn } from "../../../../UILibrary/Table/Table";
import { Space } from "antd";
import { useQueryClient } from "react-query";
import { Column } from "../../../../Types/global.types";
import { Invoice } from "../../../../Types/invoice.types";
import { useDeleteInvoice } from "../../invoice.hooks";
import { useDispatch } from "react-redux";
import { setLoadMessage } from "../../../Loader/state/action/actionCreator";

const DeleteIcon = require("../../../../images/delete.png");
const DownloadIcon = require("../../../../images/download.png");

type Props = {
  activeInvoices: Invoice[];
  companyId: string | undefined;
  isDataLoading?: Boolean;
};
export const ActiveInvoices = ({ activeInvoices, companyId, isDataLoading }: Props) => {
  const dispatch = useDispatch()
  // const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);

  const { mutate: deleteInvoice, isLoading: isDeletingInvoice } = useDeleteInvoice()
  const handleSelectionDelete = (selectedRowKeys: string[]) => { };
  const handleSingleDelete = (invoiceId: string | null) => {
    // delete api call
    deleteInvoice({ companyId, invoiceId })
  };

  React.useEffect(() => {
    if (isDeletingInvoice) {
      dispatch(setLoadMessage("Deleting Invoice..."))
    } else {

      dispatch(setLoadMessage(""))
    }
  }, [isDeletingInvoice])

  const columns: Column[] = [
    {
      title: "Date",
      columnKey: "dueDate",
      key: "dueDate",
      width: "120px",
      render: (data: string) => new Date(data).toDateString(),
    },
    {
      title: "Invoice#",
      columnKey: "invoiceNumber",
      key: "invoiceNumber",
      width: "120px",
    },
    {
      title: "Billed To",
      columnKey: "clientDetails",
      key: "clientId",
      width: "238px",
      render: (clientDetails: any) => clientDetails?.name,
    },
    { title: "Status", columnKey: "status", key: "status", width: "183px" },
    {
      title: "",
      columnKey: "actions",
      key: "actions",
      render: (_: any, row: any, index: number) => (
        <Space size={25} align="start">
          <div
            className="list_action"
            onClick={() => {
              window.open(
                `/dashboard/invoice/download?id=${companyId}&invoice-id=${row.id}`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            <div className="list_action_icon list_action_delete">
              <img
                width={"100%"}
                height={"100%"}
                src={DownloadIcon}
                alt="Download"
              />
            </div>
            <div className="list_action_text">Download</div>
          </div>
          <div
            className="list_action"
            onClick={() => handleSingleDelete(row.id)}
          >
            <div className="list_action_icon list_action_delete">
              <img
                width={"100%"}
                height={"100%"}
                src={DeleteIcon}
                alt="Delete"
              />
            </div>
            <div className="list_action_text">Delete</div>
          </div>
        </Space>
      ),
    },
  ];
  return (
    <>
      {/* <Space size={25} align="start">
        <OutlinedButton
          disabled={!selectedRowKeys.length}
          active={!!selectedRowKeys?.length}
          style={{ marginBottom: "20px" }}
          onClick={() => handleSelectionDelete(selectedRowKeys)}
        >
          Delete
        </OutlinedButton>
        <OutlinedButton
          disabled={!selectedRowKeys.length}
          active={!!selectedRowKeys?.length}
          style={{ marginBottom: "20px" }}
          onClick={() => handleSelectionDelete(selectedRowKeys)}
        >
          Send Email
        </OutlinedButton>
      </Space> */}

      <TableIn
        classname="active_clients_table"
        columns={columns}
        data={activeInvoices}
        // selectedRows={selectedRowKeys}
        // setSelectedRows={setSelectedRowKeys}
        // selectableRows
        isDataLoading={isDataLoading}
      />
    </>
  );
};

import React, { useState, useEffect } from "react";
import CoworkerInviteUI from "./CoworkerInviteUI";
import {
  getAllCollabrator,
  addCollabrators,
} from "../../../../api/companies/companies";
import { toast } from "react-toastify";

export interface Invite {
  email: string;
  role: string;
}
export interface List {
  email: string;
  role: string;
  inviteStatus: string;
}
interface Props {
  id: string;
  coworkerList: List[];
  setCoworkerList: any;
}
const CoworkerInvite = ({ id, coworkerList, setCoworkerList }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inviteRows, setInviteRows] = useState<Invite[]>([]);
  const [loadSendInvite, setLoadSendInvite] = useState<boolean>(false);
  const addRow = () => {
    setErrorMessage("");
    const item = {
      email: "",
      role: "ADMIN",
    };
    setInviteRows([...inviteRows, item]);
  };

  const deleteRow = (ind: number) => {
    setErrorMessage("");

    const rows = [...inviteRows];
    rows.splice(ind, 1);
    setInviteRows(rows);
  };
  const handleChange = (ind: number, name: string, value: string) => {
    setErrorMessage("");

    const rows = [...inviteRows];
    rows[ind] = {
      ...rows[ind],
      [name]: value,
    };
    setInviteRows(rows);
  };
  const [result, setResult] = useState<any>({});
  useEffect(() => {
    let val = {};
    const data = inviteRows.map((item, indx) => {
      val = { ...val, [item.email]: item.role };
    });
    setResult(val);
  }, [inviteRows]);
  const validateEmail = (value: string) => {
    if (value && !/^\S+@\S+\.\S+$/.test(value)) {
      return false;
    }
  };
  const sendInvite = async () => {
    setLoadSendInvite(true);
    var val = true;
    inviteRows.map((item) => {
      if (item.email === "" || item.role === "") {
        toast.error("Please make sure no feilds are empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: "non-empty-coworker",
        });
        val = false;
        setLoadSendInvite(false);
        return;
      }
      if (validateEmail(item.email) === false) {
        toast.error("Please enter a valid email address", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "Invalid Email Address",
          theme: "light",
        });
        val = false;
        setLoadSendInvite(false);
        return;
      }
    });
    if (val === true) {
      try {
        const send = await addCollabrators({ id, invites: result });
        if (send.type === "success") {
          const getColl = await getAllCollabrator({ id });

          if (getColl && getColl.type === "success") {
            setCoworkerList(getColl.data);
          }
          toast.success("Coworkers Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoadSendInvite(false);
        } else {
          toast.error("Error occured", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoadSendInvite(false);
        }
      } catch (error) {
        toast.error("Error ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadSendInvite(false);
      }
      setInviteRows([]);
    }
  };
  return (
    <CoworkerInviteUI
      {...{
        inviteRows,
        addRow,
        deleteRow,
        handleChange,
        sendInvite,
        errorMessage,
        loadSendInvite,
      }}
    />
  );
};

export default CoworkerInvite;

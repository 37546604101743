import React from "react";
import { Button } from "@mui/material";
import { Props } from "./type";

const CompanyCreatedUI: React.FC<Props> = ({ continueClick }) => {
  return (
    <div className="add invited">
      <div className="invited-message">Setup Complete</div>
      <div className="invited-names">Nice work! Your account is ready.</div>
      <div className="add-form-submit">
        <Button
          className="add-form-submit-button"
          variant="contained"
          color="primary"
          onClick={continueClick}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CompanyCreatedUI;

import React, { useEffect, useState } from "react";
// import CompanyListUI from "../../presentationals/Companies/companiesList/CompanyListUI";
// import ProjectContainer from "../../reusables/Companies/ProjectContainer";

// import { getAllCompany } from "../../../Apis/companies";
import auth from "../../../../firebase/auth";
import { useDispatch } from "react-redux";

import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Data } from "./type";
import CompanyListUI from "./CompanyList";
import ProjectContainer from "../../container/container";
import "./styles.scss";
import {
  setLoadMessage,
  setLoader,
} from "../../../Loader/state/action/actionCreator";
import {
  setErrorMessage,
  signOut,
} from "../../../auth/states/auth/actions/authActionCreator";
import { getAllCompany } from "../../../../api/companies/companies";

const CompanyList: React.FC = () => {
  const [data, setData] = useState<Data>({
    welcomeText: "",
    companies: [],
  });
  const navigate = useNavigate();
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(setLoadMessage("Loading Entities"));
    document.title = "Cashwise Entities";
    (async () => {
      try {
        const companyData = await getAllCompany();
        if (companyData && companyData.type === "success") {
          setData(companyData.data);
          setDataLoad(false);

          dispatch(setLoader(false));
          dispatch(setLoadMessage("Loading Cashwise"));
        } else if (companyData && companyData.type === "error") {
          dispatch(setErrorMessage(companyData.errorType));
          setDataLoad(false);
          dispatch(setLoader(false));
          dispatch(setLoadMessage("Loading Cashwise"));
        }
      } catch (error) {
        setDataLoad(false);
        dispatch(setLoader(false));
        dispatch(setLoadMessage("Loading Cashwise"));
        toast.error("Something went wrong!");
      }
    })();

    // eslint-disable-next-line
  }, []);

  const handleAdd = () => {
    navigate({
      pathname: "/companies/create",
    });
  };
  const handleLogOut = () => {
    auth.signOut();
    dispatch(signOut());
  };
  const handleCompanyClick = (id: string) => {
    navigate({
      pathname: "/dashboard/companies/settings",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };
  const handleAccpetInvite = (
    id: string,
    name: string,
    creatorFirstName: string,
    creatorlastName: string
  ) => {
    navigate({
      pathname: "/companies/invited",
      search: createSearchParams({
        id,
        name,
        creatorFirstName,
        creatorlastName,
      }).toString(),
    });
  };
  return (
    <ProjectContainer
      children={
        <>
          {!dataLoad && (
            <CompanyListUI
              {...{
                data,
                handleAdd,
                handleCompanyClick,
                handleAccpetInvite,
                handleLogOut,
              }}
            />
          )}
        </>
      }
    />
  );
};

export default CompanyList;

// @flow
import * as React from "react";
import { OutlinedButton } from "../../../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import "./ActiveClientsTab.scss";
import { TableIn } from "../../../../../UILibrary/Table/Table";
import { Space } from "antd";
import { Client } from "../../../../../Types/invoice.types";
import { Column } from "../../../../../Types/global.types";

const DeleteIcon = require("../../../../../images/delete.png");
const InvoiceIcon = require("../../../../../images/bill.png");
const EditIcon = require("../../../../../images/edit_Gray.png");

type Props = {
  activeClients: any[];
  selectedRowKeys: string[];
  setSelectedRowKeys: Function;
  handleSelectionDelete: Function;
  handleSingleDeleteClient: Function;
  activeTab: string;
  setActiveTab: Function;
  handleCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditClient: (data: Client) => void;
};

export const ActiveClientsTab = ({
  selectedRowKeys,
  setSelectedRowKeys,
  activeClients,
  handleSingleDeleteClient,
  handleSelectionDelete,
  handleEditClient,
}: Props) => {
  const columns: Column[] = [
    {
      title: "Client Name",
      columnKey: "name",
      key: "name",
      width: "155px",
    },
    {
      title: "phoneNumber",
      columnKey: "phoneNumber",
      key: "phoneNumber",
      width: "155px",
    },
    { title: "Email", columnKey: "email", key: "email", width: "238px" },
    {
      title: "GSTIN/Tax ID",
      columnKey: "taxId",
      key: "taxId",
      width: "183px",
      render: (_: any, row: any) => row.taxInformation?.taxId || "-",
    },
    { title: "Country", columnKey: "country", key: "country", width: "94px" },
    {
      title: "",
      columnKey: "actions",
      key: "actions",
      width: "100%",
      align: "right",
      render: (_: any, row: any, index: number) => (
        <Space size={25} align="start">
          <div className="list_action" onClick={() => handleEditClient(row)}>
            <div className="list_action_icon">
              <img width={"100%"} height={"100%"} src={EditIcon} alt="Edit" />
            </div>
            <div className="list_action_text">Edit</div>
          </div>
          {/* <div className="list_action">
            <div className="list_action_icon">
              <img
                width={"100%"}
                height={"100%"}
                src={InvoiceIcon}
                alt="Create Invoice"
              />
            </div>
            <div className="list_action_text">Create Invoice</div>
          </div> */}
          <div
            className="list_action"
            onClick={() => handleSingleDeleteClient(row.id)}
          >
            <div className="list_action_icon list_action_delete">
              <img
                width={"100%"}
                height={"100%"}
                src={DeleteIcon}
                alt="Delete"
              />
            </div>
            <div className="list_action_text">Delete</div>
          </div>
        </Space>
      ),
    },
  ];

  return (
    <>
      {/* <OutlinedButton
        disabled={!selectedRowKeys.length}
        active={!!selectedRowKeys?.length}
        style={{ marginBottom: "20px" }}
        onClick={() => handleSelectionDelete(selectedRowKeys)}
      >
        Delete
      </OutlinedButton> */}

      <TableIn
        classname="active_clients_table"
        columns={columns}
        data={activeClients}
        // selectedRows={selectedRowKeys}
        // setSelectedRows={setSelectedRowKeys}
        // selectableRows
      />
    </>
  );
};

// @flow
import * as React from "react";

import { ClientsFooterUI } from "./ClientListFooter/ClientListFooter";
import { ActiveClientsTab } from "./Tabs/ActiveClientsTab/ActiveClientsTab";
import { DeletedClientsTab } from "./Tabs/DeletedClientsTab/DeletedClientsTab";
import { TabsIn } from "../../../UILibrary/Tabs/Tabs";
import { Tab } from "../../../Types/global.types";
import { AppContentCard } from "../../AppWrapper/AppContentCard/AppContentCard";
import { Client } from "../../../Types/invoice.types";

type Props = {
  activeClients: any[];
  deletedClients: any[];
  selectedRowKeys: string[];
  setSelectedRowKeys: Function;
  handleSelectionDelete: Function;
  handleSingleDeleteClient: Function;
  activeTab: string;
  setActiveTab: Function;
  handleCheckboxClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditClient: (data: Client) => void;
};

export const ClientListUI = ({
  activeClients,
  deletedClients,
  selectedRowKeys,
  setSelectedRowKeys,
  handleSelectionDelete,
  handleSingleDeleteClient,
  activeTab,
  setActiveTab,
  handleCheckboxClick,
  handleSelectAllClick,
  handleEditClient,
}: Props) => {
  const tabsMap: Tab[] = [
    {
      title: "Active Clients",
      content: (
        <ActiveClientsTab
          activeClients={activeClients}
          selectedRowKeys={selectedRowKeys}
          handleSelectionDelete={handleSelectionDelete}
          handleSingleDeleteClient={handleSingleDeleteClient}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleCheckboxClick={handleCheckboxClick}
          handleSelectAllClick={handleSelectAllClick}
          setSelectedRowKeys={setSelectedRowKeys}
          handleEditClient={handleEditClient}
        />
      ),
    },
    // {
    //   title: "Deleted Clients",
    //   content: (
    //     <DeletedClientsTab
    //       deletedClients={deletedClients}
    //       selectedRowKeys={selectedRowKeys}
    //       handleSelectionDelete={handleSelectionDelete}
    //       handleSingleDeleteClient={handleSingleDeleteClient}
    //       activeTab={activeTab}
    //       setActiveTab={setActiveTab}
    //       handleCheckboxClick={handleCheckboxClick}
    //       handleSelectAllClick={handleSelectAllClick}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <AppContentCard>
        <TabsIn tabs={tabsMap} />
      </AppContentCard>
      <ClientsFooterUI />
    </>
  );
};

// @flow
import * as React from "react";
import "./Accordian.scss";

import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

type Props = {
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  title?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  children?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>[]
    | undefined;
};

export const AccordianIn = ({ icon, title, children }: Props) => {
  const [show, setShow] = React.useState<boolean>(false);

  return (
    <>
      <div className="item" onClick={() => setShow(!show)}>
        <div className="item_icon">{icon}</div>
        <div className="item_name">{title}</div>
        <div className={`icon-drop ${show ? "not-rotate" : "rotate"}`}>
          <KeyboardArrowUpOutlinedIcon />
        </div>
      </div>
      <div className={`navlinks ${show ? "visible" : "collapsed"}`}>
        {children}
      </div>
    </>
  );
};

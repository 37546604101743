import {
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Space,
  ConfigProvider,
} from "antd";
import React from "react";
interface Props {
  val: any;
  keyValue: any;
  setKeyValues: any;
}
const { TextArea } = Input;

const FormItem = ({ val, keyValue, setKeyValues }: Props) => {
  return (
    <>
      {val.field.map((item: any) => (
        <div className={`docgen-request-fields-set-${item.width}`}>
          {item.type === "input-split" && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gridGap: 20,
              }}
            >
              <Form.Item
                className="add-form-item"
                key={item.split[0].key}
                name={item.split[0].key}
                rules={[
                  {
                    required: true,
                    message: `${item.placeholder} is required`,
                  },
                ]}
              >
                <Input
                  className="doc-gen-form-input"
                  placeholder={item.split[0].placeholder}
                  onChange={(e: any) => {
                    const firstName: string = e.target.value;
                    setKeyValues((prevObj: any) => ({
                      ...keyValue,
                      [item.key]: `${firstName} ${
                        prevObj[item.key].split(" ")[1] || ""
                      }`,
                    }));
                  }}
                />
              </Form.Item>
              <Form.Item
                className="add-form-item"
                key={item.split[1].key}
                name={item.split[1].key}
                rules={[
                  {
                    required: true,
                    message: `${item.placeholder} is required`,
                  },
                ]}
              >
                <Input
                  className="doc-gen-form-input"
                  placeholder={item.split[1].placeholder}
                  onChange={(e: any) => {
                    const lastName: string = e.target.value;
                    setKeyValues((prevObj: any) => ({
                      ...keyValue,
                      [item.key]: `${
                        prevObj[item.key].split(" ")[0] || ""
                      } ${lastName}`,
                    }));
                  }}
                />
              </Form.Item>
            </div>
          )}
          {item.type === "input" && (
            <Form.Item
              className="add-form-item"
              key={item.key}
              name={item.key}
              rules={[
                {
                  required: true,
                  message: `${item.placeholder} is required`,
                },
              ]}
            >
              <Input
                className="doc-gen-form-input"
                placeholder={item.placeholder}
                onChange={(e: any) =>
                  setKeyValues({
                    ...keyValue,
                    [item.key]: e.target.value,
                  })
                }
              />
            </Form.Item>
          )}
          {item.type === "select" && (
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#F5f5f5",
                  lineWidth: 0,
                },
              }}
            >
              <Form.Item
                className="add-form-item"
                key={item.key}
                name={item.key}
                rules={[
                  {
                    required: true,
                    message: `${item.placeholder} is required`,
                  },
                ]}
              >
                <Select
                  placement="bottomRight"
                  size="large"
                  rootClassName="add-form-select"
                  dropdownStyle={{ minWidth: "240px" }}
                  popupClassName="select-popup"
                  placeholder={item.placeholder}
                  onChange={(value) =>
                    setKeyValues({
                      ...keyValue,
                      [item.key]: value,
                    })
                  }
                  options={item.options}
                />
              </Form.Item>
            </ConfigProvider>
          )}
          {item.type === "date" && (
            <Form.Item
              className="add-form-item"
              key={item.key}
              name={item.key}
              rules={[
                {
                  required: true,
                  message: `${item.placeholder} is required`,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                placeholder={item.placeholder}
                className="datepick"
                onChange={(date, datetime) =>
                  setKeyValues({
                    ...keyValue,
                    [item.key]: datetime,
                  })
                }
              />
            </Form.Item>
          )}
          {item.type === "textarea" && (
            <Form.Item
              className="add-form-item"
              key={item.key}
              name={item.key}
              rules={[
                {
                  required: true,
                  message: `${item.placeholder} is required`,
                },
              ]}
            >
              <TextArea
                className="doc-gen-form-input"
                placeholder={item.placeholder}
                style={{ minHeight: 120 }}
                onChange={(e: any) =>
                  setKeyValues({
                    ...keyValue,
                    [item.key]: e.target.value,
                  })
                }
              />
            </Form.Item>
          )}
          {item.type === "radio" && (
            <Form.Item
              className="add-form-item"
              key={item.key}
              name={item.key}
              rules={[
                {
                  required: true,
                  message: `${item.placeholder} is required`,
                },
              ]}
            >
              <Radio.Group
                onChange={(e: any) =>
                  setKeyValues({
                    ...keyValue,
                    [item.key]: e.target.value,
                  })
                }
              >
                <Space direction="vertical" size={10}>
                  {item.options.map((val: any) => (
                    <Radio value={val.value}>{val.label}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
        </div>
      ))}
    </>
  );
};

export default FormItem;

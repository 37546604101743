// @flow
import * as React from "react";
import { TextButton } from "../Buttons/TextButton/TextButton";
import "./Tabs.scss";
import { Tab } from "../../Types/global.types";
type Props = {
  tabs: Tab[];
};
export const TabsIn = ({ tabs }: Props) => {
  const [activeTab, setActiveTab] = React.useState<number>(1);

  return (
    <>
      <div className="tab_buttons">
        {tabs.map((tab, index) => (
          <TextButton
            style={{ marginRight: "3rem" }}
            onClick={() => {
              setActiveTab(index + 1);
            }}
            active={activeTab === index + 1}
          >
            {tab.title}
          </TextButton>
        ))}
      </div>
      {tabs[activeTab - 1].content}
    </>
  );
};

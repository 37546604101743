import { baseAPI } from "..";

export const documentGen = baseAPI + "/doc-gen";

export interface Props {
  id?: string | null;
  tempCode?: string;
  docCode?: string;
  type?: string;
  data?: any;
}

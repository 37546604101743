import { Form, Input, Select, ConfigProvider } from "antd";
import React from "react";
import { Button } from "@mui/material";
import { Props } from "./type";
import { countryAndType, numberOfEmployeesSelect } from "./data";
import MiniLoaderButton from "../../../Loader/miniLoaderButton";

const CompanyCreateUI: React.FC<Props> = (props) => {
  const { data, handleChange, handleFormSubmit, submitLoading } = props;

  return (
    <div className="add">
      <div className="add-text">
        <div className="add-text-heading">Enter details </div>
        <div className="add-text-message">
          Please enter details to create your business with Cashwise
        </div>
      </div>
      <Form className="add-form" onFinish={handleFormSubmit}>
        <Form.Item
          className="add-form-item"
          name="companyName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please enter your first name",
            },
          ]}
          initialValue={data.companyName}
        >
          <Input
            className="add-form-input-name"
            placeholder="Entity Name"
            onChange={(e) => handleChange("companyName", e.target.value)}
          />
        </Form.Item>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#F5f5f5",
              lineWidth: 0,
            },
          }}
        >
          <Form.Item
            className="add-form-item"
            name="numberOfEmployees"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please select number of employees",
              },
            ]}
          >
            <Select
              placement="bottomRight"
              size="large"
              rootClassName="add-form-select"
              dropdownStyle={{ minWidth: "140px" }}
              popupClassName="select-popup"
              placeholder="Number of Employees"
              onChange={(value) => handleChange("numberOfEmployees", value)}
              options={numberOfEmployeesSelect}
            />
          </Form.Item>
        </ConfigProvider>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#F5f5f5",
              lineWidth: 0,
            },
          }}
        >
          <Form.Item
            className="add-form-item"
            name="country"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please select your country",
              },
            ]}
          >
            <Select
              size="large"
              placement="bottomRight"
              popupClassName="select-popup"
              rootClassName="add-form-select"
              dropdownStyle={{ minWidth: "140px" }}
              placeholder="Country"
              onChange={(value) => handleChange("country", value)}
              options={[
                ...countryAndType.map((item) => ({
                  value: item.country,
                  label: item.country,
                })),
              ]}
            />
          </Form.Item>
        </ConfigProvider>
        {data.country &&
          countryAndType.filter((i) => i.country === data.country)[0] && (
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: "#F5f5f5",
                  lineWidth: 0,
                },
              }}
            >
              <Form.Item
                className="add-form-item"
                name={`${data.country}countryType`}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please select your entity",
                  },
                ]}
              >
                <Select
                  placement="bottomRight"
                  size="large"
                  rootClassName="add-form-select"
                  popupClassName="select-popup"
                  dropdownStyle={{ minWidth: "140px" }}
                  placeholder="Type of Entity"
                  onChange={(value) => handleChange("companyType", value)}
                  options={[
                    ...countryAndType
                      .filter((i) => i.country === data.country)
                      .map((item) => {
                        return item.countryTypes.map((i) => ({
                          label: i,
                          value: i,
                        }));
                      })[0],
                  ]}
                />
              </Form.Item>
            </ConfigProvider>
          )}

        <div className="add-form-submit">
          {submitLoading ? (
            <MiniLoaderButton />
          ) : (
            <Button
              className="add-form-submit-button"
              variant="contained"
              color="primary"
              type="submit"
            >
              Continue
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CompanyCreateUI;

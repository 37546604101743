import React, { useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ClientListUI } from "./ClientListUI";
import { useClients, useDeleteClient } from "../invoice.hooks";
import { Client } from "../../../Types/invoice.types";
import { useDispatch } from "react-redux";
import { setLoadMessage } from "../../Loader/state/action/actionCreator";

const ClientList: React.FC = () => {
  const [searchparams] = useSearchParams();
  const companyId: string | null = searchparams.get("id");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: clients, isLoading } = useClients(companyId);
  const { mutate: deleteClient, isLoading: isDeleting } = useDeleteClient();

  const [activeTab, setActiveTab] = React.useState<string>("1");
  const [activeClients, setActiveClients] = React.useState<Client[]>([]);
  const [deletedClients, setDeletedClients] = React.useState<Client[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    // filtere active & deleted clients
    setActiveClients(clients?.filter((each: Client) => !each?.isDeleted));
    setDeletedClients(clients?.filter((each: Client) => each?.isDeleted));
  }, [clients]);

  React.useEffect(() => {
    if(isLoading || isDeleting) {
      dispatch(setLoadMessage("Loading Clients..."))
    } else {
      dispatch(setLoadMessage(""))

    }
  }, [dispatch, isDeleting, isLoading]);

  const handleSelectionDelete = () => {
    const filteredActiveClients = activeClients.filter(
      client => !selectedRowKeys.includes(client?.id)
    );
    const filteredDeletedClients = deletedClients.concat(
      activeClients.filter(client => selectedRowKeys.includes(client?.id))
    );

    setActiveClients(filteredActiveClients);
    setDeletedClients(filteredDeletedClients);
    setSelectedRowKeys([]);
  };

  const handleSingleDeleteClient = (clientId: string) => {
    deleteClient({companyId, clientId})
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowId = event?.target?.value;
    if (selectedRowKeys.includes(rowId)) {
      // If the row is already selected, remove it from the selectedRows array
      setSelectedRowKeys(selectedRowKeys?.filter(id => id !== rowId));
    } else {
      // If the row is not already selected, add it to the selectedRows array
      setSelectedRowKeys([...selectedRowKeys, rowId]);
    }
  };

  // Define a function to handle "Select All" checkbox click events
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // If the "Select All" checkbox is checked, select all rows
      setSelectedRowKeys(activeClients.map(each => each.id));
    } else {
      // If the "Select All" checkbox is unchecked, deselect all rows
      setSelectedRowKeys([]);
    }
  };

  const handleEditClient = (client: Client) => {
    navigate({
      pathname: `/dashboard/invoice/client`,
      search: createSearchParams({
        id: companyId || "",
        "client-id": client?.id || "",
        mode: "EDIT",
      }).toString(),
    });
  };

  return (
    <ClientListUI
      activeClients={activeClients}
      selectedRowKeys={selectedRowKeys}
      handleSelectionDelete={handleSelectionDelete}
      handleSingleDeleteClient={handleSingleDeleteClient}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      handleCheckboxClick={handleCheckboxClick}
      handleSelectAllClick={handleSelectAllClick}
      deletedClients={deletedClients}
      setSelectedRowKeys={setSelectedRowKeys}
      handleEditClient={handleEditClient}
    />
  );
};

export default ClientList;

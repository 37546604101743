// @flow
import * as React from "react";
import { TableIn } from "../../../../UILibrary/Table/Table";
import { calculateRowValues } from "../helper";
import { OutlinedButton } from "../../../../UILibrary/Buttons/OutlinedButton/OutlinedButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { taxes } from "../../taxConfig";

import "../NewInvoiceUI.scss";

import CurrencyList from "currency-list";
import { KeyValue } from "../../../../UILibrary/KeyValue/KeyValue";

import { Divider, Space, Switch } from "antd";
import { TextButton } from "../../../../UILibrary/Buttons/TextButton/TextButton";
import AbcIcon from "@mui/icons-material/Abc";
import { CloseOutlined } from "@ant-design/icons";
import { ToWords } from "to-words";

import { DiscountsOnTotal } from "./DiscountsOnTotal/DiscountsOnTotal";
import { ExtraChargesOnTotal } from "./ExtraChargesOnTotal/ExtraChargesOnTotal";
import { useCalculateTotal } from "../../invoice.hooks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Invoice, TaxConfigObject } from "../../../../Types/invoice.types";
import { RootReducer } from "../../../../redux/rootReducer";
import {
  addNewLineItem,
  handleInvoiceKeyValueChange,
  setInvoiceDetails,
  setInvoiceItemRowAtIndex,
} from "../../../../redux/Invoice/invoiceActionCreator";
import { Column } from "../../../../Types/global.types";

const toWords = new ToWords();

type Props = {
  handleInvoiceChangeOnBlur: (
    data: Partial<Invoice & { id: string; templateId: string | null }>
  ) => void;
  isLoading: boolean
};

export const InvoiceItems = ({ handleInvoiceChangeOnBlur, isLoading }: Props) => {

  const dispatch = useDispatch();
  const {
    taxScheme,
    currency,
    lineItems: invoiceItems,
    columnDefinitions
  } = useSelector((state: RootReducer) => state?.invoice);

  const [dynamicColumns, setDynamicColumns] = React.useState<Column[] | null>([])
  const currencySymbol = React.useMemo(
    () =>
      currency ? (CurrencyList.getAll("en_IN")[currency].symbol as string) : "",
    [currency]
  );
  const taxObject: TaxConfigObject | undefined = React.useMemo(() => {
    return taxes?.find(each => each.id === taxScheme);
  }, [taxScheme]);

  const [isItemWiseDiscount, setIsItemWiseDiscount] = React.useState(false);

  React.useEffect(() => {
    setIsItemWiseDiscount(!!columnDefinitions?.find(each => each.columnKey === "discount"))
  }, [columnDefinitions])


  const taxColumns = taxes?.find(
    each => each.id === taxScheme
  )?.taxAmountColumns;

  const totalAmount = useCalculateTotal(
    taxColumns
  );

  // const [showTotalInWords, setShowTotalInWords] = React.useState({
  //   show: false,
  //   value: "",
  // });


  const handleChangeInvoiceItem = (event: React.ChangeEvent<HTMLInputElement>, row: any, index: number) => {
    row[event?.target?.name] = event?.target?.value;
    dispatch(setInvoiceItemRowAtIndex({ row, index }));
  }

  console.log("invoiceitems", invoiceItems)

  React.useEffect(() => {
    if (isLoading) return
    const columns: Column[] | undefined[] = [
      {
        title: "Item",
        columnKey: "itemName",
        key: "itemName",
        width: "200px",
        isInputType: true,
        inputType: "text",
        placeholder: "Item Name",
        onInputChange: handleChangeInvoiceItem,

      },
      {
        title: taxObject?.taxRateColumn.label || "Tax Rate",
        columnKey: "taxRate",
        key: "taxRate",
        width: "100px",
        isInputType: true,
        inputType: "number",
        minInputValue: 0,
        defaultValue: 0,
        suffix: "%",
        onInputChange: (event, row, index) => {
          row.taxRate = parseFloat(event?.target?.value);
          calculateRowValues(row, taxObject);
          dispatch(setInvoiceItemRowAtIndex({ row, index }));
        },


      },
      {
        title: "Quantity",
        columnKey: "quantity",
        key: "quantity",
        width: "100px",
        isInputType: true,
        inputType: "number",
        minInputValue: 0,
        onInputChange: (event, row, index) => {
          row.quantity = parseFloat(event?.target?.value);

          calculateRowValues(row, taxObject);
          dispatch(setInvoiceItemRowAtIndex({ row, index }));
        },


      },
      {
        title: "Rate",
        columnKey: "rate",
        key: "rate",
        width: "100px",
        isInputType: true,
        inputType: "number",
        minInputValue: 0,
        prefix: currencySymbol,
        onInputChange: (event, row, index) => {
          row.rate = parseFloat(event?.target?.value);
          calculateRowValues(row, taxObject);
          dispatch(setInvoiceItemRowAtIndex({ row, index }));
        },


      },
      {
        title: "Amount",
        columnKey: "amount",
        key: "amount",
        width: "120px",
        isInputType: true,
        inputType: "number",
        minInputValue: 1,
        prefix: currencySymbol,

        onInputChange: (event, row, index) => {
          row.amount = parseFloat(event?.target?.value);
          calculateRowValues(row, taxObject, true);
          dispatch(setInvoiceItemRowAtIndex({ row: { ...row }, index }));
        },
      },
      ...(taxObject?.taxAmountColumns?.map(each => ({
        title: each.label,
        columnKey: each.variable,
        key: each.variable,
        width: "100px",
        prefix: currencySymbol,
        defaultValue: 0,
      })) || []),
      {
        title: "Total",
        columnKey: "total",
        key: "total",
        width: "150px",
        prefix: currencySymbol,
      },
    ];

    if (isItemWiseDiscount) {
      columns.splice(4, 0, {
        title: "Discount",
        columnKey: "discount",
        key: "discount",
        width: "100px",
        isInputType: true,
        inputType: "number",
        minInputValue: 1,
        prefix: currencySymbol,

        onInputChange: (event, row, index) => {
          row.discount = parseFloat(event?.target?.value);
          calculateRowValues(row, taxObject);

          dispatch(setInvoiceItemRowAtIndex({ row: { ...row }, index }));
        },
      });
    }

    if (taxObject?.id && ["CGST & SGST", "IGST"].includes(taxObject?.id)) {
      columns.splice(1, 0, {
        title: "HSN/SAC",
        columnKey: "hsnsac",
        key: "hsnsac",
        width: "100px",
        isInputType: true,
        inputType: "text",

        onInputChange: (event, row, index) => {
          console.log("row in hsn", row)
          row.hsnsac = (event?.target?.value);
          dispatch(setInvoiceItemRowAtIndex({ row: { ...row }, index }));
        },
      });
    }

    setDynamicColumns(columns);

    const newInvoiceItems = invoiceItems?.map((row: any, index: number) => {
      let rowCopy = { ...row };
      console.log("Incoming row", rowCopy)
      if (isItemWiseDiscount && !rowCopy?.discount) {
        rowCopy = { ...rowCopy, discount: 0 };
      }

      rowCopy = calculateRowValues({ ...rowCopy }, taxObject);
      console.log("calculated row", rowCopy)
      return { ...rowCopy }
    })

    dispatch(setInvoiceDetails({ lineItems: [...newInvoiceItems] }))


    const mappedColumns = columns?.map((column: Column) => ({
      name: column.title,
      hidden: false,
      columnKey: column.columnKey,
    }));
    dispatch(setInvoiceDetails({ columnDefinitions: mappedColumns }));
    handleInvoiceChangeOnBlur({ columnDefinitions: mappedColumns });
  }, [currencySymbol, isItemWiseDiscount, taxScheme, isLoading]);



  const handleAddNewLine = e => {
    const row = {
      itemName: "",
      gstRate: 0,
      quantity: 1,
      rate: 1,
      amount: 1,
      total: 1,
      ...(taxObject?.taxAmountColumns?.reduce(
        (result, each) => ({ ...result, [each.variable]: 0 }),
        {}
      ) || []),
      ...(["CGST & SGST", "IGST"].includes(taxScheme) && { hsnsac: "" }),
      ...(isItemWiseDiscount && { discount: 0 }),
    }
    dispatch(addNewLineItem({ row }))

    // process rows before patching to server
    const processedInvoiceItems = processRows(invoiceItems)
    handleInvoiceChangeOnBlur({ lineItems: [...processedInvoiceItems, row] })

  };
  const processRows = (invoiceItems) => {
    const processedInvoiceItems = invoiceItems.map(row => {
      if (dynamicColumns?.length) {
        Object.keys(row).forEach(rowKey => {
          if (!dynamicColumns?.find(column => column.columnKey === rowKey)) {
            delete row[rowKey];
          }
        });
      }
      return { ...row }
    });

    return processedInvoiceItems
  }
  const handleRemoveRow = (row: any, index: number) => {
    const updatedInvoiceItems = invoiceItems?.filter(
      (each: any, eachIndex: number) => eachIndex !== index
    );
    dispatch(setInvoiceDetails({ lineItems: [...updatedInvoiceItems] }));

    const processedInvoiceItems = processRows(updatedInvoiceItems)
    handleInvoiceChangeOnBlur({ lineItems: [...processedInvoiceItems] });
  };


  return (
    <>
      <div
        className="form-row"
        style={{
          marginTop: "2rem",
          paddingBottom: "15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Space size={30}>
          <select
            className="input"
            name="taxScheme"
            id="taxScheme"
            value={taxScheme}
            onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
            onBlur={() => handleInvoiceChangeOnBlur({ taxScheme: taxScheme })}
          >
            {taxes?.map((tax: any) => (
              <option key={tax.id} value={tax.id}>
                {tax.label}
              </option>
            ))}
          </select>
          <select
            className="input"
            name="currency"
            id="currency"
            onChange={e => dispatch(handleInvoiceKeyValueChange(e))}
            value={currency}
            onBlur={() => handleInvoiceChangeOnBlur({ currency: currency })}
          >
            {Object.values(CurrencyList.getAll("en_IN"))?.map(
              (currency: any) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              )
            )}
          </select>
        </Space>

        <TextButton
          icon={
            <Switch checked={isItemWiseDiscount} onChange={() => setIsItemWiseDiscount(prev => !prev)} />
          }
          active
        >
          Give Item wise Discount
        </TextButton>
      </div>
      <TableIn
        columns={dynamicColumns}
        data={invoiceItems}
        removableRows
        onRemoveRow={handleRemoveRow}
        // extraFunctionality={useAddDescription}
        classname="new_invoice_items_table_wrapper"
      />

      <div style={{ margin: "1rem 0" }}>
        <OutlinedButton dashed onClick={handleAddNewLine}>
          <span style={{ marginRight: "0.5rem" }}>
            <AddRoundedIcon />
          </span>
          Add New Line
        </OutlinedButton>
      </div>

      <div className="new_invoice_summary_wrapper">
        <div></div>
        <div className="new_invoice_summary">
          <KeyValue
            title={"Amount"}
            value={
              currencySymbol +
              " " +
              invoiceItems?.reduce(
                (sum: number, invoice: any) => sum + (parseFloat(invoice?.amount) || 0),
                0
              )
            }
            // totalWidth={300}
            valueWeight={600}
            valueAlign="right"
            titleFontSize={"1.1rem"}
            valueFontSize={"1.1rem"}
          />
          {taxes
            ?.find(each => each.id === taxScheme)
            ?.taxAmountColumns?.map((each: any) => (
              <KeyValue
                title={each.label}
                value={
                  currencySymbol +
                  " " +
                  Number(invoiceItems?.reduce(
                    (sum: number, invoice: any) =>
                      sum + (parseFloat(invoice?.[each?.variable]) || 0),
                    0
                  )).toFixed(2)
                }
                // totalWidth={300}
                valueWeight={600}
                valueAlign="right"
                titleFontSize={"1.1rem"}
                valueFontSize={"1.1rem"}
              />
            ))}
          <ExtraChargesOnTotal
            currencySymbol={currencySymbol}
            handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur}
          />

          <DiscountsOnTotal
            currencySymbol={currencySymbol}
            handleInvoiceChangeOnBlur={handleInvoiceChangeOnBlur}
          />
          <br />
          <Divider style={{ border: "2px solid rgba(5, 5, 5, 0.06)" }} />
          <KeyValue
            title={`Total (${currency})`}
            value={`${currencySymbol} ${(totalAmount || 0).toFixed(2)}`}
            titleColor="black"
            valueWeight={600}
            valueAlign="right"
            titleFontSize={"1.5rem"}
            valueFontSize={"1.5rem"}
          />
          <Divider style={{ border: "1px solid rgba(5, 5, 5, 0.06)" }} />
          {/* {showTotalInWords.show ? (
            <div className="new_invoice_total_in_words">
              <KeyValue
                className="new_invoice_total_in_words_keyvalue"
                title={`Total (in words)`}
                value={`${toWords.convert(totalAmount || 0)} ${CurrencyList.getAll("en_IN")[currency].name_plural
                  } only`}
                alignItems="flex-start"
                direction="column"
                valueFontSize={"1.2rem"}
              />
              <TextButton
                icon={<CloseOutlined />}
                active
                onClick={() =>
                  setShowTotalInWords((prev: any) => ({
                    ...prev,
                    show: false,
                  }))
                }
              />
            </div>
          ) : (
            <TextButton
              icon={<AbcIcon />}
              active
              onClick={() => {
                setShowTotalInWords((prev: any) => ({ ...prev, show: true }));
              }}
            >
              Show Total in Words
            </TextButton>
          )} */}
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Loader/state/action/actionCreator";
import DocumentGenerationUI from "./DocumentGenerationUI";
import "./styles.scss";

const DocumentGeneration = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const id: any = searchparams.get("id");
  const dispatch = useDispatch();
  console.log("ID", id);

  const handleDocClick = (name: string) => {
    navigate({
      pathname: "/dashboard/documentGeneration/" + name,
      search: createSearchParams({
        id,
      }).toString(),
    });
  };
  const handleReqCustom = () => {
    navigate({
      pathname: "/dashboard/documentGeneration/requestCustom",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };
  if (!id) {
    return (
      <>
        <Navigate to="/" replace />
      </>
    );
  } else {
    return <DocumentGenerationUI {...{ handleDocClick, handleReqCustom }} />;
  }
};

export default DocumentGeneration;

// @flow
import * as React from "react";
import { InvoiceSummaryUI } from "./InvoiceSummaryUI";
import { ActiveInvoices } from "./ActiveInvoices/ActiveInvoices";
import { DeletedInvoices } from "./DeletedInvoices/DeletedInvoices";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Invoice } from "../../../Types/invoice.types";
import { getInvoiceList } from "../../../api/invoice/invoice.api";
import { Tab } from "../../../Types/global.types";
import { setLoadMessage } from "../../Loader/state/action/actionCreator";
import { useDispatch } from "react-redux";
type Props = {};
export const InvoiceSummary: React.FC = (props: Props) => {
  const dispatch = useDispatch();
  const [searchparams] = useSearchParams();
  const companyId: any = searchparams.get("id");

  const [activeInvoices, setActiveInvoices] = React.useState<Invoice[]>([])
  const [deletedInvoices, setDeletedInvoices] = React.useState<Invoice[]>([])
  const {
    isLoading,
    data: invoices,
  } = useQuery([companyId, "invoices"], () => getInvoiceList(companyId));

  React.useEffect(() => {
    if (isLoading) {
      dispatch(setLoadMessage("Loading Invoices..."))
    } else {
      dispatch(setLoadMessage(""))
    }
  }, [dispatch, isLoading])


  React.useEffect(() => {
    const activeInvoices: Invoice[] = invoices?.filter(
      (invoice: Invoice) => !invoice.isDeleted
    );

    const deletedInvoices: Invoice[] = invoices?.filter(
      (invoice: Invoice) => invoice.isDeleted
    );

    setActiveInvoices(activeInvoices); setDeletedInvoices(deletedInvoices)
  }, [invoices, isLoading]);

  const tabs: Tab[] = [
    {
      title: "Active Invoices",
      content: (
        <ActiveInvoices activeInvoices={activeInvoices} companyId={companyId} isDataLoading={isLoading} />
      ),
    },
    {
      title: "Deleted Invoices",
      content: <DeletedInvoices deletedInvoices={deletedInvoices} companyId={companyId} />,
    },
  ];
  return <InvoiceSummaryUI tabs={tabs} />;
};

import axios from "axios";
import { formationDocs, deleteRecord, shareRecord, Props } from ".";
import auth from "../../firebase/auth";
import { baseAPI } from "..";
import { company } from "../companies";

export const getDocs = async ({ id, type }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "get",
      url: baseAPI + "/" + type + "/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const addFundingRound = async ({ id, name }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: formationDocs + "/" + id + "/add-funding-round",
      data: {
        fundingRound: name,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const addRecordItem = async ({ id, type, recordId, data }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: baseAPI + "/" + type + "/" + id + "/record/" + recordId + "/item",
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const downloadRecordItem = async ({
  id,
  type,
  recordId,
  itemId,
}: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "get",
      url:
        baseAPI +
        "/" +
        type +
        "/" +
        id +
        "/record/" +
        recordId +
        "/item/" +
        itemId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const deleteRecordItem = async ({
  id,
  type,
  recordId,
  itemId,
}: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url:
        baseAPI +
        "/" +
        type +
        "/" +
        id +
        "/record/" +
        recordId +
        "/item/" +
        itemId +
        "/delete",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const renameRecordItem = async ({
  id,
  type,
  recordId,
  itemId,
  fileName,
}: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url:
        baseAPI +
        "/" +
        type +
        "/" +
        id +
        "/record/" +
        recordId +
        "/item/" +
        itemId +
        "/rename",
      data: {
        newFileName: fileName,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const shareRecords = async ({ id, recordIds }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: company + "/" + id + "/" + shareRecord,
      data: {
        recordIds: recordIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const deleteRecords = async ({ id, token, recordIds }: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: company + "/" + id + "/" + deleteRecord,
      data: {
        recordIds: recordIds,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const addRecord = async ({
  id,
  token,
  kind,
  multipleUpload,
  category,
  path,
}: Props) => {
  try {
    const token: string | undefined = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await axios({
      method: "post",
      url: company + "/" + id + "/record/add",
      data: {
        kind,
        multipleUpload,
        category,
        path,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      type: "success",
      data: response.data,
    };
  } catch (error: any) {
    return {
      type: "error",
      errorType: error.response.data.message,
    };
  }
};

export const serviceAgreement = {
  name: "Services Agreement",
  docCodeType: "single",
  path: "service",
  type: "Business Operations",
  tempCode: "services-agreement",
  disclaimer: "Enter below details to generate a prefilled Services Agreement!",
  docCodeValues: [
    {
      docCode: "services-agreement",
    },
  ],
  page: 5,
  pageHeading:
    "Enter below details to generate a prefilled Services Agreement!",
  fields: [
    {
      page: 1,
      name: "Date on which the agreement will be entered into:",
      field: [
        {
          key: "effective_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 1,
      name: "Nature of entity of the service provider:",
      field: [
        {
          key: "sp_type_of_entity",
          type: "select",
          width: "half",
          placeholder: "Company",
          options: [
            {
              label: "Company",
              value: "Company",
            },
            {
              label: "Individual",
              value: "Individual",
            },
            {
              label: "PartnerShip",
              value: "PartnerShip",
            },
            {
              label: "Society",
              value: "Society",
            },
            {
              label: "Trust",
              value: "Trust",
            },
            {
              label: "Limited Liability Partnership",
              value: "Limited Liability Partnership",
            },
            {
              label: "Sole Proprietorship",
              value: "Sole Proprietorship",
            },
            {
              label: "HUF",
              value: "HUF",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      name: "Name and address of the service provider:",
      serviceCondition: "true",
      serviceSelectCondition: "rest",
      field: [
        {
          key: "sp_name",
          type: "input",
          width: "half",
          placeholder: "Name",
        },
        {
          key: "sp_address",
          type: "input",
          width: "full",
          placeholder: "Registered Address",
        },
      ],
    },
    {
      page: 1,
      serviceSelectCondition: "Company",
      serviceCondition: "true",
      name: "Name, country of incorporation and registered address of the company:",
      field: [
        {
          key: "sp_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "sp_country",
          type: "input",
          width: "half",
          placeholder: "Country of Incorporation",
        },
        {
          key: "sp_address",
          type: "input",
          width: "full",
          placeholder: "Registered Address",
        },
      ],
    },
    {
      page: 1,
      serviceCondition: "true",
      name: "Is service provider's communication address same as above mentioned address?",
      field: [
        {
          key: "service_radio",
          type: "radio",
          width: "initial",
          placeholder: "",
          options: [
            {
              value: "Yes",
              label: "Yes",
            },
            {
              value: "No",
              label: "No",
            },
          ],
        },
      ],
    },
    {
      page: 1,
      serviceCondition: "true",
      serviceCommunication: "No",
      name: "Communication address of the service provider:",
      field: [
        {
          key: "sp_communication_address",
          type: "input",
          width: "full",
          placeholder: "Communication Address",
        },
      ],
    },
    {
      page: 1,
      serviceCondition: "true",
      name: "Designation and name of the person representing the service provider:",
      field: [
        {
          key: "sp_rep_title",
          type: "input",
          width: "half",
          placeholder: "Designation",
        },
        {
          key: "sp_rep_name",
          type: "input",
          width: "half",
          placeholder: "Name",
        },
      ],
    },
    {
      page: 2,
      name: "Nature of entity of the client",
      field: [
        {
          key: "client_type_of_entity",
          type: "select",
          width: "half",
          placeholder: "Company",
          options: [
            {
              label: "Company",
              value: "Company",
            },
            {
              label: "Individual",
              value: "Individual",
            },
            {
              label: "PartnerShip",
              value: "PartnerShip",
            },
            {
              label: "Society",
              value: "Society",
            },
            {
              label: "Trust",
              value: "Trust",
            },
            {
              label: "Limited Liability Partnership",
              value: "Limited Liability Partnership",
            },
            {
              label: "Sole Proprietorship",
              value: "Sole Proprietorship",
            },
            {
              label: "HUF",
              value: "HUF",
            },
          ],
        },
      ],
    },
    {
      page: 2,
      name: "Name and address of the client",
      clientCondition: "true",
      clientSelectCondition: "rest",
      field: [
        {
          key: "client_name",
          type: "input",
          width: "half",
          placeholder: "Name",
        },
        {
          key: "client_address",
          type: "input",
          width: "full",
          placeholder: "Registered Address",
        },
      ],
    },
    {
      page: 2,
      name: "Name, country of incorporation and registered address of the company:",
      clientCondition: "true",
      clientSelectCondition: "Company",
      field: [
        {
          key: "client_name",
          type: "input",
          width: "half",
          placeholder: "Company Name",
        },
        {
          key: "client_country",
          type: "input",
          width: "half",
          placeholder: "Country of Incorporation",
        },
        {
          key: "client_address",
          type: "input",
          width: "full",
          placeholder: "Registered Address",
        },
      ],
    },
    {
      page: 2,
      name: "Is client communication address same as above mentioned address?",
      field: [
        {
          key: "client_radio",
          type: "radio",
          width: "initial",
          placeholder: "",
          options: [
            {
              value: "Yes",
              label: "Yes",
            },
            {
              value: "No",
              label: "No",
            },
          ],
        },
      ],
    },
    {
      page: 2,
      name: "Communication address of the client",
      clientCommunication: "No",
      field: [
        {
          key: "client_communication_address",
          type: "input",
          width: "full",
          placeholder: "Communication Address",
        },
      ],
    },
    {
      page: 2,
      name: "Designation and name of the person representing the client",
      field: [
        {
          key: "client_rep_title",
          type: "input",
          width: "half",
          placeholder: "Designation",
        },
        {
          key: "client_rep_name",
          type: "input",
          width: "half",
          placeholder: "Name",
        },
      ],
    },
    {
      page: 3,
      name: "Describe the services being provided under the agreement (try and specify details):",
      field: [
        {
          key: "services_provided",
          type: "textarea",
          width: "full",
          placeholder:
            "1. First service and detail.\n 2. Second service and detail\n and so on...",
        },
      ],
    },
    {
      page: 3,
      name: "Party responsible to obtain approvals required for provision of the services under the Agreement:",
      field: [
        {
          key: "party_responsible_for_approval",
          type: "radio",
          width: "initial",
          placeholder: "",
          options: [
            {
              label: " Service Provider",
              value: " Service Provider",
            },
            {
              label: "Client",
              value: "Client",
            },
          ],
        },
      ],
    },
    {
      page: 3,
      name: "Location at which the services will be provided:",
      field: [
        {
          key: "service_location",
          type: "input",
          width: "half",
          placeholder: "Address/City",
        },
      ],
    },
    {
      page: 3,
      name: "Party responsible to to bear the costs of travel and accomodation, if required:",
      field: [
        {
          key: "party_for_travel_expense",
          type: "radio",
          width: "initial",
          placeholder: "",
          options: [
            {
              label: "Service Provider",
              value: "Service Provider",
            },
            {
              label: "Client",
              value: "Client",
            },
          ],
        },
      ],
    },
    {
      page: 4,
      name: "Expected date of completion of the service:",
      field: [
        {
          key: "completion_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 4,
      name: "Fee:",
      field: [
        {
          key: "currency",
          type: "select",
          width: "one-sixth",
          placeholder: "Currency",
        },
        {
          key: "Amount",
          type: "input",
          width: "half",
          placeholder: "Amount",
        },
        {
          key: "payment_frequency",
          type: "select",
          width: "one-sixth",
          placeholder: "Frequency",
        },
      ],
    },
    {
      page: 4,
      name: "Number of days within which payment is to be made by the client after receipt of invoice from service provider:",
      field: [
        {
          key: "days_for_payment",
          type: "input",
          width: "half",
          placeholder: "Number of Days. Ex: 7, 14, 30, etc",
        },
      ],
    },
    {
      page: 4,
      name: "The rate (percentage per annum) at which interest will be paid in the event of delay in payment of invoiced amount by the client:",
      field: [
        {
          key: "roi_for_delay",
          type: "input",
          width: "half",
          placeholder: "Percentage (number). Ex: 10, 12, 15, etc.",
        },
      ],
    },
    {
      page: 4,
      name: "Date of commencement of the service:",
      field: [
        {
          key: "commencement_date",
          type: "date",
          width: "initial",
          placeholder: "YYYY-MM-DD",
        },
      ],
    },
    {
      page: 5,
      name: "Period of notice (in days) given by either party to terminate the contract:",
      field: [
        {
          key: "notice_period_days",
          type: "input",
          width: "half",
          placeholder: "Number of Days. Ex: 15, 30, 60, etc.",
        },
      ],
    },
    {
      page: 5,
      name: "Party to own the intellectual property in any final product of the services provided to the client:",
      field: [
        {
          key: "party_own_IP",
          type: "radio",
          width: "initial",
          placeholder: "",
          option: [
            { label: "Service Provider", value: "Service Provider" },
            { label: "Client", value: "Client" },
          ],
        },
      ],
    },
    {
      page: 5,
      name: "Enter the place where the courts will have jurisdiction over the agreement. Please choose a place where one of the parties reside:",
      field: [
        {
          key: "court_city",
          type: "input",
          width: "half",
          placeholder: "City",
        },
      ],
    },
  ],
};

import { authActions } from "./authActions";

export const loadAuth = () => {
  return { type: authActions.LOAD_AUTH };
};

export const createUser = (user: unknown) => {
  return {
    type: authActions.CREATE_USER,
    payload: user,
  };
};

export const signOut = () => {
  return {
    type: authActions.SIGN_OUT,
  };
};

export const setErrorMessage = (message: string) => {
  return {
    type: authActions.SET_ERROR,
    payload: message,
  };
};

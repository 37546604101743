import "firebase/compat/auth";
import firebase from "./index";
import { toast } from "react-toastify";

const auth = firebase.auth();
export const signInWithEmailLink = async () => {
  if (auth.isSignInWithEmailLink(window.location.href)) {
    try {
      let email: any = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        toast.error("Signin Req.");
        throw new Error("error");
        email = window.prompt("Please provide your email for confirmation");
      }

      await auth.signInWithEmailLink(email, window.location.href);
      return {
        value: "success",
      };
    } catch (error: any) {
      const { message } = error;
      if (
        message ===
        "Firebase: The action code is invalid. This can happen if the code is malformed, expired, or has already been used. (auth/invalid-action-code)."
      ) {
        toast.error("The link is expired or it's already used.", {
          toastId: "sent-already",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return {
        value: "error",
      };
    }
  }
};

export default auth;

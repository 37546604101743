import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { dashboardRoutes, nonDashboardRoutes } from "./routes";
import { AppWrapper } from "../components/AppWrapper/AppWrapper";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {nonDashboardRoutes.map((item, ind) => {
          return <Route key={ind} path={item.path} element={item.component} />;
        })}
        <Route key="dashboard" path="/dashboard" element={<AppWrapper />}>
          {dashboardRoutes().map((item, ind) => {
            return (
              <Route key={ind} path={item.path} element={item.component} />
            );
          })}
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import * as React from "react";
import "./Table.scss";

import { TableRowIn } from "./TableRow/TableRow";
import { Empty, Spin } from "antd";

type Props = {
  data: any;
  columns: any;
  selectedRows?: string[];
  setSelectedRows?: Function;
  selectableRows?: boolean;
  classname?: string;
  cellPadding?: string;
  removableRows?: boolean;
  boldHeaders?: boolean;
  hideRows?: boolean;
  onRemoveRow?: Function;
  extraFunctionality?: Function;
  isDataLoading?: Boolean;
};
export const TableIn = ({
  data,
  columns,
  selectedRows,
  setSelectedRows,
  selectableRows,
  classname,
  removableRows,
  onRemoveRow,
  extraFunctionality,
  cellPadding,
  boldHeaders,
  hideRows,
  isDataLoading
}: Props) => {
  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowId = event?.target?.value;
    if (!(selectedRows && setSelectedRows)) return;

    if (selectedRows?.includes(rowId)) {
      setSelectedRows(selectedRows?.filter(id => id !== rowId)); // If the row is already selected, remove it from the selectedRows array
    } else {
      setSelectedRows([...selectedRows, rowId]); // If the row is not already selected, add it to the selectedRows array
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!setSelectedRows) return;

    if (event.target.checked) {
      setSelectedRows(data?.map((each: any) => each.key || each.id)); // If the "Select All" checkbox is checked, select all rows
    } else {
      setSelectedRows([]); // If the "Select All" checkbox is unchecked, deselect all rows
    }
  };

  return (
    <>
      <div className={`active-table-wrapper ${classname || ""}`}>
        <table className="row-list-table">
          <thead>
            <tr className="row-list-table-header">
              {selectableRows && (
                <th
                  style={{
                    width: "62px",
                  }}
                >
                  <input
                    className="row-list-table-checkbox"
                    type="checkbox"
                    checked={
                      selectedRows &&
                      selectedRows?.length > 0 &&
                      selectedRows?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </th>
              )}
              {columns?.map((column: any, index) => (
                <th
                  key={index}
                  style={{
                    minWidth: `${column.width}`,
                    fontWeight: boldHeaders ? "600" : "normal",
                  }}
                >
                  {column.title}
                </th>
              ))}
              {removableRows && <th style={{ width: "100%" }}>{""}</th>}
            </tr>
          </thead>
          {!hideRows && (
            <tbody>
              {Array.isArray(data) &&
                data?.filter(each => !!each).map((row: any, index: number) => (
                  <TableRowIn
                    key={row?.id || row?.key || index}
                    {...{
                      columns,
                      selectedRows,
                      selectableRows,
                      removableRows,
                      onRemoveRow,
                      row,
                      index,
                      handleCheckboxClick,
                      extraFunctionality,
                      cellPadding,
                    }}
                  />
                ))}

            </tbody>
          )}
        </table>
        {!data?.length && !isDataLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {isDataLoading && (
          <Spin style={{ margin: "2rem auto 0 auto", display: "block" }} size="large" />

        )}
      </div>
    </>
  );
};

import React, { useState } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import LoginUI from "./LoginUI";
import SentUI from "./SentUI";
import AuthContainer from "../../container/AuthContainer";
import "./styles.scss";

const Login: React.FC = () => {
  const [screen, setScreen] = useState<string>("login");
  const [loginLoading, setLoginLoading] = useState<Boolean>(false);

  const getEmail: any = window.localStorage.getItem("emailForSignIn");

  const [email, setEmail] = useState<string>(getEmail ? getEmail : "");
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleSendEmail = () => {
    setLoginLoading(true);

    axios
      .post("https://api.dev.cashwise.in/send-auth-email", {
        email: email,
      })
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setLoginLoading(false);
        toast.success(
          "The email has been sent! Please also check the spam folder.",
          {
            toastId: "email_sent_login",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setScreen("sent");
      })
      .catch((error) => {
        // An error occurred
        console.error(error.message);
        setLoginLoading(false);
        toast.error("Error occured while sending mail. Please try again.", {
          toastId: "login_error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const handleSentEmailAgain = () => {
    setLoginLoading(true);
    axios
      .post("https://api.dev.cashwise.in/send-auth-email", {
        email: email,
      })
      .then(() => {
        // The link was sent successfully
        window.localStorage.setItem("emailForSignIn", email);
        toast.success(
          "The email has been sent again to same email address! Please also check the spam folder.",
          {
            toastId: "email_sent_login_again",
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setLoginLoading(false);
        setScreen("sent");
      })
      .catch((error) => {
        // An error occurred
        console.error(error.message);
        setLoginLoading(false);

        toast.error("Error occured while sending mail. Please try again.", {
          toastId: "email_sent_login_error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const backToLogin = () => {
    setScreen("login");
  };
  return (
    <>
      <AuthContainer
        children={
          screen === "login" ? (
            <LoginUI
              {...{ email, loginLoading, handleEmailChange, handleSendEmail }}
            />
          ) : (
            <SentUI
              {...{ email, loginLoading, handleSentEmailAgain, backToLogin }}
            />
          )
        }
      />
    </>
  );
};

export default Login;

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import ClientList from "../components/Invoice/ClientList/ClientList";
import { AddEditClient } from "../components/Invoice/AddEditClient/AddEditClient";
import CompanyList from "../components/companies/components/compnayList/index";
import CompanyInvited from "../components/companies/components/companyInvited";
import CompanyCreated from "../components/companies/components/companyCreationSuccess";
import CompanyCreate from "../components/companies/components/companyCreate";
import CompanyInvite from "../components/companies/components/companyInvite";
import Login from "../components/auth/components/login";
import Register from "../components/auth/components/register";
import Coworker from "../components/coworkers/components";
import DocumentGeneration from "../components/DocumentGeneration/components/main";
import Settings from "../components/settings/components";
import IndivDoc from "../components/DocumentGeneration/components/indivDocument";
import RequestCustom from "../components/DocumentGeneration/components/requestCustom";
import HeadingFormation from "../components/documentStorage/formationDeligence/Heading";
import FormationDiligence from "../components/documentStorage/formationDeligence";
import HeadingCompliance from "../components/documentStorage/compliance/Heading";
import Compliance from "../components/documentStorage/compliance";
import { NewInvoice } from "../components/Invoice/NewInvoice/NewInvoice";
import { InvoicePreview } from "../components/Invoice/InvoicePreview/InvoicePreview";
import { InvoiceSummary } from "../components/Invoice/InvoiceSummary/InvoiceSummary";
import { InvoiceTemplates } from "../components/Invoice/InvoiceTemplates/InvoiceTemplates";

/* 
Use the below priority scheme in the list of routes:
path: /dashboard/invoice/new
path: /dashboard/invoice

less nested ones will be down in the list
*/
interface Route {
  path: string;
  backNavigation?: string;
  component: any;
  heading?:
  | string
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | undefined;
}

const ProtectedRoute = ({ children }: any) => {
  const state: any = useSelector(state => state);
  const authState = state.auth;
  const userState = state.user;

  if (authState.user === null) {
    return <Navigate to="/login" replace />;
  } else {
    if (userState.user) {
      return children;
    } else {
      return <Navigate to="/profile-details" replace />;
    }
  }
};
const ProtectedLoginRoute = ({ children }: any) => {
  const state: any = useSelector(state => state);
  const authState = state.auth;
  if (authState.user === null) {
    return children;
  } else {
    if (authState.errorMessage === "Unauthorized") {
      return <Navigate to="/login" replace />;
    } else if (authState.errorMessage === "Signup incomplete") {
      return <Navigate to="/profile-details" replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  }
};
const ProtectedRegisterRoute = ({ children }: any) => {
  // return children;
  const state: any = useSelector(state => state);
  const authState = state.auth;
  const userState = state.user;
  if (authState.user === null) {
    return <Navigate to="/login" replace />;
  } else {
    if (userState.user) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  }
};

export const dashboardRoutes: (id?: string | null) => Route[] = id => [
  {
    path: "/dashboard/companies/settings",
    heading: "Settings",
    component: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/companies/co-workers",
    heading: "Co-Workers",
    backNavigation: "/dashboard/companies/settings?id=${id}",
    component: (
      <ProtectedRoute>
        <Coworker />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/documentGeneration/requestCustom",
    backNavigation: `/dashboard/documentGeneration?id=${id}`,
    heading: "Request Custom Document",
    component: (
      <ProtectedRoute>
        <RequestCustom />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/documentGeneration",
    heading: "Document Generation",
    component: (
      <ProtectedRoute>
        <DocumentGeneration />
      </ProtectedRoute>
    ),
  },

  {
    path: "/dashboard/formationDiligence",
    heading: <HeadingFormation />,
    component: (
      <ProtectedRoute>
        <FormationDiligence />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/compliance",
    heading: <HeadingCompliance />,
    component: (
      <ProtectedRoute>
        <Compliance />
      </ProtectedRoute>
    ),
  },

  {
    path: "/dashboard/template/new",
    heading: "New Template",
    component: (
      <ProtectedRoute>
        <NewInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/template/preview",
    heading: "Preview Invoice Template",
    component: (
      <ProtectedRoute>
        <InvoicePreview />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/summary",
    heading: <>Invoice Summary</>,
    component: (
      <ProtectedRoute>
        <InvoiceSummary />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/templates",
    heading: "Invoice Templates",
    component: (
      <ProtectedRoute>
        <InvoiceTemplates />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/template",
    heading: "Invoice Template",
    component: (
      <ProtectedRoute>
        <NewInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/client",
    heading: "Add Client",
    dynamicHeading: (mode: string) =>
      mode === "ADD" ? "Add Client" : "Edit Client",
    backNavigation: `/dashboard/invoice/clients?id=${id}`,
    component: (
      <ProtectedRoute>
        <AddEditClient />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/clients",
    heading: "Client List",
    component: (
      <ProtectedRoute>
        <ClientList />
      </ProtectedRoute>
    ),
  },

  {
    path: "/dashboard/documentGeneration/:docName",
    heading: "Generate Document",
    backNavigation: `/dashboard/documentGeneration?id=${id}`,
    component: (
      <ProtectedRoute>
        <IndivDoc />
      </ProtectedRoute>
    ),
  },
];

export const nonDashboardRoutes: Route[] = [
  {
    path: "/companies/create",
    heading: "",
    component: (
      <ProtectedRoute>
        <CompanyCreate />
      </ProtectedRoute>
    ),
  },
  {
    path: "/companies/invite",
    heading: "",
    component: (
      <ProtectedRoute>
        <CompanyInvite />
      </ProtectedRoute>
    ),
  },
  {
    path: "/companies/invited",
    heading: "",
    component: (
      <ProtectedRoute>
        <CompanyInvited />
      </ProtectedRoute>
    ),
  },
  {
    path: "/companies/completed",
    heading: "",
    component: (
      <ProtectedRoute>
        <CompanyCreated />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    heading: "",
    component: (
      <ProtectedRoute>
        <CompanyList />
      </ProtectedRoute>
    ),
  },
  {
    path: "login",
    heading: "",
    component: (
      <ProtectedLoginRoute>
        <Login />
      </ProtectedLoginRoute>
    ),
  },
  {
    path: "/profile-details",
    heading: "",
    component: (
      <ProtectedRegisterRoute>
        <Register />
      </ProtectedRegisterRoute>
    ),
  },
  {
    path: "/dashboard/template/download",
    heading: "",
    component: (
      <ProtectedRoute>
        <InvoicePreview />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard/invoice/download",
    heading: "",
    component: (
      <ProtectedRoute>
        <InvoicePreview />
      </ProtectedRoute>
    ),
  },
];

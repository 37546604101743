import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import { AddEditClientUI } from "./AddEditClientUI";
import { useAddClient, useClient, useClients, usePatchClient } from "../invoice.hooks";
import { toastIn } from "../../../UILibrary/Toast/Toast";
import { Client } from "../../../Types/invoice.types";

export const AddEditClient: React.FC = () => {
  const [searchparams] = useSearchParams();
  const companyId: string | null = searchparams.get("id");
  const mode: string | null = searchparams.get("mode");
  const clientId: string | null = searchparams.get("client-id");
  const { data: clients, isLoading: isGetClientLoading } = useClients(
    companyId
  );

  const [clientDetails, setClientDetails] = useState<
    Partial<Client> | undefined
  >({
    type: "Business",
    taxInformation: {
      taxScheme: "IGST",
      taxId: "",
    },
  });

  useEffect(() => {
    const client = clients?.find(each => each.id === clientId)
    if (clients && client) {
      setClientDetails(client);
    }
  }, [clients]);

  const { mutate: postMutateClient, isLoading } = useAddClient();
  const { mutate: patchMutateClient,isLoading: isPatchLoading } = usePatchClient();
  const addClient = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev?.preventDefault?.();

    if (
      !clientDetails?.name ||
      !clientDetails?.type ||
      !clientDetails?.streetAddress ||
      !clientDetails?.city ||
      !clientDetails?.postalCode ||
      !clientDetails?.state ||
      !clientDetails?.country
    )
      return toast.error("Please enter all the required fields!");

    try {
      if (mode === "ADD") {
        postMutateClient({ companyId, clientDetails });
      } else {
        patchMutateClient({companyId, clientDetails})
      }
    } catch (error) {
      toastIn.error(`Error adding new client. ${error}`);
    }
  };

  const onChange = (
    ev: any,
    key?: string | Number | Symbol | any,
    value?: string | Number | Symbol | any
  ) =>
  {if(key && value) {
    setClientDetails((details: any) => ({
      ...details,
      [key]: value ,
    }))
  } else{

    setClientDetails((details: any) => ({
      ...details,
      [ ev?.target?.name]: ev?.target?.value,
    }))}
  }

  return (
    <AddEditClientUI
      setClientDetails={setClientDetails}
      onChange={onChange}
      clientDetails={clientDetails}
      addClient={addClient}
      isLoading={isLoading || isPatchLoading}
    />
  );
};

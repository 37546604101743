import { combineReducers } from "redux";

import loaderReducer from "../components/Loader/state/reducer/loaderReducer";
import { loaderStateType } from "../components/Loader/state/type";
import { authActionType } from "../components/auth/states/auth/type";
import { userApiStateType } from "../components/auth/states/user/type";
import authReducer from "../components/auth/states/auth/reducers/authReducers";
import userApiReducer from "../components/auth/states/user/reducers/userApiReducer";
import invoiceReducer from "./Invoice/InvoiceReducer";
import { Invoice } from "../Types/invoice.types";
import { modalStatetype } from "./Modals/modalTypes";
import modalReducer from "./Modals/modalReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userApiReducer,
  load: loaderReducer,
  invoice: invoiceReducer,
  modal: modalReducer
});

export interface RootReducer {
  auth: authActionType;
  user: userApiStateType;
  load: loaderStateType;
  invoice: Invoice;
  modal: modalStatetype;
}

export default rootReducer;

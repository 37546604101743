import { Form } from "antd";
import React, { useState } from "react";
import FormItem from "./FormItem";
import { Dialog, Button, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import MiniLoaderButton from "../../../../Loader/miniLoaderButton";
import { generateDocument } from "../../../../../api/docGeneration/docgen";
import { toast } from "react-toastify";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  id: any;
  fields: any;
  keyValue: any;
  setKeyValues: any;
  noOfPages: any;
  currentPage: any;
  data: any;
  handlePreviousPage: any;
  setCurrentPage: any;
}
const tickImage = require("../../../images/tick.png");

const MultiPageForm = ({
  id,
  fields,
  keyValue,
  setKeyValues,
  noOfPages,
  currentPage,
  data,
  handlePreviousPage,

  setCurrentPage,
}: Props) => {
  const [openTemp, setOpenTemp] = useState<boolean>(false);
  const [loadCustom, setLoadCustom] = useState<boolean>(false);
  const handleSubmit = async (docCode: string, type: string) => {
    // console.log(docCode);
    setLoadCustom(true);
    const data = await generateDocument({
      id,
      docCode,
      type,
      data: keyValue,
    });
    const blobUrl = URL.createObjectURL(data.data);

    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = docCode;
    downloadLink.click();

    URL.revokeObjectURL(blobUrl);
    if (data && data.type === "success") {
      setLoadCustom(false);
      toast.success("Document Generated Successfully");
    }
    if (data && data.type === "error") {
      setLoadCustom(false);
    }
  };
  // Rendering Fields
  const renderFields = () => {
    const currentPageFields = fields.filter(
      (field: any) => field.page === currentPage + 1
    );

    return (
      <>
        {/* Page: {currentPage + 1}/{noOfPages} */}
        {currentPageFields
          .filter(
            (val: any) =>
              !val.cofounderCondition ||
              val.cofounderCondition <= keyValue.conditon_cofounder
          )
          .filter(
            (val: any) =>
              !val.serviceCondition ||
              (val.serviceCondition && keyValue.sp_type_of_entity)
          )
          .filter(
            (val: any) =>
              !val.serviceSelectCondition ||
              (val.serviceSelectCondition &&
                (val.serviceSelectCondition === keyValue.sp_type_of_entity ||
                  (val.serviceSelectCondition !== keyValue.sp_type_of_entity &&
                    val.serviceSelectCondition === "rest")))
          )
          .filter(
            (val: any) =>
              !val.clientCondition ||
              (val.clientCondition && keyValue.sp_type_of_entity)
          )
          .filter(
            (val: any) =>
              !val.clientSelectCondition ||
              (val.clientSelectCondition &&
                (val.clientSelectCondition === keyValue.sp_type_of_entity ||
                  (val.clientSelectCondition !== keyValue.sp_type_of_entity &&
                    val.clientSelectCondition === "rest")))
          )
          .filter(
            (val: any) =>
              !val.serviceCommunication ||
              (val.serviceCommunication &&
                val.serviceCommunication === keyValue.service_radio)
          )
          .filter(
            (val: any) =>
              !val.clientCommunication ||
              (val.clientCommunication &&
                val.clientCommunication === keyValue.client_radio)
          )
          .filter(
            (val: any) =>
              !val.policyCondition ||
              val.policyCondition === keyValue.client_radio
          )

          .map((val: any) => {
            return (
              <div className="docgen-request-fields-set">
                <div className="docgen-request-fields-set-heading">
                  {val.name}
                </div>

                <FormItem {...{ val, keyValue, setKeyValues }} />
              </div>
            );
          })}
      </>
    );
  };

  //   Rendering Form
  return (
    <Form
      onFinish={() => {
        if (currentPage === noOfPages - 1) {
          setOpenTemp(true);
        } else {
          setCurrentPage(currentPage + 1);
        }
      }}
    >
      <div className="docgen-request-fields">
        {renderFields()}

        <div className="docgen-request-button">
          {currentPage > 0 && (
            <Button variant="contained" onClick={handlePreviousPage}>
              Previous
            </Button>
          )}
          {currentPage < noOfPages - 1 && (
            <Button variant="contained" type="submit">
              Next
            </Button>
          )}
          {currentPage === noOfPages - 1 && (
            <Button variant="contained" type="submit">
              Generate Document
            </Button>
          )}
        </div>
        <Dialog TransitionComponent={Transition} open={openTemp}>
          <div className="docgen-dialog dialog">
            <div className="dialog-close">
              <IconButton
                onClick={() => {
                  setOpenTemp(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="docgen-dialog-image">
              <img src={tickImage} alt="" />
            </div>
            <div className="docgen-dialog-heading">
              Hurray! Document Generated!
            </div>
            <div className="docgen-dialog-buttons">
              {loadCustom ? (
                <MiniLoaderButton />
              ) : (
                <>
                  <Button
                    className="docgen-dialog-buttons-contained"
                    variant="contained"
                    onClick={() => {
                      if (
                        data[0].docCodeType == "multiple" &&
                        data[0].path === "privacyPolicy"
                      ) {
                        handleSubmit(
                          data[0].docCodeValues[keyValue.condition_policy - 1]
                            .docCode,
                          "pdf"
                        );
                      } else if (
                        data[0].docCodeType == "multiple" &&
                        data[0].path === "cofounder"
                      ) {
                        handleSubmit(
                          data[0].docCodeValues[keyValue.conditon_cofounder - 2]
                            .docCode,
                          "pdf"
                        );
                      } else {
                        handleSubmit(data[0].docCodeValues[0].docCode, "pdf");
                      }
                    }}
                  >
                    Download PDF
                  </Button>
                  <Button
                    className="docgen-dialog-buttons-contained"
                    variant="contained"
                    onClick={() => {
                      if (data[0].docCodeType == "multiple") {
                        handleSubmit(
                          data[0].docCodeValues[keyValue.none - 2].docCode,
                          "docs"
                        );
                      } else {
                        handleSubmit(data[0].docCodeValues[0].docCode, "docs");
                      }
                    }}
                  >
                    Download Word
                  </Button>
                </>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </Form>
  );
};

export default MultiPageForm;

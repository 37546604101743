import React from "react";
import { Dialog, Button, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

import { Input } from "antd";
interface Props {
  currentEntity: any;
  leaveEntity: boolean;
  handleLeaveEntity: any;
  deleteEntity: boolean;
  handleDeleteEntity: any;
  deleteEntityInput: string;
  setDeleteEntityInput: any;
  lastNameMatch: string;
  setLastNameMatch: any;
  userDetail: any;
  handleLeave: () => void;
  handleDelete: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeleteLeaveEntity: React.FC<Props> = (props) => {
  const {
    currentEntity,
    leaveEntity,
    handleLeaveEntity,
    deleteEntity,
    handleDeleteEntity,
    deleteEntityInput,
    setDeleteEntityInput,
    lastNameMatch,
    setLastNameMatch,
    userDetail,
    handleLeave,
    handleDelete,
  } = props;
  return (
    <div className="setting-delete">
      {currentEntity.userRole === "OWNER" ? (
        <Button variant="text" onClick={() => handleDeleteEntity(true)}>
          Delete this Entity from Cashwise?
        </Button>
      ) : (
        <Button variant="text" onClick={() => handleLeaveEntity(true)}>
          Leave this Entity?
        </Button>
      )}
      {/* DELETE DIALOG */}
      <Dialog TransitionComponent={Transition} open={deleteEntity}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                handleDeleteEntity(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Delete Entity?</div>
          <div className="dialog-content">
            Enter entity name "{currentEntity.name}" to delete!
          </div>
          <div className="dialog-detail">
            <div className="dialog-detail-heading">Things to know:</div>
            <div className="dialog-detail-content">
              - This is permanent and can't be undone.{" "}
            </div>
            <div className="dialog-detail-content">
              - Admins would no longer be able to log in
            </div>
          </div>
          <div className="dialog-input">
            <div className="dialog-input-heading">
              Enter the entity name to delete{" "}
            </div>
            <Input
              className={`dialog-input-input ${
                deleteEntityInput !== "" &&
                deleteEntityInput !== currentEntity.name &&
                "input-error"
              }`}
              placeholder="First Name"
              value={deleteEntityInput}
              onChange={(e) => setDeleteEntityInput(e.target.value)}
            />
            {deleteEntityInput !== "" &&
              deleteEntityInput !== currentEntity.name && (
                <div className="dialog-input-error">
                  The entity name entered isn't correct{" "}
                </div>
              )}
          </div>
          <div className="dialog-buttons">
            <Button
              className="dialog-buttons-contained"
              variant="contained"
              disabled={deleteEntityInput !== currentEntity.name ? true : false}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </Dialog>
      {/* LEAVE DIALOG */}
      <Dialog TransitionComponent={Transition} open={leaveEntity}>
        <div className="logout-dialog dialog">
          <div className="dialog-close">
            <IconButton
              onClick={() => {
                handleLeaveEntity(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="dialog-heading">Leave Entity?</div>
          <div className="dialog-content">
            Enter your last name "{userDetail.lastName}" to leave!
          </div>
          <div className="dialog-detail">
            <div className="dialog-detail-heading">Things to know:</div>
            <div className="dialog-detail-content">
              - You would be logged out and would no longer be able to log in
            </div>
            <div className="dialog-detail-content">
              - You would be needed to be invited again to join this entity
              again{" "}
            </div>
          </div>
          <div className="dialog-input">
            <div className="dialog-input-heading">
              Enter your last name to leave
            </div>
            <Input
              className={`dialog-input-input ${
                lastNameMatch !== "" &&
                lastNameMatch !== userDetail.lastName &&
                "input-error"
              }`}
              placeholder="First Name"
              value={lastNameMatch}
              onChange={(e) => setLastNameMatch(e.target.value)}
            />
            {lastNameMatch !== "" && lastNameMatch !== userDetail.lastName && (
              <div className="dialog-input-error">
                The last name entered is not correct
              </div>
            )}
          </div>
          <div className="dialog-buttons">
            <Button
              className="dialog-buttons-contained"
              variant="contained"
              disabled={lastNameMatch !== userDetail.lastName ? true : false}
              onClick={handleLeave}
            >
              Leave
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteLeaveEntity;

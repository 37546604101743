import React, { useState } from "react";
import { AdditionalCharges, Reduction } from "../../Types/invoice.types";
import {
  deleteClient,
  deleteInvoice,
  deleteInvoiceTemplateById,
  getClientById,
  getClients,
  getInvoiceTemplates,
  patchClient,
  postClient,
} from "../../api/invoice/invoice.api";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toastIn } from "../../UILibrary/Toast/Toast";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type TaxColumn = { label: string; variable: string };

export const useCalculateTotal = (
  taxColumns: TaxColumn[] | undefined
) => {
  const invoice = useSelector((state: any) => state.invoice || {});
  const { lineItems, additionalCharges, reductions } = invoice;
  const [totalAmount, setTotalAmount] = useState<number>(0)

  React.useEffect(() => {
    console.log("LINEITEMS affexe", lineItems)
    const totalAmountOfItems: number =
      lineItems?.reduce(
        (sum: number, invoice: any) => sum + (parseFloat(invoice?.amount) || 0),
        0
      ) || 0;

    const totalAdditionalCharges: number = additionalCharges?.reduce(
      (totalAdditionalCharges, charge: AdditionalCharges) => {
        if (charge.type === "Percentage") {
          return (
            totalAdditionalCharges + (totalAmountOfItems * (charge?.value || 0)) / 100
          );
        } else {
          return totalAdditionalCharges + (charge.value || 0);
        }
      },
      0
    );

    const totalDiscounts: number = reductions?.reduce(
      (totalDiscounts, charge: Reduction) => {
        if (charge.type === "Percentage") {
          return totalDiscounts + (totalAmountOfItems * (charge?.value || 0)) / 100;
        } else {
          return totalDiscounts + (charge?.value || 0);
        }
      },
      0
    );

    const taxSum: number | undefined = taxColumns?.reduce((taxSum: number, each: any) => {
      return (
        taxSum +
        (lineItems?.reduce(
          (sum: number, invoice: any) => sum + (parseFloat(invoice?.[each.variable]) || 0),
          0
        ) || 0) || 0
      );
    }, 0);

    console.log("TAXSUM", taxSum)
    const total: number =
      totalAmountOfItems +
      (totalAdditionalCharges || 0) -
      (totalDiscounts || 0) +
      (taxSum || 0);

    setTotalAmount(total);
  }, [additionalCharges, invoice, lineItems, reductions, taxColumns]);

  return totalAmount;
};

export const useClients = (companyId: string | null) => {
  return useQuery([companyId, "clients"], async () => getClients(companyId));
};

export const useClient = (
  companyId: string | null,
  clientId: string | null
) => {
  return useQuery([companyId, "client", clientId], async () =>
    getClientById(companyId, clientId)
  );
};

export const useGetTemplates = (companyId: string | null) => {
  return useQuery([companyId, "invoiceTemplates"], () =>
    getInvoiceTemplates(companyId)
  );
};

export function useDeleteTemplate(companyId: string | null) {
  const queryClient = useQueryClient();
  return useMutation(
    [companyId, "invoiceTemplates"],
    ({ templateId }: any) => deleteInvoiceTemplateById(companyId, templateId),
    {
      onSuccess: (_, variables) => {
        toastIn.success("Invoice Template Deleted");
        queryClient.invalidateQueries([companyId, "invoiceTemplates"]);
      },
      onError: (res, err) => {
        toastIn.error("Something went wrong");
      },
    }
  );
}

export function useAddClient() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    "postClient",
    ({ companyId, clientDetails }: any) => postClient(companyId, clientDetails),
    {
      onSuccess: (_, variables) => {
        toastIn.success("Client Added Successfully");
        queryClient.invalidateQueries([variables.companyId, "clients"]);
        navigate({
          pathname: `/dashboard/invoice/clients`,
          search: createSearchParams({
            id: variables.companyId || "",
          }).toString(),
        });
      },
      onError: (res, err) => {
        toastIn.error("Something went wrong");
      },
    }
  );
}

export function usePatchClient() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    "patchClient",
    ({ companyId, clientDetails }: any) => patchClient(companyId, clientDetails),
    {
      onSuccess: (_, variables) => {
        toastIn.success("Client Updated Successfully");
        queryClient.invalidateQueries([variables.companyId, "clients"]);
        navigate({
          pathname: `/dashboard/invoice/clients`,
          search: createSearchParams({
            id: variables.companyId || "",
          }).toString(),
        });
      },
      onError: (res, err) => {
        toastIn.error("Something went wrong");
      },
    }
  );
}

export function useDeleteClient() {
  const queryClient = useQueryClient();
  return useMutation(
    "deleteClient",
    ({ companyId, clientId }: any) => deleteClient(companyId, clientId),
    {
      onSuccess: (_, variables) => {
        toastIn.success("Client Updated Successfully");
        queryClient.invalidateQueries([variables.companyId, "clients"]);
      },
      onError: (res, err) => {
        toastIn.error("Something went wrong");
      },
    }
  );
}

export function useDeleteInvoice() {
  const queryClient = useQueryClient();
  return useMutation(
    "deleteInvoice",
    ({ companyId, invoiceId }: any) => deleteInvoice(companyId, invoiceId),
    {
      onSuccess: (_, variables) => {
        toastIn.success("Invoice deleted!");
        queryClient.invalidateQueries([variables.companyId, "invoices"]);
      },
      onError: (res, err) => {
        toastIn.error("Something went wrong");
      },
    }
  );
}

import React, { useEffect, useState } from "react";
import {
  deleteCollabrator,
  getAllCollabrator,
  addCollabrators,
  updateCollabrator,
} from "../../../../api/companies/companies";
import CoworkerDetailUI from "./CoworkerDetailsUI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setLoadMessage,
  setLoader,
} from "../../../Loader/state/action/actionCreator";
export interface List {
  email: string;
  role: string;
  inviteStatus: string;
}
interface Props {
  id: string;
  coworkerList: List[];
  setCoworkerList: any;
}
const CoworkerDetail = ({ id, coworkerList, setCoworkerList }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [owner, setOwner] = useState<string>("");
  const [logOutOpen, setLogOutOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(setLoadMessage("Loading Coworkers Detail"));
    dispatch(setLoader(true));
    const fetchData = async () => {
      const getColl = await getAllCollabrator({ id });
      if (getColl && getColl.type === "success") {
        setOwner(getColl.data[0].email);
        setLoading(false);
        dispatch(setLoader(false));
        dispatch(setLoadMessage("Loading Cashwise"));
      } else if (getColl && getColl.type === "error") {
        setLoading(false);
        dispatch(setLoader(false));
        dispatch(setLoadMessage("Loading Cashwise"));
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (email: string) => {
    setLoadingDelete(true);
    const emails = [email];

    const deleteColl = await deleteCollabrator({ id, emails });
    if (deleteColl && deleteColl.type === "success") {
      const getColl = await getAllCollabrator({ id });
      if (getColl && getColl.type === "success") {
        setCoworkerList(getColl.data);
        setLoadingDelete(false);
        setLogOutOpen(false);
      }
    }
  };
  const handleResend = async (email: string, role: string) => {
    dispatch(setLoader(true));
    dispatch(setLoadMessage("Resending Invite"));
    try {
      const send = await addCollabrators({ id, invites: { [email]: role } });
      if (send.type === "success") {
        dispatch(setLoader(false));
        dispatch(setLoadMessage("Loading Cashwise"));
        toast.success("Email Resend Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        throw new Error("Error occured");
      }
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(setLoadMessage("Loading Cashwise"));
      toast.error("Error ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {!loading && (
        <CoworkerDetailUI
          {...{
            owner,
            coworkerList,
            handleDelete,
            handleResend,
            logOutOpen,
            setLogOutOpen,
            loadingDelete,
          }}
        />
      )}
    </>
  );
};

export default CoworkerDetail;
